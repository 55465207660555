// material-ui
import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SAMPLE PAGE ||============================== //

const SiteDocs = () => (
  <MainCard title="Documentation">
    <Typography variant="body2">
      Coming Soon!
    </Typography>
  </MainCard>
);

export default SiteDocs;
