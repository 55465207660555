// material-ui
import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Database Connect PAGE ||============================== //

const ConnectAI = () => (
  <MainCard title="Add Database table(s)">
    <Typography variant="body2">
      Add or Connect your Orgnaization database to your Knowledge Hub which can be used to train your AI ChatBot
    </Typography>
  </MainCard>
);

export default ConnectAI;
