import { Drawer, IconButton, Menu, MenuItem } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { 
    Upload,
    Database, 
    Globe2,
    MessagesSquare
  } from 'lucide-react';
import { Stack } from '@mui/material';
import UploadComp from 'views/Create/Upload';
import TextArea from 'views/Create/Text';
import LinkComp from 'views/Create/Link';
import QnAInputBox from 'views/Create/QA';

function NavComp({getKbData,fetchKbName,clip=false}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [type,setType] = useState("")
    const [drawerOpen,setDrawerOpen] = useState(false)
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const nav = useNavigate()

    return (
        <>
            {clip?
                <IconButton onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5">
                        <path 
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            d="M21.44 11.05l-9.19 9.19a6 6 0 01-8.49-8.49l9.19-9.19a4 4 0 015.66 5.66l-9.2 9.19a2 2 0 01-2.83-2.83l8.49-8.48"
                            />
                    </svg>
                </IconButton>
            :<button onClick={handleClick} className="flex items-center gap-2 px-4 py-2 text-sm text-purple-700 bg-opacity-80 bg-purple-100 hover:bg-opacity-100 hover:text-purple-700 rounded-full transition-colors duration-200">
                <Upload className='text-base' height={16}/>
                Add or Upload
            </button>}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={()=>{
                    setType("upload")
                    setAnchorEl(null)
                    setDrawerOpen(true)
                }}>
                    <Stack direction={"row"} >
                        <Upload height={16}/>
                        Upload Files
                    </Stack>
                </MenuItem>
                <MenuItem onClick={()=>{
                    setType("data")
                    setAnchorEl(null)
                    setDrawerOpen(true)
                }}>
                    <Stack direction={"row"}>
                        <Database  height={16}/>
                        Capture Data
                    </Stack>
                </MenuItem>
                <MenuItem onClick={()=>{
                    setType("link")
                    setAnchorEl(null)
                    setDrawerOpen(true)
                }}>
                    <Stack direction={"row"}>
                        <Globe2  height={16}/>
                        Crawl Links
                    </Stack>
                </MenuItem>
                <MenuItem onClick={()=>{
                    setType("qna")
                    setAnchorEl(null)
                    setDrawerOpen(true)
                }}>
                    <Stack direction={"row"}>
                        <MessagesSquare  height={16}/>
                        Question & Answers
                    </Stack>
                </MenuItem>
            </Menu>
            <Drawer
                anchor='right'
                open={drawerOpen}
                onClose={()=>setDrawerOpen(false)}
            >
                <div className='md:w-[50vw] w-[100vw] bg-white'>
                    <div className='mt-10 p-7'>
                        {type === "upload" ? <UploadComp fetchKbNameTop={fetchKbName} getKbData={getKbData} close={setDrawerOpen} />:type === "data" ? <TextArea fetchKbNameTop={fetchKbName} getKbData={getKbData} close={setDrawerOpen}/>:type === "link" ? <LinkComp fetchKbNameTop={fetchKbName} getKbData={getKbData} close={setDrawerOpen}/>:type === "qna" && <QnAInputBox fetchKbNameTop={fetchKbName} getKbData={getKbData} close={setDrawerOpen}/>}
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default NavComp