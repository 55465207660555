// assets
import { IconKey, 
  IconBookUpload , 
  IconFileUpload, 
  IconUpload, 
  IconWorldUpload, 
  IconAddressBook,
  IconDatabase,
  IconBuildingStore,
  IconWindmill} from '@tabler/icons';

// constant
const icons = {
  IconKey, IconBookUpload, IconBuildingStore, IconFileUpload, IconUpload, IconWorldUpload, IconDatabase, IconAddressBook, IconWindmill
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'create',
  // title: 'Build Knowledge',
  // caption: 'Add the following to your KB And train your AI ChatBot',
  type: 'group',
  children: [
    {
      id: 'repo',
      title: 'Knowledge Hub',
      type: 'item',
      url: '/kb',
      icon: icons.IconBuildingStore,
      breadcrumbs: true
    },
  ]
};

export default pages;
