import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useAccess } from 'context/AccessContext';

// ============================|| FIREBASE - LOGIN ||============================ //

const AuthReset = ({ ...others }) => {
    const theme = useTheme();
    const nav = useNavigate()

    const [otp,setotp] = useState("")
    const [loading,setLoading] = useState(false)

    const { forgetPassword } = useAccess()

    const handleVerify = async() => {
        try {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(!emailPattern.test(otp)){
                toast.error(`Enter an valid email`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
            setLoading(true)
            await forgetPassword(otp,nav)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                <InputLabel htmlFor="outlined-adornment-email-register">Enter Your Registered Email</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-email-register"
                    type="text"
                    value={otp}
                    name="otp"
                    onChange={(e)=>setotp(e.target.value)}
                    inputProps={{}}
                />
            </FormControl>
            <Box sx={{ mt: 2 }}>
                <AnimateButton>
                    <button onClick={handleVerify} disabled={loading} className="btn">{loading? <CircularProgress /> :"Verify"}</button>
                </AnimateButton>
            </Box>
        </>
    );
};

export default AuthReset;