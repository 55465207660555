import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import ChatSection from './ChatSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { ChatBubbleOutline, Share } from '@mui/icons-material';
import { useEffect } from 'react';
import getUserInfo, { addkbqa, getAskllyAdmin, getMemberRole } from 'services/app.services';
import Home from '@mui/icons-material/Home';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import axios from 'axios';

import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { Stack } from '@mui/material';

import AssignmentIcon from '@mui/icons-material/Assignment';
import HubIcon from '@mui/icons-material/Hub';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ListIcon from '@mui/icons-material/List';
import ChatSearch from 'views/search/chat_search';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const ChatHeader = ({ sp,multi=true,api,kbhub=true,handleLeftDrawerToggle,showHome,toogleData,navi,bot,handleSelectChange,kbs,chatId, shareChat, show = true }) => {
  const nav = useNavigate()
  const theme = useTheme();

  const [isAsklly,setIsAsklly] = useState(false);
  const [roles, setRoles] = useState([])

  useEffect(()=>{
    console.log(kbs)
  },[])

  const getAsklly = async() => {
    try {
      const uid = localStorage.getItem("uid")
      const usr = await getUserInfo(uid)
      const org = localStorage.getItem("organization")
      if(!org){
        const { data } = await axios.post("https://fast.asklly.ai/get/org",{
          email: usr?.email
        })
        const body = {
          email: usr.email,
          organization: data
        }
        const orgn  = await getAskllyAdmin(body)
        setIsAsklly(orgn)
      }else{
        const body = {
          email: usr.email,
          organization: org
        }
        const data  = await getAskllyAdmin(body)
        setIsAsklly(data)
      }
      const form2 = new FormData()
      form2.append("email",usr?.email)
      const role = await getMemberRole(form2)
      let acces = role.role.role.split(",")
      setRoles(acces)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    console.log(bot)
    getAsklly().then()
    return ()=>{
      setIsAsklly(false)
    }
  },[])

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 320,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden',mr:1 }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={toogleData}
            color="inherit"
          >
            {<IconMenu2 stroke={1.5} size="1.3rem" />}
          </Avatar>
        </ButtonBase>
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ ml:1, borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={toogleData}
            color="inherit"
          >
            <Tooltip placement='bottom' title="New Chat" arrow>
                <IconButton
                  onClick={navi}
                >
                  <AddCommentIcon fontSize='medium' className='text-[#5E35B1]'/>
                </IconButton>
            </Tooltip>
          </Avatar>
        </ButtonBase>
      </Box>
      <Box sx={{ flex: 1 }}></Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '65%' }}>
        {show && <ChatSearch id={sp} kbhub={kbhub} kbs={kbs} api={api} bot={bot} handleSelectChange={handleSelectChange} nav={nav} />}
      </Box>
      <Box sx={{ flex: 1 }}></Box>
      {/* Home */}
      <Box sx={{ ml: 0.5, mr: 0.5,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle?handleLeftDrawerToggle:()=>nav("/marketplace")}
            color="inherit"
          >
            
            {showHome?<Home stroke={1.5} size="1.3rem" />:<IconMenu2 stroke={1.5} size="1.3rem" />}
          </Avatar>
        </ButtonBase>
      </Box>

      <Box className="hidden md:block">
        {chatId&&<Tooltip placement='bottom' title="Share Chat">
          <IconButton onClick={()=>shareChat(chatId)}>
            <Share fontSize='medium' className='text-[#5E35B1]'/>
          </IconButton>
        </Tooltip>}
      </Box>

      {(roles.includes('Admin') || roles.includes('Create Knowledge Base')) && (
        <Box className="hidden md:block" sx={{ ml: 1, mr: 1,
            [theme.breakpoints.down('md')]: {
              mr: 2
            }
          }}
        >
          <ButtonBase sx={{ borderRadius: '12px' }}  onClick={()=>nav("/kb")}>
            <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  transition: 'all .2s ease-in-out',
                  background: theme.palette.secondary.light,
                  color: theme.palette.secondary.dark,
                  '&:hover': {
                    background: theme.palette.secondary.dark,
                    color: theme.palette.secondary.light
                  }
                }}
                color="inherit"
              >
                <Tooltip placement='bottom' title="Knowledge Hub" arrow>
                  <HubIcon stroke={1.5} size="1.3rem" />
                </Tooltip>
              </Avatar>
          </ButtonBase>
        </Box>
      )}

      {/* Available Assistants */}
      {roles.includes('Admin') && (
        <Box className="hidden md:block" sx={{ ml: 0.5, mr: 0.5,
            [theme.breakpoints.down('md')]: {
              mr: 2
            }
          }}
        >
          <ButtonBase sx={{ borderRadius: '12px' }} onClick={()=>nav("/marketplace")}>
            <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  transition: 'all .2s ease-in-out',
                  background: theme.palette.secondary.light,
                  color: theme.palette.secondary.dark,
                  '&:hover': {
                    background: theme.palette.secondary.dark,
                    color: theme.palette.secondary.light
                  }
                }}
                color="inherit"
              >
                <Tooltip placement='bottom' title="Available Assistants" arrow>
                  <SmartToyIcon stroke={1.5} size="1.3rem" />
                </Tooltip>
              </Avatar>
          </ButtonBase>
        </Box>
      )}


      {/* Create Assistant */}
      {/* <Box sx={{ ml: 1, mr: 1,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: 'all .2s ease-in-out',
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark,
                '&:hover': {
                  background: theme.palette.secondary.dark,
                  color: theme.palette.secondary.light
                }
              }}
              onClick={handleLeftDrawerToggle?handleLeftDrawerToggle:()=>nav("/train")}
              color="inherit"
            >
              <Tooltip placement='bottom' title="Create Assistant " arrow>
                <SmartToyIcon stroke={1.5} size="1.3rem" />
              </Tooltip>
            </Avatar>
        </ButtonBase>
      </Box> */}
      <div className='md:flex items-center hidden'>
        <NotificationSection />
        <ProfileSection isAsklly={isAsklly} />
      </div>
    </>
  );
};

ChatHeader.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default ChatHeader;