import React, { useEffect, useState, useMemo, useRef } from 'react';
import { IconButton, CircularProgress, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, TextField, Drawer, Divider, Card, CardContent } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { IconEdit } from '@tabler/icons';
import { useNavigate } from 'react-router';
import getUserInfo, { deleteTenant, getAskllyAdmin, getTenants, editTenant } from 'services/app.services';
import { Trash2, X } from 'lucide-react';
import { MaterialReactTable } from 'material-react-table';
import { ChevronRight, Home, HomeSharp, Visibility } from "@mui/icons-material";
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress as MuiCircularProgress } from '@mui/material';
import AddTenants from './addTeants';
import { useAccess } from 'context/AccessContext';

const ViewTenants = () => {
    const nav = useNavigate();
    const { activeTeam } = useAccess()

    const [tenants, setTenants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [tenantToDelete, setTenantToDelete] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editingTenant, setEditingTenant] = useState(null);
    const [newTenantName, setNewTenantName] = useState('');
    const [newAdminName, setNewAdminName] = useState('');
    const [newAdminEmail, setNewAdminEmail] = useState('');
    const [newLogo, setNewLogo] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [oneTenant, setOneTenant] = useState()

    useEffect(() => {
        const uid = localStorage.getItem("uid")
        getUserInfo(uid).then((res)=>{
            const body = {
                email: res.email,
                organization: localStorage.getItem("organization"),
                team_id: activeTeam
            }
            console.log(body)
            getAskllyAdmin(body).then((res)=>{
                if(!res){
                    nav("/404")
                }
            })
        })

        fetchTenants();
    }, []);

    const fetchTenants = async () => {
        setLoading(true);
        try {
            const response = await getTenants();
            // Sort tenants by created_at in descending order (latest first)
            const sortedTenants = response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setTenants(sortedTenants);
        } catch (error) {
            console.error('Error fetching tenants:', error);
        } finally {
            setLoading(false);
        }
    };

    const getOneTenant = async() => {
        try {
            setOneTenant()
            const tenant = await getTenants(selectedTenant.name)
            console.log(tenant)
            setOneTenant(tenant)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(()=>{
        getOneTenant()
    },[selectedTenant])

    const handleDeleteTenant = async () => {
        setDeleting(true)
        if (tenantToDelete) {
            await deleteTenant(tenantToDelete);
            fetchTenants();
            setDeleteDialogOpen(false);
            setTenantToDelete(null);
        }
        setDeleting(false)
    };

    const handleEditTenant = async () => {
        if (editingTenant) {
            setIsEditing(true);
            const formData = new FormData();
            formData.append('tenant_id', editingTenant.id);
            if (newTenantName) formData.append('new_tenant_name', newTenantName);
            if (newAdminName) formData.append('new_admin_name', newAdminName);
            if (newAdminEmail) formData.append('new_admin_email', newAdminEmail);
            if (newLogo) formData.append('new_logo', newLogo);

            try {
                await editTenant(editingTenant.id, formData);
                fetchTenants();
                setEditDialogOpen(false);
                setEditingTenant(null);
                setNewTenantName('');
                setNewAdminName('');
                setNewAdminEmail('');
                setNewLogo(null);
                setPreviewUrl(null);
            } catch (error) {
                console.error('Error editing tenant:', error);
            } finally {
                setIsEditing(false);
            }
        }
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setNewLogo(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewUrl(null);
        }
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Tenant Name',
            },
            {
                accessorKey: 'admin_name',
                header: 'Enterprise Admin Name',
            },
            {
                accessorKey: 'admin_email',
                header: 'Enterprise Admin Email',
            },
            {
                accessorKey: 'created_at',
                header: 'Created At',
                Cell: ({ cell }) => {
                    console.log(cell.getValue()+"Z")
                    const utcTime = new Date(cell.getValue());
                    const localTime = utcTime.toLocaleString();
                    return localTime;
                },
                sortingFn: 'datetime',
                sortDescFirst: true
            },
            {
                id: 'actions',
                header: 'Actions',
                Cell: ({ row }) => (
                    <>
                        <Tooltip title="View Tenant" arrow>
                            <IconButton 
                                variant="contained" 
                                color="secondary" 
                                onClick={() => {
                                    setSelectedTenant(row.original);
                                    setDetailsDialogOpen(true);
                                }}
                            >
                                <Visibility />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Tenant" arrow>
                            <IconButton 
                                variant="contained" 
                                color="secondary" 
                                onClick={() => {
                                    setEditingTenant(row.original);
                                    setNewTenantName(row.original.name);
                                    setNewAdminName(row.original.admin_name);
                                    setNewAdminEmail(row.original.admin_email);
                                    setEditDialogOpen(true);
                                }}
                            >
                                <IconEdit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Tenant" arrow>
                            <IconButton 
                                variant="contained" 
                                color="secondary" 
                                onClick={() => {
                                    setTenantToDelete(row.original.name);
                                    setDeleteDialogOpen(true);
                                }}
                            >
                                <Trash2 color='red' />
                            </IconButton>
                        </Tooltip>
                    </>
                ),
            },
        ],
        []
    );

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <Stack>
            <MainCard>
                <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                    <div className="flex justify-center items-center">
                        <Link to='/marketplace'>
                            <HomeSharp color="secondary" />
                        </Link>
                        <ChevronRight />
                        <Typography sx={{ fontWeight: 'bold' }}>Tenants</Typography>
                    </div>
                    <button onClick={toggleDrawer(true)} className="flex items-center gap-2 px-4 py-2 text-sm text-purple-700 bg-opacity-80 bg-purple-100 hover:bg-opacity-100 hover:text-purple-700 rounded-full transition-colors duration-200">
                        <AddIcon className='text-base' />
                        Tenant
                    </button>
                </Stack>
                <Stack sx={{ mt: 1}}><Typography>Manage all onboarded tenants seamlessly on the platform for streamlined administration and oversight.</Typography></Stack>
            </MainCard>
            <Stack sx={{ mt: 2, borderRadius: '8px', overflow: 'hidden'}}>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <MaterialReactTable
                        columns={columns}
                        data={tenants}
                        enableColumnActions={false}
                        enableColumnFilters={false}
                        enablePagination={true}
                        enableSorting={true}
                        enableBottomToolbar={true}
                        enableTopToolbar={true}
                        muiTableBodyRowProps={{ hover: true }}
                        enableGlobalFilter={true}
                        muiSearchTextFieldProps={{
                            placeholder: 'Search Tenants',
                            variant: 'outlined',
                            label: 'Search',
                            InputLabelProps: { shrink: true },
                        }}
                        sx={{
                            borderRadius: '8px', // Adjust the radius as needed
                            overflow: 'hidden', // Ensure the corners are rounded
                        }}
                        // Customizing the pagination options
                        muiTablePaginationProps={{
                            labelRowsPerPage: '',
                        }}
                        initialState={{
                            sorting: [
                                {
                                    id: 'created_at',
                                    desc: true
                                }
                            ]
                        }}
                    />
                )}
                
                <Dialog open={deleteDialogOpen} onClose={() => {
                    if(deleting){
                        return
                    }
                    setDeleteDialogOpen(false)
                }}>
                    <DialogTitle>Confirm Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete this tenant?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={deleting} onClick={() => setDeleteDialogOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button disabled={deleting} onClick={handleDeleteTenant} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Drawer anchor="right" open={detailsDialogOpen} onClose={() => setDetailsDialogOpen(false)}>
                    <div className='flex justify-between mb-2 items-center px-7 py-3'>
                        <Typography variant='h3' className="font-bold">Tenant Details</Typography>
                        <IconButton onClick={() => setDetailsDialogOpen(false)}>
                            <X />
                        </IconButton>
                    </div>
                    <Divider></Divider>
                    <div className="w-screen md:w-[50vw] p-8">                        
                        {oneTenant ? (
                            <div className="space-y-6">
                                <div className="bg-gray-50 p-6 rounded-lg">
                                    <div className='flex h-24 items-center gap-4'>
                                        {oneTenant.tenant.logo_url && (
                                            <div className="flex justify-center">
                                                <img 
                                                    src={oneTenant.tenant.logo_url} 
                                                    className="w-24 p-1 object-contain rounded-lg shadow-md" 
                                                    alt="Tenant Logo"
                                                />
                                            </div>
                                        )}
                                        <div>
                                            <Typography variant="h4">
                                                {oneTenant.tenant.name}
                                            </Typography>
                                            <Typography variant="h6">
                                                {oneTenant.tenant.admin_name}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="space-y-4">
                                        <div>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                Enterprise Admin Email
                                            </Typography>
                                            <Typography variant="h6">
                                                {oneTenant.tenant.admin_email}
                                            </Typography>
                                        </div>

                                        <div>
                                            <Typography variant="subtitle2" color="textSecondary">
                                                Created At
                                            </Typography>
                                            <Typography variant="h6">
                                                {new Date(oneTenant.tenant.created_at).toLocaleDateString()}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className='flex gap-4 mb-2'>
                                        <Card className='w-full'>
                                            <CardContent className="p-6">
                                                <div className="space-y-4">
                                                    <p className="text-gray-600">No. of Knowledge Hub created</p>
                                                    <p className="text-xl font-semibold">{oneTenant.metrics.total_kb}</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <Card className='w-full'>
                                            <CardContent className="p-6">
                                                <div className="space-y-4">
                                                    <p className="text-gray-600">No. of Assistants created</p>
                                                    <p className="text-xl font-semibold">{oneTenant.metrics.total_bots}</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <div className='flex gap-4 mb-2'>
                                        <Card className='w-full mb-2'>
                                            <CardContent className="p-6">
                                                <div className="space-y-4">
                                                    <p className="text-gray-600">Total no. of files uploaded</p>
                                                    <p className="text-xl font-semibold">{oneTenant.metrics.total_files}</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <Card className='w-full mb-2'>
                                            <CardContent className="p-6">
                                                <div className="space-y-4">
                                                    <p className="text-gray-600">Total file size</p>
                                                    <p className="text-xl font-semibold">{oneTenant.metrics.total_file_size} Mb</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <div className='flex gap-4 mb-2'>
                                        <Card className='w-full mb-2'>
                                            <CardContent className="p-6">
                                                <div className="space-y-4">
                                                    <p className="text-gray-600">TotalTeams</p>
                                                    <p className="text-xl font-semibold">{oneTenant.metrics.teams}</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                        <Card className='w-full mb-2'>
                                            <CardContent className="p-6">
                                                <div className="space-y-4">
                                                    <p className="text-gray-600">Total Users</p>
                                                    <p className="text-xl font-semibold">{oneTenant.metrics.users}</p>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    <Card className='mb-2'>
                                        <CardContent className="p-6">
                                            <div className="space-y-4">
                                                <p className="text-gray-600">Total Chats</p>
                                                <p className="text-xl font-semibold">{oneTenant.metrics.chats}</p>
                                            </div>
                                        </CardContent>
                                    </Card>
                                    <Card className='mb-2'>
                                        <CardContent className="p-6">
                                            <div className="space-y-4">
                                                <p className="text-gray-600">Total No Of Chat Tokens Used</p>
                                                <p className="text-xl font-semibold">{oneTenant.metrics.token}</p>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        ) : (
                            <div className='flex h-full justify-center items-center'>
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                </Drawer>
                <Drawer anchor="right" open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                    <div className='flex justify-between mb-2 items-center px-7 py-3'>
                        <Typography variant='h3' className="font-bold">Edit Tenant</Typography>
                        <IconButton onClick={() => setEditDialogOpen(false)}>
                            <X />
                        </IconButton>
                    </div>
                    <Divider></Divider>
                    <div style={{ width: {xs: '100vw', md: '50vw'}, padding: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Tenant Name"
                            type="text"
                            fullWidth
                            color='secondary'
                            value={newTenantName}
                            onChange={(e) => setNewTenantName(e.target.value)}
                            disabled={isEditing}
                        />
                        <TextField
                            margin="dense"
                            label="Admin Name"
                            type="text"
                            color='secondary'
                            fullWidth
                            value={newAdminName}
                            onChange={(e) => setNewAdminName(e.target.value)}
                            disabled={isEditing}
                        />
                        <TextField
                            margin="dense"
                            label="Admin Email"
                            type="email"
                            fullWidth
                            color='secondary'
                            value={newAdminEmail}
                            onChange={(e) => setNewAdminEmail(e.target.value)}
                            disabled={isEditing}
                        />
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="raised-button-file-drawer"
                            type="file"
                            onChange={handleLogoChange}
                            disabled={isEditing}
                        />
                        <label htmlFor="raised-button-file-drawer">
                            <Button variant="contained" component="span" color='secondary' disabled={isEditing} style={{ marginTop: '10px' }}>
                                Upload New Logo
                            </Button>
                        </label>
                        {newLogo && <Typography>{newLogo.name}</Typography>}
                        {previewUrl && (
                            <div style={{ marginTop: '10px' }}>
                                <Typography>Preview:</Typography>
                                <img src={previewUrl} alt="Logo Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                            </div>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                            <Button onClick={() => setEditDialogOpen(false)} color="error" disabled={isEditing} style={{ marginRight: '10px' }}>
                                Cancel
                            </Button>
                            <Button onClick={handleEditTenant} color="secondary" disabled={isEditing}>
                                {isEditing ? <MuiCircularProgress size={24} /> : 'Save Changes'}
                            </Button>
                        </div>
                    </div>
                </Drawer>
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    className='flex justify-center items-center h-full'
                >
                    <div className='flex justify-between mb-2 items-center px-7 py-3'>
                        <Typography variant='h3' className="font-bold">New Tenant</Typography>
                        <IconButton onClick={()=>setDrawerOpen(false)}>
                            <X />
                        </IconButton>
                    </div>
                    <Divider></Divider>
                    <AddTenants fetchTenants={fetchTenants} />
                </Drawer>
            </Stack>
        </Stack>
    );
};

export default ViewTenants;