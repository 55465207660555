// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { useState } from 'react';
import { useEffect } from 'react';
import getUserInfo, { getAskllyAdmin, getMemberRole } from 'services/app.services';
import pages from 'menu-items/pages';
import team_page from 'menu-items/team_page';
import chat_page from 'menu-items/chat_pages';
import utilities from 'menu-items/utilities';
import bot_pages from 'menu-items/bot_pages';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { IconBuilding } from '@tabler/icons';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [menu,setMenu] = useState([])
  const nav = useNavigate()
  useEffect(()=>{
    getUserRole()
  },[])
  
  async function getUserRole(){
    const uid = localStorage.getItem("uid")
    const user = await getUserInfo(uid)
    const form = new FormData()
    form.append("email", user?.email)
    const role = await getMemberRole(form)
    if(!role?.role){
      nav("/no-org")
    }
    const usr = await getUserInfo(uid)
    const org = localStorage.getItem("organization")
    let orgn = false; // Initialize orgn variable
    const { data } = await axios.post("https://fast.asklly.ai/get/org",{
      email: usr?.email
    })
    const body = {
      email: usr.email,
      organization: data
    }
    orgn  = await getAskllyAdmin(body) // Set orgn based on the response
    console.log(role)
    const finalRole = role?.role?.role?.split(",")
    localStorage.setItem("organization", role?.role?.organization)
    // Append team_page.children to the beginning if orgn is true
    if (orgn && team_page.children[0]?.id !== 'Tenants') {
      team_page.children.unshift({
        id: 'Tenants',
        title: 'Tenants',
        type: 'item',
        url: '/tenants/view',
        icon: IconBuilding
      });
    }
    let menuItems = [bot_pages]
    if (finalRole.includes("Admin")) {
      menuItems = [bot_pages, pages, chat_page, team_page, utilities]
    } else {
      if (finalRole.includes("Create Knowledge Base")) {
        menuItems.push(pages)
      }
      if (finalRole.includes("Only Query Knowledge Base")) {
        menuItems.push(chat_page)
      }
      if (finalRole.includes("Create Teams")) {
        menuItems.push(team_page)
      }
    }

    // Reorder menu items based on the specified order
    const orderMap = {pages: 1, chat_page: 2, bot_pages: 3, team_page: 4, utilities: 5}
    menuItems.sort((a, b) => orderMap[a.id] - orderMap[b.id])
    
    setMenu(menuItems)
  }
  const navItems = menu.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
