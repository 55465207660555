import { Link, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { CircularProgress, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AuthAccept from '../auth-forms/AuthAccept';
import logo from "assets/images/kwlogoremoved.png";
import { decryptToken } from 'services/app.services';
import { useEffect } from 'react';
import { useState } from 'react';

// assets

// ================================|| AUTH3 - Accept ||================================ //

const Accept = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const { id } = useParams()
    const [tokenDecrypt,setTokenDecrypt] = useState({})
    const [loading,setLoading] = useState(false)

    const decryptJson = async() => {
        setLoading(true)
        const form = new FormData()
        form.append("token",id)
        const token = await decryptToken(form)
        setTokenDecrypt(token)
        setLoading(false)
    }

    useEffect(()=>{
        decryptJson()
    },[])

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        {loading?
                            <CircularProgress />
                        :<Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    
                                    <Grid item xs={12}>
                                        <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                                                        Accept Invite
                                                    </Typography>
                                                    <Typography variant="caption" fontSize="16px" textAlign={'center'}>
                                                        You have been invited to join the team <span className='font-semibold text-black'>{tokenDecrypt?.team_name}</span> as  <span className='font-semibold text-black'>{tokenDecrypt?.role}</span>
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AuthAccept id={id} />
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>}
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid> */}
            </Grid>
        </AuthWrapper1>
    );
};

export default Accept;