import { Button } from '@mui/material'
import Nav from 'layout/MainPage/Components/nav'
import React from 'react'
import { useNavigate } from 'react-router'

export default function NotFound() {
    const nav = useNavigate()
    return (
        <div className='h-screen'>
            <Nav />
            <div className='h-full flex flex-col justify-center items-center bg-white'>
                <img src="404(1).png" className='w-80' />
                <Button variant='outlined' color='secondary' onClick={()=>nav("/")}>Nav Back To Home</Button>
            </div>
        </div>
    )
}
