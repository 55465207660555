import { useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
} from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { useNavigate } from 'react-router';
import { acceptInvite } from 'services/app.services';

// ============================|| FIREBASE - LOGIN ||============================ //

const AuthAccept = ({ id }) => {
    const nav = useNavigate()
    const [loading,setLoading] = useState(false)

    const handleAccept = async() =>{
        setLoading(true)
        try {
            const form = new FormData()
            form.append("token",id)
            await acceptInvite(form)
            nav("/register")
        } catch (error) {
            console.log(error)
        }finally{
            setLoading(false)
        }
    }

    return (
        <>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <AnimateButton>
                            <button onClick={handleAccept} disabled={loading} className="btn">{loading? <CircularProgress /> :"Accept Invite"}</button>
                        </AnimateButton>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default AuthAccept;