import React, { useState } from 'react';
import { Typography, IconButton, Menu, MenuItem, CircularProgress, TextField } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { Clock3, Edit, Eye, Link, Trash2, X, Check, ExternalLink } from 'lucide-react'; // Added CheckCircle and Cancel icons
import { deleteCollection, editCollectionName } from 'services/app.services';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

function MenuCard({ item, index, nav, setCollections }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(item.name);
    const [name, setName] = useState(item?.name);
    const uid = localStorage.getItem("uid");
    const [isLoading, setIsLoading] = useState(false); // State to manage loading

    const handleCollectionMenuClose = (index) => {
        setAnchorEl(null);
    };

    const handleDeleteCollection = async (collectionId, index) => {
        setDeleting(true);
        try {
            await deleteCollection(collectionId, uid);
            setCollections(prev => prev.filter(collection => collection.id !== collectionId));
        } catch (error) {
            console.error("Error deleting collection:", error);
        } finally {
            setDeleting(false);
        }
        handleCollectionMenuClose(index);
    };

    const handleRenameCollection = async () => {
        setIsLoading(true); // Set loading state
        const form = new FormData();
        form.append("new_name", newName);
        await editCollectionName(item?.id, uid, form);
        setName(newName);
        setIsEditing(false);
        setIsLoading(false); // Reset loading state
    };

    const passedDays = (ts) => {
        const timestamp = ts * 1000;
        const now = Date.now();
        const diffInMs = now - timestamp;
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        return diffInDays;
    };

    const handleMenuClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div
            key={index}
            className="bg-[#EDE7F6] text-[#5E35B1] w-full p-4 mt-4 rounded relative duration-300 transition-all ease-in-out"
            onClick={(e) => e.stopPropagation()} // Prevent navigation when clicking on the card
        >
            <div
                className="cursor-pointer w-fit"
                onClick={(e) => {
                    e.stopPropagation();
                    if(isEditing){
                        return
                    }
                    nav(`/collection?id=${item?.id}`)
                }}
            >
                {isEditing ? (
                    <div className="flex items-center">
                        <TextField
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            autoFocus
                            variant="outlined"
                            size="small"
                        />
                        <IconButton className='w-fit' onClick={handleRenameCollection} disabled={isLoading}>
                            {isLoading ? <CircularProgress size={24} /> : <Check size={19} className="text-green-500" />}
                        </IconButton>
                        <IconButton className='w-fit' onClick={() => { setIsEditing(false); setNewName(name); }} disabled={isLoading}>
                            <X size={19} className="text-red-500 disabled:text-red-100" />
                        </IconButton>
                    </div>
                ) : (
                    <h1 className='font-medium text-sm'>{name}</h1>
                )}
                <div className='flex gap-3'
                    
                >
                    <div className='flex items-center justify-start gap-1'>
                        <Eye size={15} />
                        <h1 className='text-xs'>{item?.views}</h1>
                    </div>
                    <div className='flex items-center justify-start gap-1'>
                        <Clock3 size={15} />
                        <h1 className='text-xs'>{passedDays(item?.timestamp) === 0 ? 'Today' : `${passedDays(item?.timestamp)} days ago`}</h1>
                    </div>
                </div>
            </div>
            <div
                className="absolute top-2 right-2"
                onClick={(e) => e.stopPropagation()} // Stop propagation on the menu container div
            >
                {!isEditing&&<IconButton
                    aria-label="more"
                    onClick={handleMenuClick}
                >
                    <MoreVert />
                </IconButton>}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': `collection-menu-button-${index}`,
                    }}
                >
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            setIsEditing(true);
                            handleCloseMenu();
                        }}
                        disabled={deleting}
                    >
                        {deleting ? (
                            <CircularProgress size={24} className="mr-3" />
                        ) : (
                            <Edit className="mr-3" />
                        )}
                        Rename
                    </MenuItem>
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            const { origin } = window.location;
                            copy(`${origin}/share/collection?id=${item?.id}`);
                            toast.success("Url copied", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            handleCloseMenu();
                        }}
                        disabled={deleting}
                    >
                        <ExternalLink />&nbsp;&nbsp;&nbsp;
                        Share
                    </MenuItem>
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            handleDeleteCollection(item?.id, index);
                            handleCloseMenu();
                        }}
                        disabled={deleting}
                    >
                        {deleting ? (
                            <CircularProgress size={24} className="mr-3" />
                        ) : (
                            <Trash2 className="text-red-500 mr-3" />
                        )}
                        Delete
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
}

export default MenuCard;