import { Box } from '@mui/material';

const SideNavBarLinks = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        borderRadius: 1,
      }}
    >
      <Box sx={{ color: '#1a73e8' }}>About</Box>
      <Box sx={{ color: '#1a73e8' }}>Docs</Box>
      <Box sx={{ color: '#1a73e8' }}>Legal</Box>
    </Box>
  );
};

export default SideNavBarLinks;
