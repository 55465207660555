import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAccess } from 'context/AccessContext';

// ============================|| FIREBASE - LOGIN ||============================ //

const AuthOtp = ({ ...others }) => {
    const theme = useTheme();
    const nav = useNavigate()

    useEffect(()=>{
        if(localStorage.getItem("uid")){
        nav("/kb")
        }
    },[])

    const [otp,setotp] = useState("")
    const [loading,setLoading] = useState(false)

    const { resendOtp, verifyOtp } = useAccess()

    const handleVerify = async() => {
        try {
            if(otp.length < 6 || otp.length > 6){
                toast.error(`Please enter an valid otp`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            }
            setLoading(true)
            const email = localStorage.getItem("email")
            await verifyOtp(email,otp,nav)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleResend = async() => {
        try {
            setLoading(true)
            const email = localStorage.getItem("email")
            await resendOtp(email,nav)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                <InputLabel htmlFor="outlined-adornment-email-register">Otp</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-email-register"
                    type="text"
                    value={otp}
                    name="otp"
                    onChange={(e)=>setotp(e.target.value)}
                    inputProps={{}}
                />
            </FormControl>
            <Button variant='text' onClick={handleResend}>
                <Typography variant="subtitle1" color="secondary" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                    Haven't received otp? Resend otp
                </Typography>
            </Button>
            <Box sx={{ mt: 2 }}>
                <AnimateButton>
                    <button onClick={handleVerify} disabled={loading} className="btn">{loading? <CircularProgress /> :"Verify"}</button>
                </AnimateButton>
            </Box>
        </>
    );
};

export default AuthOtp;