import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {  } from 'services/app.services';
import { toast } from 'react-toastify';
import { useAccess } from 'context/AccessContext';

// ===========================|| FIREBASE - REGISTER ||=========================== //

const AuthNewPassword = ({ id, ...others }) => {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState({
        new: "",
        confirm: ""
    });
    const nav = useNavigate()
    const [obj,setobj] = useState({})

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const { changePasswordForgot } = useAccess()

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const [loading,setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if(strength < 4){
                return toast.error("Enter an strong password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

            if(password.new !== password.confirm){
                return toast.error("The password doesnt match", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            setLoading(true)
            await changePasswordForgot(id,password.new,nav)
            setLoading(false)
        } catch (error) {
            console.error(error) 
            setLoading(false)
        }
    }

    useEffect(() => {
        changePassword('123456');
    }, []);

    return (
        <>
            <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password-register">New Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password-register"
                        type={showPassword ? 'text' : 'password'}
                        value={password.password}
                        name="new"
                        label="New Password"
                        onChange={(e) => {
                            setPassword({...password,[e.target.name]:e.target.value})
                            changePassword(e.target.value);
                        }}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                        inputProps={{}}
                    />
                </FormControl>

                <FormControl fullWidth sx={{ mt:2 }}>
                    <InputLabel htmlFor="outlined-adornment-password-register">Confirm Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password-register"
                        type={showPassword ? 'text' : 'password'}
                        value={password.confirm}
                        name="confirm"
                        label="Confirm Password"
                        onChange={(e) => {
                            setPassword({...password,[e.target.name]:e.target.value})
                            changePassword(e.target.value);
                        }}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                        inputProps={{}}
                    />
                </FormControl>
                <Box sx={{ mt: 2 }}>
                    <AnimateButton>
                        <button type='submit' disabled={loading} className="btn">{loading? <CircularProgress /> :"Sign Up"}</button>
                    </AnimateButton>
                </Box>
            </form>
        </>
    );
};

export default AuthNewPassword;