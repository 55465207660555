import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Box, Typography } from '@mui/material';

const SideNavBarPageLinks = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        borderRadius: 1,
      }}
    >
      {' '}
      <Box
        mx={2}
        my={3}
        px={1}
        py={1}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderRadius: '10px',
          ':hover': { bgcolor: '#0000000a', cursor: 'pointer' },
        }}
      >
        <HomeOutlinedIcon />
        <Typography variant='text' ml={1}>
          Home
        </Typography>
      </Box>
      <Box
        mx={2}
        my={3}
        px={1}
        py={1}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderRadius: '10px',
          ':hover': { bgcolor: '#0000000a', cursor: 'pointer' },
        }}
      >
        <BarChartOutlinedIcon />
        <Typography variant='text' ml={1}>
          Dashboard
        </Typography>
      </Box>
      <Box
        mx={2}
        my={3}
        px={1}
        py={1}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          borderRadius: '10px',
          ':hover': { bgcolor: '#0000000a', cursor: 'pointer' },
        }}
      >
        <ChatBubbleOutlineOutlinedIcon variant='outlined' />
        <Typography variant='text' ml={1}>
          AI Chat
        </Typography>
      </Box>
    </Box>
  );
};

export default SideNavBarPageLinks;
