import { Card, CardContent, CardHeader, Typography, Stack, IconButton, Avatar, CircularProgress } from '@mui/material'
import React, { useRef } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { IoPerson,IoLockClosed,IoPencil } from 'react-icons/io5'
import { styled } from '@mui/system';
import { useState } from 'react';
import getUserInfo, { changePassword, getMetrics, updateProf } from 'services/app.services';
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { IconChartHistogram } from '@tabler/icons';
import TotalIncomeLightCard from './BotLightCard';
import { ChevronDown, ChevronRight } from 'lucide-react';

const IMAGE = styled("img")(({theme})=>({
    width: 100,
    height: 100,
    borderRadius: 50
}))

ChartJS.register(ArcElement, Tooltip, Legend);

function stringToColor(name) {
    if (!name || name.length === 0) {
      return '#000000';
    }
  
    let hash = 0;
    let i;
  
    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
  
    return color;
}
  
function stringAvatar(name) {
    if (!name) {
        return {
        sx: {
            // Define default styling if needed
        },
        children: '',
        };
    }

    return {
        sx: {
        bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        className='md:mt-0 mt-3 md:p-2 p-0'
      >
        {value === index && (
          <Box sx={{ p: { sm: 1, md: 4 } }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

function Profile() {
    const [value, setValue] = React.useState(0);
    const [user,setUser] = useState()
    const [profile,setProfile] = useState()
    const image = useRef(null)
    const [previewUrl, setPreviewUrl] = useState(null);
    const [submitting,setSubmitting] = useState(false)
    const [formValues, setFormValues] = React.useState({
        fname: '',
        lname: '',
        email:''
    });
    const [prof,setProf] = useState("")
    const [datas,setData] = useState(null)
    const [loading,setLoading] = useState(false)
    const [passwords,setPasswords] = useState({
        oldpassword: "",
        newpassword: "",
        confirmpassword: ""
    })

    useEffect(()=>{
        get_metrics()
    },[])

    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    const get_metrics = async() => {
        setLoading(true)
        const form = new FormData()
        const org = localStorage.getItem("organization")
        const uid = localStorage.getItem("uid")
        form.append("org",org)
        form.append("uid",uid)
        const met = await getMetrics(form)
        setData(met)
        setLoading(false)
    }

    const handlePasswordInputChange = async(e) => {
        setPasswords({
            ...passwords,
            [e.target.name]: e.target.value,
        })
    }

    const handleInputChange = (event) => {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value,
        });
    };
    const onButtonClick = () => {
        image.current.click();
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getUser = async() => {
        const id = localStorage.getItem('uid')
        const gauth = localStorage.getItem('gauthcred')
        const usr = await getUserInfo(id)
        if(usr){
            setUser(usr)
            const username = usr.uname.split(" ")
            setFormValues({fname:username[0],lname:username[1],email:usr.email})
            if(usr?.prof !== ""){
                setProf(usr?.prof)
            }else if(gauth){
                const user = jwt_decode(gauth)
                setProf(user?.picture)
            }else{
                setProf("")
            }
        }
    }
    const handleSubmit = async(e) =>{
        try {
            setSubmitting(true)
            e.preventDefault()
            const form = new FormData()
            const uid = localStorage.getItem('uid')
            form.append('uid',uid)
            form.append('fname',formValues.fname)
            form.append('lname',formValues.lname)
            if(profile){
                form.append('pic','')
                form.append('img',profile)
            }else if(prof){
                form.append('pic',prof)
                form.append('img','')
            }else{
                form.append('pic','')
                form.append('img','')
            }
            await updateProf(form)
            getUser()
            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            console.log(error)
        }
    }

    const handleChangePassword = async(e) => {
        e.preventDefault()
        if(passwords.oldpassword === "" || passwords.newpassword === "" || passwords.confirmpassword === ""){
            return toast.error("All feilds are required", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }
        
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if(!strongRegex.test(passwords.newpassword)){
            return toast.error("Enter an strong password to continue", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }

        if(passwords.newpassword !== passwords.confirmpassword){
            return toast.error("Confirm password doesnt matched", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        }

        setSubmitting(true)
        const id = localStorage.getItem('uid')
        const usr = await getUserInfo(id)
        const form = new FormData();
        form.append("email",usr.email);
        form.append("password",passwords.oldpassword);
        form.append("newpassword",passwords.newpassword);
        await changePassword(form);
        setPasswords({
            oldpassword: "",
            newpassword: "",
            confirmpassword: ""
        });
        setSubmitting(false)
    }

    const data = {
        labels: ["Used","UnUsed"],
        datasets: [{
            data:[10,8],
            backgroundColor: ["red","black"],
            borderColor: ["red","black"],
        }]
    }
    useEffect(()=>{
        getUser()
    },[])

    const downloadCSV = () => {
        if (!datas || !datas.kb_metrics) {
            toast.error("No data available to download");
            return;
        }

        const csvContent = [
            ["Name", "No of Files", "Storage (MB)"],
            ...datas.kb_metrics.map(item => [
                item.kb_name,
                item.no_of_files,
                Number(item.size).toFixed(1)
            ])
        ].map(e => e.join(",")).join("\n");

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "knowledge_base_usage.csv");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <div className='md:p-2'>
            <Typography variant='h2'>Account Settings</Typography>
            <Box sx={{ width: '100%',mt:2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} textColor='secondary' indicatorColor="secondary" onChange={handleChange} aria-label="basic tabs example">
                        <Tab iconPosition="start" icon={<IoPerson />} label="Profile" />
                        <Tab iconPosition="start" icon={<IconChartHistogram />} label="Usage" />
                        <Tab iconPosition="start" icon={<IoLockClosed />} label="Billing" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>

                        <Card sx={{width: '100%'}} className='mt-2 md:ml-3'>
                            <CardHeader title="Profile" subheader="Change your avatar here" />                        
                            <div className=' flex justify-center'>
                                <hr className='w-[80%]' />
                            </div>
                            <CardContent className='relative' sx={{display: 'flex',justifyContent:'center',alignItems:'center'}}>
                                {previewUrl ? 
                                    <IMAGE alt='profile' src={previewUrl} /> :
                                    (prof ? 
                                        <IMAGE alt='profile' src={prof} /> :
                                        <Avatar
                                            {...stringAvatar(`${formValues?.fname} ${formValues?.lname}`)}
                                            color="inherit"
                                            sx={{
                                                width: 100,
                                                height: 100,
                                                borderRadius: 50
                                            }}
                                        />
                                    )
                                }
                                <IconButton className='absolute right-14 top-3' onClick={onButtonClick}>
                                    <IoPencil />
                                </IconButton>
                                <input type='file' onChange={(e)=>{
                                    const file = e.target.files[0];
                                    setProfile(file);
                                    if (file) {
                                        const url = URL.createObjectURL(file);
                                        setPreviewUrl(url);
                                    }
                                    console.log("file:", file);
                                }} ref={image} id='image' accept=".jpg,.png,.webp,.jpeg,.svg" hidden />
                            </CardContent>
                        </Card>
                        <Card csx={{width: '100%'}} className='mt-10 md:ml-3' >
                            <CardContent>
                                <form noValidate autoComplete="off">
                                    <Stack direction={{sm:'column',md:'row'}} justifyContent={'space-around'}>
                                        <TextField
                                            id="name-input"
                                            name="fname"
                                            label="First Name"
                                            type="text"
                                            variant="outlined"
                                            margin="normal"
                                            onChange={handleInputChange}
                                            fullWidth
                                            color='secondary'
                                            value={formValues.fname}
                                        />
                                        <TextField
                                            id="name-input"
                                            name="lname"
                                            label="Last Name"
                                            type="text"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            className='md:ml-2'
                                            onChange={handleInputChange}
                                            color='secondary'
                                            value={formValues.lname}
                                        />
                                    </Stack>
                                    <Box mt={2}>
                                        <Button color='secondary' disabled={submitting} onClick={handleSubmit} variant='outlined' type="submit">
                                            {submitting?<CircularProgress />:"Save"}
                                        </Button>
                                    </Box>
                                </form>
                            </CardContent>   
                        </Card>

                    <Card sx={{width: '100%'}} className='mt-10 md:ml-3'>
                        <CardContent>
                            <Typography variant='h4'>Change Password</Typography>
                            <form noValidate autoComplete="off" onSubmit={handleChangePassword}>
                                <TextField
                                    name="oldpassword"
                                    label="Old Password"
                                    type="password"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={handlePasswordInputChange}
                                    fullWidth
                                    color='secondary'
                                    value={passwords.oldpassword}
                                />
                                <TextField
                                    name="newpassword"
                                    label="New Password"
                                    type="password"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    onChange={handlePasswordInputChange}
                                    color='secondary'
                                    value={passwords.newpassword}
                                />
                                <TextField
                                    name="confirmpassword"
                                    label="Confirm Password"
                                    type="password"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    onChange={handlePasswordInputChange}
                                    color='secondary'
                                    value={passwords.confirmpassword}
                                />
                                <Box mt={2}>
                                    <Button color='secondary' disabled={submitting} variant='outlined' type="submit">
                                        {submitting?<CircularProgress />:"Save"}
                                    </Button>
                                </Box>
                            </form>
                        </CardContent>   
                    </Card>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    {loading? 
                        <div className='h-[50vh] flex justify-center items-center'>
                            <CircularProgress color='secondary'/>
                        </div>
                    :datas&&<>
                        <div className="max-w-6xl mx-auto p-6 space-y-6">
                        {/* Header */}
                            <div className="flex justify-between items-center">
                                <h1 className="text-2xl font-semibold">Organization Usage Details</h1>
                                <a className="text-purple-600 hover:text-purple-700">
                                Questions? Contact support
                                </a>
                            </div>

                            {/* Billing Summary Cards */}
                            <div className="grid md:grid-cols-2 gap-6">
                                {/* Estimated Bill Card */}
                                <Card>
                                <CardContent className="p-6">
                                    <div className="space-y-4">
                                    <p className="text-gray-600">No. of Knowledge Hub created</p>
                                    <p className="text-4xl font-semibold">{datas.total_kb}</p>
                                    {/* <p className="text-gray-600">Period ends on November 1, 2024</p> */}
                                    </div>
                                </CardContent>
                                </Card>

                                {/* Credits Card */}
                                <Card>
                                    <CardContent className="p-6">
                                        <div className="space-y-4">
                                        <p className="text-gray-600">No. of files uploaded</p>
                                        <p className="text-4xl font-semibold">{datas.total_files}</p>
                                        <div className="space-y-2">
                                            <div className="flex justify-between text-sm">
                                            <span>{datas.total_file_size} Mb used</span>
                                            <span>24.96 GB Remain</span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                            <div 
                                                className="bg-purple-600 h-2 rounded-full" 
                                                style={{ width: '0.16%' }} 
                                            />
                                            </div>
                                        </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>

                            {/* Subscription and Payment Section */}
                            <Card>
                                <CardContent className="p-6">
                                    <div className="space-y-4">
                                        <p className="text-gray-600">No. of Assistants Created</p>
                                        <p className="text-4xl font-semibold">{datas.total_bots}</p>
                                    </div>
                                </CardContent>
                            </Card>
                            <Card>
                                <CardContent className="p-6">
                                    <div className="flex justify-between items-center">
                                        <div className="space-y-1">
                                            <p className="text-gray-600">No. of Tokens used</p>
                                            <p className="font-semibold">100</p>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>

                            {/* Database Usage Section */}
                            <div className="space-y-4">
                                <div className="flex justify-between items-center">
                                <h2 className="text-xl font-semibold">Knowledge Base Usage</h2>
                                <button 
                                    className="flex items-center gap-2 px-4 py-2 border rounded-md hover:bg-gray-50"
                                    onClick={downloadCSV}
                                >
                                    Download Usage as CSV
                                    <ChevronDown className="w-4 h-4" />
                                </button>
                                </div>

                                <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead>
                                        <tr className="border-b">
                                            <th className="text-left py-4 px-4 font-medium text-gray-600">Name</th>
                                            <th className="text-left py-4 px-4 font-medium text-gray-600">No of Files</th>
                                            <th className="text-left py-4 px-4 font-medium text-gray-600">Storage (MB)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datas?.kb_metrics?.length === 0 ? (
                                            <tr className="border-b hover:bg-gray-50">
                                                <td colSpan="3" className="py-4 px-4 text-center">No knowledge hub have been created</td>
                                            </tr>
                                        ) : (
                                            datas.kb_metrics.map((item, index) => (
                                                <tr key={index} className="border-b hover:bg-gray-50">
                                                    <td className="py-4 px-4 text-purple-600">{item.kb_name}</td>
                                                    <td className="py-4 px-4">{item.no_of_files}</td>
                                                    <td className="py-4 px-4">{Number(item.size).toFixed(1)}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </>}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    Beta version is free :)
                </CustomTabPanel>
            </Box>
        </div>
    )
}

export default Profile