// material-ui
import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| SAMPLE PAGE ||============================== //

const PublishAI = () => (
  <MainCard title="Publish Your AI ChatBot">
    <Typography variant="body2">
      Publish your AI Assistant to be accessable across Organization or to Public
    </Typography>
  </MainCard>
);

export default PublishAI;
