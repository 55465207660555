import { useState, useRef, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  useMediaQuery,
  Tooltip
} from '@mui/material';

// assets
import { getNotify } from 'services/app.services';
import { ChatBubbleOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router';


// ==============================|| NOTIFICATION ||============================== //

const ChatSection = () => {
  const nav = useNavigate()
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [list,setList] = useState([])
  const anchorRef = useRef(null);

  const onChatClick = () => {
    nav("/chat-with-bot")
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    const id = localStorage.getItem("uid")
    prevOpen.current = open;
    if(open === true){
      console.log("opened")
      getNotify(id).then((res)=>{
        console.log(res)
        setList(res)
      })
    }
  }, [open]);

  useEffect(()=>{
    const id = localStorage.getItem("uid")
    getNotify(id).then((res)=>{
      console.log(res)
      setList(res)
    })
  },[])

  return (
    <>
      <Box
        sx={{
          ml: 0.5,
          mr: 0.5,
          [theme.breakpoints.down('md')]: {
            mr: 2
          }
        }}
      >
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={onChatClick}
            color="inherit"
          >
            <Tooltip placement='bottom' title="Chat" arrow>
              <ChatBubbleOutline stroke={1.5} size="1.3rem" />
            </Tooltip>
          </Avatar>
        </ButtonBase>
      </Box>
    </>
  );
};

export default ChatSection;
