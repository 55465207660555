import { Grid } from '@mui/material'
import React from 'react'
import TopMobNavBar from './nav_new/TopMobNavBar'
import SideNavBar from './nav_new/SideNavBar'
import { Box } from '@mui/system'

function ChatScreen() {
  return (
    <Box sx={{  height: "100vh", background:"#ddd" }}>
      <TopMobNavBar />
      
    </Box>
  )
}

export default ChatScreen