import { lazy } from 'react';

// project imports
import HomePage from 'layout/MainPage/Home';

const HomeRoutes = {
    path: '/',
    element: <HomePage />
}

export default HomeRoutes