import axios from "axios";
import { useState } from "react";
import { useContext } from "react";
import { useEffect, createContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { googleLogout } from '@react-oauth/google';
import getUserInfo, { deleteKb, getCollections, getSingleTenants } from "services/app.services";

export const AccessContext = createContext(null)

export function AccessProvider({children}){
    const nav = useNavigate()
    // const URL = "http://localhost:8000"
    const URL = "https://fast.asklly.ai"


    const [uid, setUid] = useState("");
    const [org, setOrg] = useState("");
    const [state, setState] = useState(false)
    const [myTeams, setMyTeams] = useState([])
    const [logout, setLogout] = useState(false)
    const [showData, setShowData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [activeRole, setActiveRole] = useState("")
    const [activeTeam, setActiveTeam] = useState(null) // Initialize as null
    const [myAssistant, setMyAssistant] = useState([])
    const [knowldegeHubs, setKnowledgeHubs] = useState([])
    const [knowldegeHubsNames, setKnowledgeHubsNames] = useState([])
    
    useEffect(()=>{
        setUid("")
        setOrg("")
    },[logout])

    useEffect(()=>{
        const uuid = localStorage.getItem("uid")
        const orgn = localStorage.getItem("organization")
        if (uuid && orgn){
            setOrg(orgn)
            setUid(uuid)
            getActiveRole().then()
        }else{
            getOrgName().then()
        }
    },[state])
    
    const logOut = async() => {
        setUid("");
        setOrg("");
        setActiveRole(undefined);
        setActiveTeam(null);
        setMyAssistant([]);
        setKnowledgeHubs([]);
        setKnowledgeHubsNames([]);
        
        if(localStorage.getItem("gauthcred")){
            localStorage.removeItem("gauthcred");
            googleLogout();
        }
        setLogout(true);
        localStorage.clear();
        nav("/login");
    }
    
    const getActiveRole = async() => {
        try {
            setLoading(true)
            const teams = await getmyTeam()
            if (teams.length > 0) {
                await getTeamRole(teams[0].id)
            }
            await getKb(teams[0].id)
            await handleGetKbNames(teams[0].id)
            await fetchtrainedBots(teams[0].id)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const getmyTeam = async() => {
        try {
            const uuid = localStorage.getItem("uid")
            const user = await getUserInfo(uuid)
            const organization = await getOrgName()
            const form = new FormData()
            form.append("email", user?.email)
            form.append("org", organization)
            const { data } = await axios.post(`${URL}/team/get_teams`, form)
            console.log("Teams: ",data)
            setMyTeams(data) 
            return data
        } catch (error) {
            console.error(error)
        }
    }

    const handleTeamChange = async(id) => {
        try {
            setLoading(true)
            setDisabled(true)
            await getTeamRole(id)
            setTimeout(()=>{
                setDisabled(false)
            },1500)
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const getTeamRole = async(id) => {
        try {
            const uuid = localStorage.getItem("uid")
            const { data } = await axios.get(`${URL}/team/${id}/role/${uuid}`)
            console.log(data)
            if(!data.roles){
                return nav("/no-org")
            }
            console.log("Teams: ", data.team_id);
            setActiveTeam(data.team_id);
            setActiveRole(data.roles)
            await getKb(data.team_id)
            await handleGetKbNames(data.team_id)
            await fetchtrainedBots(data.team_id)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    async function getKbByName(form){
        try {
            const { data } = await axios.post(`${URL}/get/kb/names`, form, {
                headers: { 'team_id': activeTeam }
            });
            return data
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const getOrgName = async() => {
        const uuid = localStorage.getItem("uid")
        const user = await getUserInfo(uuid)
        const { data } = await axios.post(`${URL}/get/org`,{
          email: user?.email
        }, {
          headers: { 'team_id': activeTeam }
        })
        setOrg(data)
        return data
    }
    
    async function signup(fullname,email,password,gauth){
        try {
            const body = {
                email:email, 
                password:password,
                fullname:fullname,
                gauth:gauth
            }
            const { data } = await axios.post(`${URL}/create_user`,body, {
                headers: { 'team_id': activeTeam }
            });
            localStorage.setItem("ob",true)
            if(nav){
                toast("Check your inbox for OTP", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                localStorage.setItem('email',email);
                nav('/otp')
            }
            return data
        } catch (error) {
            if (error.response) {
                toast.error(`${error.response.data.detail || "There was an error in Signing up"}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in uploading data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function verifyOtp(email,otp){
        try {
            const body = {
                email:email, 
                otp:otp
            }
            const { data } = await axios.post(`${URL}/verify-otp`,body, {
                headers: { 'team_id': activeTeam }
            });
            localStorage.setItem("ob",true)
            if(nav){
                setUid(data?.uid)
                setState(true)
                localStorage.setItem('uid',data.uid)
                localStorage.setItem('user',JSON.stringify(data.user))
                await getActiveRole()
                nav('/marketplace')
            }
            return data
        } catch (error) {
            if (error.response) {
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("Server Error", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function resendOtp(email){
        try {
            const body = {
                email:email
            }
            const { data } = await axios.post(`${URL}/resend-otp`,body, {
                headers: { 'team_id': activeTeam }
            });
            toast("Otp Sent", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return data
        } catch (error) {
            if (error.response) {
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in uploading data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function login(email,password){
        try {
            const body = {
                email:email, 
                password:password
            }
            const { data } = await axios.post(`${URL}/login_user`,body, {
                headers: { 'team_id': activeTeam }
            });
            if(data.status_code){
                return toast.error(data.detail, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            localStorage.setItem('uid',data.uid)
            setUid(data?.uid)
            setState(true)
            localStorage.setItem('user',JSON.stringify(data.user))
            await getActiveRole()
            nav('/marketplace')
            return data
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    localStorage.setItem('email',email);
                    nav("/otp")
                    return toast.error(`${error.response.data.detail }`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }); 
                }
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in uploading data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function forgetPassword(email){
        try {
            const body = new FormData()
            body.append("email",email)
            const { data } = await axios.post(`${URL}/forget-password`,body, {
                headers: { 'team_id': activeTeam }
            });
            toast("Reset email sent", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return data
        } catch (error) {
            console.log(error);
            if (error.response) {
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in uploading data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function isEmail(email){
        try {
            const body = new FormData()
            body.append("email",email)
            const { data } = await axios.post(`${URL}/decrypt-email`,body, {
                headers: { 'team_id': activeTeam }
            });
            if(data?.email){
                return data
            }else{
                nav("/404")
                return data
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in uploading data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function changePasswordForgot(token,password){
        try {
            const body = new FormData()
            body.append("token",token)
            body.append("password",password)
            const { data } = await axios.post(`${URL}/reset-password`,body, {
                headers: { 'team_id': activeTeam }
            });
            toast(data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            nav("/login")
            return data
        } catch (error) {
            console.log(error);
            if (error.response) {
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in changing password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    async function fetchtrainedBots(id){
        try {
            setLoading(true)
            const uuid = localStorage.getItem("uid")
            const orgn = await getOrgName()
            if(!uuid) return alert("no uid")
            const headers = {
                'X-User-Id':uuid,
                'X-Org': orgn,
                'team_id': id
            }
            console.log(headers)
            const { data } = await axios.get(`${URL}/getmybots`,{headers})
            console.log("Response :",data.res)
            setMyAssistant(data.res || [])
            return {ok:true,data:data.res}
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    async function getKb(id){
        try {
            setKnowledgeHubs([]);
            setShowData([]);
            console.log(id);
            const uuid = localStorage.getItem("uid");
            const form = new FormData();
            const usr = await getUserInfo(uuid || uid);
            let email = usr.email || localStorage.getItem("email");
            form.append("email", email);
            form.append("team_id", id || activeTeam);
            const organization = await getOrgName();
            form.append("organization", organization || org);
            console.log("Team for header", id);
            const { data } = await axios.post(`${URL}/get/kb`, form, {
                headers: { 'team_id': id }
            });
            console.log("Current items count:", data.kb?.length);
            setShowData(data.kb || []);
            setKnowledgeHubs(data.kb || []);
            return data.kb;
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async function getNewKb(id){
        try {
            const uuid = localStorage.getItem("uid");
            const form = new FormData();
            const usr = await getUserInfo(uuid || uid);
            const email = usr.email;
            if (email == null) {
                email = localStorage.getItem("email");
            }
            form.append("email", email);
            form.append("team_id", id || activeTeam);
            const organization = await getOrgName();
            form.append("organization", organization || org);
            console.log("Team for header",id)
            const { data } = await axios.post(`${URL}/get/kb`, form, {
                headers: { 'team_id': id }
            });
            return data.kb
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    
    const handleGetCollections = async() => {
        try {
          const uid = localStorage.getItem("uid")
          console.log(uid)
          const all_collections = await getCollections(uid)
          console.log(all_collections.collections)
          return all_collections.collections
        } catch (error) {
          console.error(error)
        }
    }

    const deleteFile = async(deleteKeys) => {
        try {
            const form = new FormData();
            form.append("organization",org)        
            form.append("uid", uid);
            form.append("org", org);
            form.append("id", deleteKeys.join());
            await deleteKb(form);
        } catch (error) {
            console.log(error)
        }
    }

    const handleGetKbNames = async(id) => {
        try {
            setKnowledgeHubsNames([])
            const uuid = localStorage.getItem("uid");
            const form = new FormData()
            const user = await getUserInfo(uuid)
            form.append("email", user?.email)
            const organization = await getOrgName()
            form.append("organization",organization)
            form.append("team_id",id)
            const { data } = await axios.post(`${URL}/get/kb/names`, form, {
                headers: { 'team_id': id }
            });
            console.log(data)
            setKnowledgeHubsNames(data.kb)
            return data.kb
        } catch (error) {
            console.log(error)
        }
    }

    const getFavorites = async () => {
        try {
            const uid = localStorage.getItem("uid");
            const org = await getOrgName();
        
            const headers = {
                'X-User-Id': uid,
                'X-Org': org
            };
        
            const { data } = await axios.get(`${URL}/get_favorites`, { headers });
            return { ok: true, favorites: data.favorites };
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch favorites", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            throw error;
        }
    };

    const getFileSummary = async(id) => {
        try {
            console.log("filename", id)
            const { data } = await axios.get(`${URL}/kb/get_summary?name=${id}&id`);
            return data
        } catch (error) {
            console.error(error)
        }
    }

    const changeTags = async(form) => {
        try {
            const { data } = await axios.post(`${URL}/kb/modify_tags`, form)
            return data
        } catch (error) {
            console.error(error)
        }
    }

    return(
        <AccessContext.Provider value={{ changeTags,getFileSummary,getFavorites,getmyTeam,setShowData,showData,getNewKb,deleteFile,disabled,getActiveRole,myAssistant,handleTeamChange,loading,knowldegeHubs,knowldegeHubsNames,myTeams,activeTeam,activeRole,handleGetKbNames,handleGetCollections,signup,login,forgetPassword,resendOtp,verifyOtp,isEmail,changePasswordForgot,setUid,fetchtrainedBots,getOrgName,getKb,logOut,setActiveTeam }}>
            {children}
        </AccessContext.Provider>
    )
}

export function useAccess() {
    const context = useContext(AccessContext);
    if (!context) {
        throw new Error('useAccess must be used within an AccessProvider');
    }
    return context;
}
