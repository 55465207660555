import React, { useState, useRef, useEffect } from 'react';
import { TextField, Paper, List, Box, InputAdornment, Tabs, Tab } from '@mui/material';
import { Book, Bot, Heart, Search } from 'lucide-react';
import { addToFavorites } from 'services/app.services';
import { useNavigate } from 'react-router';
import { useAccess } from 'context/AccessContext';

const ITEMS_PER_PAGE = 4;

const SearchBarWithDropdown = () => {
    const [fav, setFav] = useState([]);
    const [search, setSearch] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [access, setAccess] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [collections, setCollections] = useState([]);
    const [activeSection, setActiveSection] = useState('favorites');

    const { knowldegeHubsNames, getFavorites, handleGetCollections, myAssistant, activeRole } = useAccess()

    const nav = useNavigate();
    const wrapperRef = useRef(null);
    const uid = localStorage.getItem("uid");

    useEffect(() => {
        console.log(knowldegeHubsNames)
        const fetchData = async () => {
            try {
                const favs = await getFavorites()
                setFav(favs.favorites)
                console.log(favs.favorites)
                const newCollection = await handleGetCollections()
                setCollections(newCollection);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
      
        fetchData();
    
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        
        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [activeSection, search]);

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const handleFocus = () => {
        setIsOpen(true);  // Open dropdown on focus
    };

    const handleSuggestionClick = (suggestion) => {
        setSearch(suggestion);
        setIsOpen(false);  // Close after selecting an item
    };

    const allSubItems = {
        'knowledge-hub':  knowldegeHubsNames.map(item => ({
            title: item.kb_name,
            category: 'Knowledge Hub',
            nav: `/kb?filter=${item?.kb_name}`,
            onclick: async() => {
                await addToFavorites(item?.kb_id, "knowledgebase")
                const favs = await getFavorites()
                setFav(favs.favorites)
            },
            fav: fav.some(f => f.category === "Knowledge Hub" && f.kb_id === item.kb_id)
        })),
        'ai-assistants': myAssistant.map(item => ({
            title: item.botname,
            description: 'AI Assistant',
            category: 'AI Assistants', 
            nav: `/api/${item?.apikey}`,
            onclick: async() => {
                await addToFavorites(item?.id, "bot")
                const favs = await getFavorites()
                setFav(favs.favorites)
            },
            fav: fav.some(f => f.category === "AI Assistants" && f.id === item.id)
        })),
        'favorites': fav.map(item => ({
            title: item?.botname ? item?.botname : item?.kb_name ? item?.kb_name : item.name,
            description: item.category,
            category: item.category,
            nav: item.category === 'AI Assistants' ? `/api/${item.apikey}` : item.category === 'collection'? `/collection?id=${item?.id}` : '/kb',
            onclick: async() => {
                await addToFavorites(item.category === 'knowledgebase'? item?.kb_id:  item?.id, item.category === 'AI Assistants' ? "bot" : item.category)
                const favs = await getFavorites()
                setFav(favs.favorites)
            },
            fav: true
        })),
        'collections': collections.map(item => ({ // New section for collections
            title: item.name,
            description: item.description,
            category: 'Collections',
            nav: `/collection?id=${item?.id}`, // Placeholder navigation
            onclick: async() => {
                await addToFavorites(item?.id, "collection")
                const favs = await getFavorites()
                setFav(favs.favorites)
            },
            fav: fav.some(f => f.category === "collection" && f.id === item.id)
        }))
    };

    const menuItems = [
        {
            id: 'favorites',
            icon: <Heart className="w-5 h-5" />,
            title: 'Favorites',
            items: ['favorites']
        },
        ...(activeRole.includes('Create Knowledge Base') || activeRole.includes('Admin')) ? [{
            id: 'knowledge-hub',
            icon: <Book className="w-5 h-5" />,
            title: 'Knowledge Hub',
            items: ['knowledge-hub']
        }] : [],
        ...(activeRole.includes('Only Query Knowledge Base') || activeRole.includes('Admin')) ? [{
            id: 'ai-assistants',
            icon: <Bot className="w-5 h-5" />,
            title: 'AI Assistants',
            items: ['ai-assistants']
        }] : [],
        ...(activeRole.includes('Only Query Knowledge Base') || activeRole.includes('Admin')) ? [{
            id: 'collections',
            icon: <Book className="w-5 h-5" />,
            title: 'Collections',
            items: ['collections']
        }] : []
    ];

    const Pagination = ({ totalItems }) => {
        const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
        
        return (
            <div className="flex justify-center mt-2 gap-2">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                    <button
                        key={page}
                        onClick={() => setCurrentPage(page)}
                        className={`px-2 py-1 rounded text-sm ${
                            currentPage === page
                                ? 'bg-purple-600 text-white'
                                : 'bg-gray-200 hover:bg-gray-300'
                        }`}
                    >
                        {page}
                    </button>
                ))}
            </div>
        );
    };

    const SubItemGrid = ({ items }) => {
        // Filter items based on search
        const filteredItems = items.filter(item => 
            (item.title && item.title.toLowerCase().includes(search.toLowerCase())) ||
            (item.description && item.description.toLowerCase().includes(search.toLowerCase())) ||
            (item.category && item.category.toLowerCase().includes(search.toLowerCase()))
        );

        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const paginatedItems = filteredItems.slice(startIndex, startIndex + ITEMS_PER_PAGE);
        
        return (
            <div className={`flex flex-col ${isMobile ? 'h-[calc(70vh-15rem)]' : 'h-[280px]'}`}>
                <div className={`grid gap-2 ${isMobile ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2'} overflow-y-auto flex-grow px-2`}>
                    {paginatedItems.length === 0 ? (
                        <div className="text-center w-full text-gray-500">
                            <p>No {activeSection} found.</p>
                        </div>
                    ) : (
                        paginatedItems.map((item, index) => (
                            <div 
                                key={index}
                                onClick={()=>{
                                    nav(item?.nav)
                                    setIsOpen(false)
                                }}
                                className="bg-white rounded-lg p-2 hover:shadow-md transition-all cursor-pointer h-[100px] w-full"
                            >
                                <div className="flex flex-col h-full justify-between">
                                    <div className="flex justify-between items-start gap-2">
                                        <div className="flex-1 min-w-0"> {/* Added min-w-0 to allow text truncation */}
                                            <h3 className="text-sm font-semibold text-gray-900 truncate">{item.title}</h3>
                                            <p className="text-gray-600 text-xs mt-1 truncate">{item.description}</p>
                                        </div>
                                        <div className="flex items-center gap-2 flex-shrink-0"> {/* Added flex-shrink-0 */}
                                            <Heart 
                                                fill={item?.fav ? "red" : "none"}
                                                className={`w-4 h-4 ${item?.fav ? "text-red-500" : "text-gray-400"} hover:text-red-500 cursor-pointer`}
                                                onClick={(e) => {
                                                    item?.onclick()
                                                    e.stopPropagation();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <Pagination totalItems={filteredItems.length} />
            </div>
        );
    };

    return (
        <div className="relative mx-4" ref={wrapperRef}>
            <TextField
                variant="outlined"
                fullWidth
                color='secondary'
                value={search}
                onChange={handleSearchChange}
                placeholder="Search"
                onFocus={handleFocus}
                InputProps={{
                    startAdornment:<InputAdornment>
                        <Search size={16} />
                    </InputAdornment> ,
                    endAdornment: <i className="fas fa-search text-gray-500" />
                }}
                className="bg-white"
            />

            {isOpen && (
                <Paper 
                    className={`absolute top-full left-0 w-full bg-white shadow-lg z-10 ${isMobile ? 'h-[calc(70vh-8rem)]' : 'h-80'} overflow-hidden`}
                    elevation={3}
                    sx={{ position: 'relative', marginTop: 1, display: 'flex', flexDirection: 'column', width: '100%' }}
                >
                    {isMobile ? (
                        <>
                            <Box sx={{ maxWidth: '100%', overflow: 'auto' }}>
                                <Tabs
                                    value={activeSection}
                                    onChange={(e, newValue) => setActiveSection(newValue)}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    className="border-b"
                                    indicatorColor='secondary'
                                    textColor='secondary'
                                >
                                    {menuItems.map((item) => (
                                        <Tab
                                            key={item.id}
                                            value={item.id}
                                            icon={item.icon}
                                            label={item.title}
                                            className="text-xs"
                                        />
                                    ))}
                                </Tabs>
                            </Box>
                            <Box className="flex-1 overflow-hidden">
                                <SubItemGrid items={allSubItems[activeSection] || []} />
                            </Box>
                        </>
                    ) : (
                        <div className="flex h-full">
                            <Box className="w-48 p-2" sx={{ borderRight: '1px solid #ddd' }}>
                                <List className="space-y-1">
                                    {menuItems.map((item) => (
                                        <div
                                            key={item.id}
                                            className={`flex items-center space-x-2 p-2 rounded-lg cursor-pointer
                                            transition-colors ${
                                            activeSection === item.id 
                                                ? 'bg-purple-600 text-white' 
                                                : 'hover:bg-purple-50 text-gray-600'
                                            }`}
                                            onClick={()=>{
                                                setActiveSection(item.id);
                                            }}
                                        >
                                            <div className={activeSection === item.id ? 'text-white' : 'text-gray-400'}>
                                                {item.icon}
                                            </div>
                                            <div className="font-medium text-sm">{item.title}</div>
                                        </div>
                                    ))}
                                </List>
                            </Box>
                            <Box className="flex-1 overflow-hidden">
                                <SubItemGrid items={allSubItems[activeSection] || []} />
                            </Box>
                        </div>
                    )}
                </Paper>
            )}
        </div>
    );
};

export default SearchBarWithDropdown;