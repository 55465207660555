import { IconSettings,IconBuilding,IconUsers,IconGraph } from "@tabler/icons"

const icons = {
    IconUsers, IconSettings, IconBuilding, IconGraph
}

const team_page = {
    id: 'Teams',
    title: 'Admin Related',
    type: 'group',
    children: [
        {
            id: 'Teams',
            title: 'Teams',
            type: 'item',
            url: '/teams',
            icon: icons.IconUsers
        },
        {
            id: 'Accounts',
            title: 'Settings',
            type: 'item',
            url: '/profile',
            icon: icons.IconSettings
        },
        {
            id: 'Activities',
            title: 'Activity Log',
            type: 'item',
            url: '/audit',
            icon: icons.IconGraph
        }
    ]
}

export default team_page