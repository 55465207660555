/* Create bot using link component */
import { useEffect, useState } from "react";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "components/Loader";
import { useNavigate } from "react-router-dom";
import getUserInfo, { addkburl, fetchkbnames, getAllUrls, getKbByName, getMemberRole, getUrls, saveAllUrls } from "services/app.services";
import Button from '@mui/material/Button';
import { CircularProgress, TextField, Stack, Typography , Select, MenuItem, IconButton} from "@mui/material";
import MainCard from 'ui-component/cards/MainCard';
import { ArrowRight, ChevronRight, Close, Home, HomeMaxOutlined } from "@mui/icons-material";

function LinkComp({close,getKbData}) {
    const [url,setUrl] = useState("")
    const [bot,setBot] = useState("")
    const [urls,setUrls] = useState([])
    const [loading,setLoading] = useState(false)
    const [loading1,setLoading1] = useState(false)
    const [fname,setFName] = useState("")
    const [kbname,setKbName] = useState([]);
    const [val,setVal] = useState("")
    const nav = useNavigate()
    const [uid,setUid] = useState()
    const [access,setAccess] = useState(false)
    const [kb_id,setKb_Id] = useState("")
    const [tags, setTags] = useState([]);
    const [currentTag, setCurrentTag] = useState('');

    const fetchKbName = async() => {
        const uid = localStorage.getItem("uid");
        const form = new FormData()
        const user = await getUserInfo(uid)
        form.append("email", user?.email)
        const organization = localStorage.getItem("organization")
        form.append("organization",organization)
        const data = await getKbByName(form)
        setKbName(data.kb)
        const form2 = new FormData()
        form2.append("email",user?.email)
        const role = await getMemberRole(form2)
        if(role?.role?.role === "Admin"){
          setAccess(true)
        }else{
          setAccess(false)
        }
    }

    useEffect(()=>{
        fetchKbName()
    },[])
    /** function to get all the urls */
    const handleSubmit = async() => {
        setLoading(true)
        const res = await getUrls(url,uid)
        if (res){
            setUrls(res)
        }
        setLoading(false)
    }

    const crawlallurls = async() => {
        try {
            setLoading(true)
            const form = new FormData()
            form.append("url",url)
            const { main_urls } = await getAllUrls(form);
            const allUrls = []
            main_urls?.map((item,index)=>allUrls.push({id:index,url:item}))
            setUrls(allUrls)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const removeTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    const handleTagInputKeyDown = (e) => {
        if (e.key === 'Enter' && currentTag.trim()) {
            e.preventDefault();
            if (!tags.includes(currentTag.trim())) {
            setTags([...tags, currentTag.trim()]);
            setCurrentTag('');
            }
        }
    };

    /** function to submit the urls to create the bot */
    const handleCreate = async() =>{
        setLoading1(true)
        if(urls.length === 0) {
            setLoading1(false)
            return  toast.error("Fetch or add the urls to create", {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }else if(val === true && bot === ""){
            setLoading1(false)
            return  toast.error("Enter an bot name to continue", {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }else if(tags.length === 0){
            setLoading(false)
            return toast.error("Please add at least one tag", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
        }
        const body = new FormData();
        const dateObj = new Date
        const day = dateObj.getDate().toString()
        const month = dateObj.getMonth() + 1
        const year = dateObj.getFullYear().toString()
        const uid = localStorage.getItem("uid")
        const organization = localStorage.getItem("organization")
        const user = await getUserInfo(uid)
        body.append("tags",tags.join(","))
        body.append("organization",organization)
        body.append("filename",fname)
        body.append("uid",uid)
        body.append("created_by",user?.uname)
        if(val !== true){
            body.append("kb_name",val)
        }else if(bot){
            body.append("kb_name",bot)
        }
        body.append("last_modified", dateObj.getTime().toString());
        body.append("main_url",url)
        body.append("kb_id",kb_id)
        body.append("file_type","link")
        for (const url of urls) {
            body.append("urls", url.url);
        }
        const res = await saveAllUrls(body)
        if(res){
            setUrl("")
            setUrls("")
            close(false)
            nav("/kb");
        }
        getKbData()
        close(false)
        setLoading1(false)
    }
    /** function to remove the particular url */
    const handleDelete = async(id) => {
        setUrls([...urls.filter((item,i)=>item.id !== id)])
    }

    const isAddButtonDisabled = () => {
        if (!fname) return true;
        if (val === "") return true;
        if (val === true && !bot) return true;
        if (urls.length === 0) return true;
        if (tags.length === 0) return true;
        return loading;
    }

    return (
        <Stack sx={{ mt: -5}}>
            <div className="flex justify-between w-full items-center">
                <Typography variant="h3">Crawl URLs to your Knowledge Hub</Typography>
                <IconButton onClick={()=>close(false)}>
                    <Close />
                </IconButton>
            </div>
            <Stack justifyContent={'center'}  sx={{mt:2}} alignItems={'center'}>
                <Stack sx={{width: '100%'}}>
                    <MainCard>  
                        <div className="flex mt-2 items-center">
                            <TextField
                                variant="outlined"
                                label="Enter the URL to crawl"
                                id="kbname"
                                color="secondary"
                                fullWidth
                                sx={{mr:2}}
                                disabled={loading}
                                onChange={(e)=>setUrl(e.target.value)}
                            />
                            <Button 
                                variant="outlined"
                                color="secondary"
                                sx={{width:130}}
                                disabled={loading || !/^https?:\/\/[^\s/$.?#].[^\s]*$/.test(url)} // Disable if the URL is not in valid format
                                onClick={crawlallurls} 
                                // className="bg-[#5E35B1] text-white px-10 py-2 rounded-lg hover:scale-105 transition-all ease-in-out duration-300"
                            >
                                {loading?<Loader />:"Crawl"}
                            </Button>
                        </div>
                        {urls.length > 0 && (
                            <>
                                <div className="flex flex-col mt-2">
                                    <label className="label">Included urls</label>
                                    <div className="max-h-60 overflow-y-auto">
                                        {urls.map((i) => (
                                            <div key={i.id} className="flex mb-2 p-2 bg-gray-100 rounded">
                                                <div className="flex-1 break-all text-sm md:text-base">{i.url}</div>
                                                <IconButton onClick={() => handleDelete(i.id)} size="small">
                                                    <Close fontSize="small" />
                                                </IconButton>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="font-bold mt-4">Included url count: <span className="text-gray-600 font-semibold">{urls.length}</span></div>
                            </>
                        )}
                        <div className="border-2 p-7 mt-4 rounded-xl">
                            <label className="label">File Name</label>
                            <TextField
                                variant="outlined"
                                label="Enter the File name"
                                color="secondary"
                                onChange={(e)=>{
                                    setFName(e.target.value)
                                }}
                                value={fname}
                                fullWidth
                                disabled={loading}
                                sx={{mt:2}}
                            />
                            <label className="label">Knowledge Hub</label>
                            <div className="flex flex-col justify-center">
                            <Select
                                onChange={(e)=>{
                                    setVal(e.target.value)
                                    if(e.target.value !== true){
                                        const selectedKb = kbname.find(kb => kb.kb_name === e.target.value);
                                        setKb_Id(selectedKb?.kb_id)
                                    }
                                }}
                                value={val}
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                disabled={loading}
                                >
                                    {kbname?.map((i,index)=>(<MenuItem value={i?.kb_name} key={index}>{i?.kb_name}</MenuItem>))}
                                    {access&&<MenuItem value={true}>New Knowledge Hub</MenuItem>}
                                </Select>
                                {val === true &&<TextField
                                    variant="outlined"
                                    label="Enter the Knowledge base name"
                                    color="secondary"
                                    onChange={(e)=>{
                                        setBot(e.target.value)
                                        setKb_Id(e.target.value)
                                    }}
                                    fullWidth
                                    disabled={loading}
                                    sx={{mt:2}}
                                />}
                            </div>
                            {/* Tags Section */}
                            <div className="mt-6 space-y-2">
                                <label className="text-sm font-medium text-gray-700">Add Tags</label>
                                <div className="flex flex-wrap gap-2 mb-3">
                                {tags.map((tag, index) => (
                                    <span
                                    key={index}
                                    className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
                                    >
                                    #{tag}
                                    <button
                                        onClick={() => removeTag(tag)}
                                        className="ml-2 hover:text-blue-600"
                                    >
                                        <Close className="w-4 h-4" />
                                    </button>
                                    </span>
                                ))}
                                </div>
                                <div className="flex items-center">
                                <input
                                    type="text"
                                    value={currentTag}
                                    onChange={(e) => setCurrentTag(e.target.value)}
                                    onKeyDown={handleTagInputKeyDown}
                                    placeholder="Type tag and press Enter"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    disabled={loading}
                                />
                                </div>
                            </div>
                                <div className="flex mt-3 justify-center">
                                    <Button 
                                        onClick={handleCreate} 
                                        variant="outlined"
                                        color="secondary"
                                        sx={{mt:3}}
                                        disabled={isAddButtonDisabled()}
                                        // className="bg-[#5E35B1] w-full h-10 rounded-xl mt-4 text-white font-semibold hover:scale-105 transition-all duration-200 ease-in-out"
                                    >
                                        {loading1?<CircularProgress color="secondary" />:"Add to Knowledge Hub"}
                                    </Button>
                                </div>
                            </div>
                    </MainCard>
                </Stack>
            </Stack>
        </Stack>
    );
}
  
export default LinkComp;