import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import getUserInfo, { addkbtext, getKbByName, getMemberRole } from "services/app.services";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, Select, MenuItem, TextField, Typography, IconButton } from "@mui/material";
import MainCard from 'ui-component/cards/MainCard';
import { Stack } from "@mui/system";
import { Close } from "@mui/icons-material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function TextArea({close,getKbData,fetchKbNameTop}) {
    const [text,setText] = useState('')
    const [count,setCount] = useState(0)
    const [fileName,setFileName] = useState("")
    const [loading,setLoading] = useState(false)
    const [bot,setBot] = useState('')
    const [uname,setUname] = useState('')
    const [uid,setUid] = useState()
    const [kbname,setKbName] = useState([]);
    const [val,setVal] = useState("")
    const nav = useNavigate()
    const [access,setAccess] = useState(false)
    const [kb_id,setKb_Id] = useState("")
    const [tags, setTags] = useState([]);
    const [currentTag, setCurrentTag] = useState('');

    const fetchKbName = async() => {
        const uid = localStorage.getItem("uid");
        setUid(uid)
        const form = new FormData()
        const user = await getUserInfo(uid)
        form.append("email", user?.email)
        const organization = localStorage.getItem("organization")
        form.append("organization",organization)
        const data = await getKbByName(form)
        setKbName(data.kb)
        setUname(user?.uname)
        const form2 = new FormData()
        form2.append("email",user?.email)
        const role = await getMemberRole(form2)
        if(role?.role?.role === "Admin"){
          setAccess(true)
        }else{
          setAccess(false)
        }
    }

    useEffect(()=>{
        fetchKbName()
    },[])

    const handleTextChange = (value) => {
        setText(value);
        setCount(value.length);
    };

    const isSubmitDisabled = () => {
        return !text || !fileName || !val || (val === true && !bot) || tags.length === 0;
    };

    const removeTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    const handleTagInputKeyDown = (e) => {
        if (e.key === 'Enter' && currentTag.trim()) {
            e.preventDefault();
            if (!tags.includes(currentTag.trim())) {
                setTags([...tags, currentTag.trim()]);
                setCurrentTag('');
                console.log(...tags)
            }
        }
    };

    const handleSubmit = async() =>{
        try {
            setLoading(true);
            if(text === ""){
                setLoading(false)
                return  toast.error("Enter the data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if(fileName === ""){
                setLoading(false)
                return  toast.error("Enter the file name", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if(val === true && bot === ""){
                setLoading(false)
                return  toast.error("Enter the knowledge hub name to continue", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }else if(tags.length === 0){
                setLoading(false)
                return toast.error("Please add at least one tag", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
            }
            const body = new FormData();
            const dateObj = new Date
            console.log(tags)
            body.append("tags",tags.join(","))
            body.append("created_id",uid)
            body.append("created_by",uname)
            body.append("filename",fileName)
            if(val !== true){
                body.append("kb_name",val)
            }else if(bot){
                body.append("kb_name",bot)
            }
            body.append("last_modified", dateObj.getTime().toString());
            body.append("file_type","data")
            body.append("kbdata","")
            body.append("kb_id",kb_id)
            body.append("data",text)
            const organization = localStorage.getItem("organization")
            body.append("organization",organization)
            const res = await addkbtext(body)
            if(res){
                setText("")
                setBot("")
                close(false)
                nav('/kb')
                setLoading(false)
            }
            getKbData()
            fetchKbNameTop()
            close(false)
            setLoading(false)
        } catch (error) {
            console.error("Error submitting text:", error);
            toast.error("An error occurred while submitting the text", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false);
        }
    }

    return ( 
        <Stack sx={{ mt: -5}} height={"90vh"}>
            <div className="flex justify-between w-full items-center">
                <Typography variant="h3">Capture data to your Knowledge Hub</Typography>
                <IconButton onClick={()=>close(false)}>
                    <Close />
                </IconButton>
            </div>
            <Stack justifyContent={'center'} alignItems={'center'}>
                <Stack sx={{width: '100%', height: "100%",mt: 2, border: 0.7, borderColor: "#EFF7FE", borderRadius: 2, padding: 3}}>
                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data Here</label>
                    <ReactQuill
                        theme="snow"
                        value={text}
                        onChange={handleTextChange}
                        placeholder="Enter Data to be trained"
                        modules={{
                            toolbar: [
                                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                                [{size: []}],
                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                [{'list': 'ordered'}, {'list': 'bullet'}, 
                                    {'indent': '-1'}, {'indent': '+1'}],
                                ['link', 'image'],
                                ['clean']
                            ],
                        }}
                        formats={[
                            'header', 'font', 'size',
                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'indent',
                            'link', 'image'
                        ]}
                        style={{height: '200px', marginBottom: '50px'}}
                    />
                    <div className="border-2 mt-10 p-2 rounded-xl">
                        <div className="flex mt-3 flex-col justify-center">
                            <label className="label">File Name</label>
                            <TextField
                                variant="outlined"
                                label="Enter File Name"
                                color="secondary"
                                onChange={(e)=>setFileName(e.target.value)}
                                fullWidth
                                disabled={loading}
                                sx={{mt:2,mb:2}}
                            />
                            <label className="label">Knowledge Hub </label>
                            <Select
                                onChange={(e)=>{
                                    setVal(e.target.value)
                                    if(e.target.value !== true){
                                        const selectedKb = kbname.find(kb => kb.kb_name === e.target.value);
                                        setKb_Id(selectedKb?.kb_id)
                                    }
                                }}
                                value={val}
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                disabled={loading}
                            >
                                {kbname?.map((i,index)=>(<MenuItem value={i?.kb_name} key={index}>{i?.kb_name}</MenuItem>))}
                                {access&&<MenuItem value={true}>New Knowledge Hub</MenuItem>}
                                </Select>
                                {val === true &&<TextField
                                variant="outlined"
                                label="Enter the Knowledge Hub name"
                                color="secondary"
                                onChange={(e)=>{
                                    setBot(e.target.value)
                                    setKb_Id(e.target.value)
                                }}
                                fullWidth
                                disabled={loading}
                                sx={{mt:2}}
                                />}
                            </div>
                            
                            {/* Tags Section */}
                            <div className="mt-6 space-y-2">
                                <label className="text-sm font-medium text-gray-700">Add Tags</label>
                                <div className="flex flex-wrap gap-2 mb-3">
                                {tags.map((tag, index) => (
                                    <span
                                    key={index}
                                    className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
                                    >
                                    #{tag}
                                    <button
                                        onClick={() => removeTag(tag)}
                                        className="ml-2 hover:text-blue-600"
                                    >
                                        <Close className="w-4 h-4" />
                                    </button>
                                    </span>
                                ))}
                                </div>
                                <div className="flex items-center">
                                <input
                                    type="text"
                                    value={currentTag}
                                    onChange={(e) => setCurrentTag(e.target.value)}
                                    onKeyDown={handleTagInputKeyDown}
                                    placeholder="Type tag and press Enter"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    disabled={loading}
                                />
                                </div>
                            </div>


                            <div className="flex mt-2 justify-center">
                                {!loading?<Button
                                    onClick={handleSubmit}
                                    variant="outlined"
                                    color="secondary"
                                    sx={{mt:2}}
                                    disabled={isSubmitDisabled()}
                                >
                                    Add to Knowledge Hub
                                </Button>:
                                <Button 
                                    disabled 
                                    variant="outlined"
                                    color="secondary"
                                    sx={{mt:2}}
                                >
                                <CircularProgress color="secondary" />
                            </Button>}
                        </div>
                    </div>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default TextArea;