const Footer = () => (
    <footer className="bg-white border-t">
      <div className="max-w-7xl mx-auto px-4 py-8 md:py-12">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <div className="text-xl font-bold bg-gradient-to-r from-purple-600 to-pink-600 text-transparent bg-clip-text mb-4">
              AskLLY.ai
            </div>
            <p className="text-gray-500 text-sm">Experience the future of AI assistance, customized just for you.</p>
          </div>
          
          <div>
            <h3 className="font-semibold mb-3">Product</h3>
            <ul className="space-y-2 text-sm text-gray-500">
              <li>Features</li>
              <li>Use cases</li>
              <li>Marketplace</li>
              <li>Documentation</li>
            </ul>
          </div>
  
          <div>
            <h3 className="font-semibold mb-3">Company</h3>
            <ul className="space-y-2 text-sm text-gray-500">
              <li>About</li>
              <li>Careers</li>
              <li>Blog</li>
              <li>Contact</li>
            </ul>
          </div>
  
          <div>
            <h3 className="font-semibold mb-3">Legal</h3>
            <ul className="space-y-2 text-sm text-gray-500">
              <li>Privacy Policy</li>
              <li>Terms of Service</li>
              <li>Cookie Policy</li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t text-center text-sm text-gray-500">
          {/* <p>© {new Date().getFullYear()} AskLLY.ai. All rights reserved.</p> */}
          <p>© {new Date().getFullYear()} AskLLY.ai. Research Purposes Only.</p>
        </div>
      </div>
    </footer>
  );
  
  export default Footer;