import { AddCircleOutline, ContentCopy, DeleteForeverOutlined, Subject, ThumbDown } from '@mui/icons-material'
import { AppBar, Box, Chip, CircularProgress, IconButton, List, MenuItem, Toolbar, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, Select } from '@mui/material'
import { Stack, useTheme } from '@mui/system'
import ChatCard from 'components/chatCard/ChatCard'
import ChatHeader from 'layout/MainLayout/Header/ChatHeader'
import SearchSection from 'layout/MainLayout/Header/SearchSection'
import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { createCollection, deleteMessageFromCollection, getAllChats, getCollections, getSingleCollections, addMessageToCollection, deleteChat, fetchtrainedApi } from 'services/app.services'
import Answering from './AnswerRendering'
import copy from 'copy-to-clipboard'
import { ExternalLink, ListIcon, Trash } from 'lucide-react'
import { purple } from '@mui/material/colors'
import { toast } from 'react-toastify'
import PDFReader from 'components/pdf/PDFViewer'
import { useRef } from 'react'
import Divider from '@mui/material/Divider';
import MenuCard from 'components/menuCard'

function Collections() {
    const theme = useTheme()
    const nav = useNavigate();

    const [show, setShow] = useState(true)
    const [nloading, setNLoading] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [allChat, setAllChat] = useState([])
    const [sp,setSp] = useSearchParams();
    const [collections, setCollections] = useState([])
    const [openCreateCollection, setOpenCreateCollection] = useState(false);
    const [newCollection, setNewCollection] = useState({ name: '', description: '', aiPrompt: '' });
    const [qna, setQna] = useState([])
    const [openFeedback,setOpenFeedBack] = useState(false)
    const [chatIndex,setChatIndex] = useState(0);
    const [links,setLinks] = useState([])
    const [openCollectionPopup, setOpenCollectionPopup] = useState(false);
    const [openD,setOpen] = useState(false)
    const [file,setFile] = useState("")
    const [collectionName,setCollectionName] = useState("")
    const [fileName,setFileName] = useState([])
    const [cid,setCid] = useState("")
    const [collectionSelected, setCollectionSelected] = useState({
        cid: "",
        index: "",
        selectedCollectionId: ""
    })
    const [filteredChats, setFilteredChats] = useState({
        today: [],
        yesterday: [],
        previous7Days: [],
        previous30Days: [],
        lastYear: []
    })
    const [isCreating, setIsCreating] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [collectionSearch, setCollectionSearch] = useState("");
    const [apis,setApis] = useState([])
    
    const query = sp.get('id')
    const uid = localStorage.getItem("uid")
    const endOfMessagesRef = useRef()

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const getBots = async() => {
        try {
          const uid = localStorage.getItem("uid");
          const bots = await fetchtrainedApi(uid);
          console.log(bots);
          setApis(bots.data);
        } catch (error) {
          console.log(error);
        }
    }
    
    const splitChatsByTimeframe = () => {
        const todayChats = [];
        const yesterdayChats = [];
        const previous7DaysChats = [];
        const previous30DaysChats = [];
        const lastYearChats = [];
    
        allChat.forEach(chat => {
          const chatDate = new Date(chat.timestamp * 1000);
          const diffTime = today - chatDate;
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays === 0) {
            todayChats.push(chat);
          } else if (diffDays === 1) {
            yesterdayChats.push(chat);
          } else if (diffDays <= 7) {
            previous7DaysChats.push(chat);
          } else if (diffDays <= 30) {
            previous30DaysChats.push(chat);
          } else {
            lastYearChats.push(chat);
          }
        });
    
        return { todayChats, yesterdayChats, previous7DaysChats, previous30DaysChats, lastYearChats };
    };

    const { todayChats, yesterdayChats, previous7DaysChats, previous30DaysChats, lastYearChats } = splitChatsByTimeframe();
    const sortedTodayChats = [...todayChats]
    const sortedYesterdayChats = [...yesterdayChats]
    const sortedPrevious7DaysChats = [...previous7DaysChats]
    const sortedPrevious30DaysChats = [...previous30DaysChats]
    const sortedLastYearChats = [...lastYearChats]

    const sortChats = (chats) => {
        return chats.sort((a, b) => b.timestamp - a.timestamp);
    };

    const copyText = (i) => {
        const answerWithoutBr = qna[i].answer.replace(/<br\/?>/g, '');
        copy(answerWithoutBr)
        toast.success("Copied text",{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
    }

    useEffect(()=>{
        getAllChats(uid).then((res)=>{
            setAllChat(res)
        })
    },[])

    useEffect(() => {
        const filterChats = (chats) => {
            return sortChats(chats.filter(chat => 
                chat.chatName.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        };
    
        const newFilteredChats = {
            today: filterChats(sortedTodayChats),
            yesterday: filterChats(sortedYesterdayChats),
            previous7Days: filterChats(sortedPrevious7DaysChats),
            previous30Days: filterChats(sortedPrevious30DaysChats),
            lastYear: filterChats(sortedLastYearChats)
        };

        // Only update state if the filtered chats have changed
        if (JSON.stringify(newFilteredChats) !== JSON.stringify(filteredChats)) {
            setFilteredChats(newFilteredChats);
        }
    }, [searchTerm, sortedTodayChats, sortedYesterdayChats, sortedPrevious7DaysChats, sortedPrevious30DaysChats, sortedLastYearChats, filteredChats]);


    const renderChatSection = (title, chats) => {
        if (chats.length === 0) return null;
        return (
          <Stack>
            <Typography sx={{fontWeight: 400}}>{title}</Typography>
            {chats.map((i) => (
              <ChatCard i={i} handleDelete={handleDelete} shareChat={()=>shareChat(i.chatID)} />
            ))}
          </Stack>
        );
    };

    const handleGetCollections = async() => {
        try {
          console.log(uid)
          const all_collections = await getCollections(uid, nav)
          console.log(all_collections.collections)
          if (all_collections.collections.length === 0) {
            nav('/404');
          } else {
            setCollections(all_collections.collections)
          }
        } catch (error) {
          console.error(error)
          nav('/404');
        }
    }

    useEffect(()=>{
        setQna([])
        setNLoading(true)
        handleGetCollections()
        getSingleCollections(query, uid).then(res=>{
            console.log(res.collection)
            setCollectionName(res.collection.name)
            setCid(res.collection.id)
            res.collection.messages?.map((i)=>{
                const referencesMatch = i?.answer?.match(/references\s*([\s\S]+)$/i);
                const referencesArray = [];
                if (referencesMatch && referencesMatch[1]) {
                  const referencesString = referencesMatch[1].trim();
                  referencesArray.push(...referencesString.split(',').map(reference => reference.trim()));
                }       
                
                const parseToMarkDown = i?.answer?.replace(/\\n/gi, "\n") || "";
                const referencesSplit = parseToMarkDown.split("references;;");
                const answer = referencesSplit[1] ? referencesSplit[0] : referencesSplit[0];
                const reference = i?.answer?.split("references;;")[1] ? i?.answer?.split("references;;")[1].split(';;;') : [];
                setQna((prev) => [
                    ...prev,
                    {
                        ...i,
                        answer: answer,
                        reference: reference.length > 0 ? reference.map(ref => ref.replace(/<br\/>/gi, " ")) : [],
                        isLoading: false,
                        links: i.link ? i.link : []
                    }
                ]);
                setLinks(i.link || []);
            })
            setNLoading(false)
        })
    },[sp])


    const handleOpenCreateCollection = () => {
        setOpenCreateCollection(true);
    };

    const handleCloseCreateCollection = () => {
        setOpenCreateCollection(false);
        setNewCollection({ name: '', description: '', aiPrompt: '' });
    };

    const handleCreateCollection = async() => {
        setIsCreating(true)
        const uid = localStorage.getItem("uid")
        const form = new FormData()
        form.append("collection_name", newCollection.name)
        form.append("collection_description", newCollection.description)
        form.append("ai_prompt", newCollection.aiPrompt || "null")
        form.append("uid", uid)
        await createCollection(form)
        await handleGetCollections()
        handleCloseCreateCollection();
        setIsCreating(false)
    };

    const openPDF = async(index, reference, link) => {
        if (!links || links.length === 0) {
            return;
        } else {
            // Extract the file name from the reference
            const fileName = reference.trim();
        
            // Find the correct link by matching the file name
            const correctLink = links.find(url => url.includes(fileName));
        
            if (correctLink) {
                setFile(correctLink);
                setFileName(fileName);
                setOpen(true);
                console.log("Opening PDF:", correctLink); // For debugging
            } else {
                console.error("Link not found for file:", fileName);
                // Optionally, show an error message to the user
            }
        }
    }

    const closePDF = async() => {
        setFile("")
        setFileName("")
        setOpen(false)
    }

    const [deletingIndexes, setDeletingIndexes] = useState([]);

    const handleDeleteMessage = async (index) => {
        const urlParam = new URLSearchParams(window.location.search).get("id");
        setDeletingIndexes(prev => [...prev, index]);
        try {
            await deleteMessageFromCollection(urlParam, index, uid);
            setQna(prev => prev.filter((_, i) => i !== index))
        } catch (error) {
            console.error("Error deleting message:", error);
        } finally {
            setDeletingIndexes(prev => prev.filter(i => i !== index));
        }
    };

    const handleDelete = async(id) => {
        const uid = localStorage.getItem("uid")
        const del = await deleteChat(uid,id)
        sp.delete("id")
        nav('/chat-with-bot')
    }

    const shareChat = (chatId) => {
        console.log(chatId)
        const { origin } = window.location;
        const url = `${origin}/share?id=${chatId}`
        copy(url)
        toast.success("Coppied Url",{
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    }

    // Use useMemo for filtering collections
    const filteredCollections = useMemo(() => {
        const filtered = collections.filter(collection => 
            collection.name.toLowerCase().includes(collectionSearch.toLowerCase().trim())
        );
        return filtered.length > 0 ? filtered : []; // Return empty array if no matches
    }, [collectionSearch, collections]);

    return (
        <Box sx={{
            backgroundColor: '#fff',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                bgcolor: theme.palette.background.default,
                }}
            >
                <Toolbar>
                    <ChatHeader handleLeftDrawerToggle={null} showHome={true} bot={null} show={false} handleSelectChange={null} kbs={null} navi={()=>nav('/chat-with-bot')} toogleData={()=>setShow(!show)} chatId={null} shareChat={null} />
                </Toolbar>
            </AppBar>
            <Stack direction={{ xs: 'column', md: 'row' }} sx={{flex: 1, marginTop: { xs: 10, md: 9 }, overflow: "hidden"}}>
            {show && (
                <Stack sx={{
                    width: { xs: '100%', md: '25%', lg: '19%' },
                    height: "100%",
                    display: { xs: show ? 'block' : 'none', md: 'block' },
                    mb: { xs: 2, md: 0 },
                    border: 0,
                    overflow: 'hidden',
                    padding: 3
                }}>
                    <SearchSection search={searchTerm} setSearch={setSearchTerm} />
                    <List sx={{
                        overflow: 'auto',
                        flex: 1,
                        maxHeight: { xs: 'calc(100% - 56px)', md: 'calc(100% - 64px)' }, // Adjust based on SearchSection height
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        scrollbarWidth: 'none',
                        '-ms-overflow-style': 'none'
                    }}>
                        {!nloading ? 
                        <>
                            {renderChatSection("Today", filteredChats.today)}
                            {renderChatSection("Yesterday", filteredChats.yesterday)}
                            {renderChatSection("Past 7 Days", filteredChats.previous7Days)}
                            {renderChatSection("Past 30 Days", filteredChats.previous30Days)}
                            {renderChatSection("Last Year", filteredChats.lastYear)} 
                        </>:<Stack justifyContent={'center'} alignItems={'center'} sx={{height:'100%'}}>
                            <CircularProgress color='secondary' /> 
                        </Stack> 
                        }
                    </List>
                </Stack>
                )}
                <Stack
                    sx={{
                        flex: 1, 
                        backgroundColor: "#EEF2F6", 
                        borderTopLeftRadius: { xs: 0, md: 10 },
                        borderTopRightRadius: { xs: 0, md: 10 },
                        marginTop: { xs: 0, md: 2 },
                        padding: { xs: "8px", sm: "16px" },
                        overflow: "hidden"
                    }}
                >
                    <Stack
                        sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)' }, // Adjust based on your AppBar height
                        overflow: 'hidden',
                        backgroundColor: "white",
                        borderRadius: 2
                        }}
                    >
                        <List
                            sx={{
                                flex: 1,
                                overflow: 'auto',
                                padding: { xs: 1, sm: 2 },
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                scrollbarWidth: 'none',
                                '-ms-overflow-style': 'none',
                            }}
                        >
                            {collectionName ? <>
                                <div className='flex justify-between items-center mb-3'>
                                    <Typography variant='h3'>{collectionName}</Typography>
                                    <IconButton onClick={(event)=>{
                                        event.stopPropagation();
                                        const { origin } = window.location;
                                        copy(`${origin}/share/collection?id=${cid}`);
                                        toast.success("Url copied", {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                    }}>
                                        <ExternalLink size={20} />
                                    </IconButton>
                                </div>
                                <Divider /> </>: <></>
                            }
                            {nloading ? 
                            <Stack justifyContent={'center'} alignItems={'center'} sx={{height:'100%'}}>
                                <CircularProgress color='secondary' /> 
                            </Stack>:qna?.length > 0 && qna?.map((i,index)=>(<Box sx={{ overflow:'auto', borderBottom:0 , borderColor:"#c1c1c1" }}>
                                <Stack direction={'row'} sx={{height:'fit-content',p:{xs:1,sm:2}}} alignItems={'center'}>
                                    <Stack direction={"column"} sx={{width: "100%"}}>
                                        <Stack direction={"column"}>
                                    <Typography sx={{fontWeight:'500'}} variant='h2'>{i.question}</Typography>
                                </Stack>
                                </Stack>
                                </Stack>
                                <Stack direction={'row'} sx={{height:'fit-content',p:{xs:1,sm:2}}} alignItems={'flex-start'}>
                                    <Box sx={{ display:{xs:"none",sm:"block"} }}>
                                        <Subject />
                                    </Box>
                                    <Stack direction={"column"}>
                                        <Typography variant='h3' sx={{fontWeight:'700',ml:{xs:0,sm:2},mb:2}}>
                                            Answer
                                        </Typography>
                                        <Answering i={i} />
                                    </Stack>
                                </Stack>
                                {i.isLoading === false && <Stack sx={{ml:{xs:0,sm:5}}} justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Stack direction={"row"} alignItems={"center"} >
                                    <Tooltip title="copy" placement='bottom'>
                                        <IconButton onClick={()=>copyText(index)}>
                                            <ContentCopy sx={{ cursor: "pointer",p:1 ,":hover":{backgroundColor:"#f1f1f1",animationDuration:10},borderRadius: 20,fontSize:30}} />
                                        </IconButton>
                                    </Tooltip>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"}>
                                    <Tooltip title="Bad Review" placement='bottom'>
                                        <IconButton onClick={()=>{
                                            setChatIndex(index)
                                            setOpenFeedBack(true)
                                        }}>
                                            <ThumbDown sx={{ cursor: "pointer",p:1 ,":hover":{backgroundColor:"#f1f1f1",animationDuration:10},borderRadius: 20,fontSize:30}} />
                                        </IconButton>
                                    </Tooltip>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"}>
                                    <Tooltip title="Delete From Collection" placement='bottom'>
                                        <IconButton 
                                            onClick={() => handleDeleteMessage(index)}
                                            disabled={deletingIndexes.includes(index)}
                                        >
                                            {deletingIndexes.includes(index) ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <DeleteForeverOutlined sx={{ cursor: "pointer", p: 1, ":hover": { backgroundColor: "#f1f1f1", animationDuration: 10 }, borderRadius: 20, fontSize: 35 }} />
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                    </Stack>
                                </Stack>
                                </Stack>}
                                {i?.reference?.length > 0 &&<Stack direction={'row'} sx={{height:'fit-content',p:{xs:1,sm:2}}} alignItems={'flex-start'}>
                                <Box sx={{ display:{xs:"none",sm:"block"} }}>
                                    <ListIcon />
                                </Box>
                                {i.isLoading === false && <Stack direction={"column"}>
                                    <Typography variant='h3' sx={{fontWeight:'700',ml:{xs:0,sm:2},mb:2}}>
                                    Sources
                                    </Typography>
                                    <div className='grid md:grid-cols-8 sm:grid-cols-4 grid-cols-2 gap-3 mt-2'>
                                    {i?.reference?.map((name,index) => (
                                        <Chip
                                            key={index}
                                            label={name !== "\n\n" && name}
                                            sx={{ color: purple[800]}}
                                            variant = "outlined"
                                            onClick={() => openPDF(index, name, i.links[index])}
                                        />
                                    ))}  
                                    </div>
                                </Stack>}
                                </Stack>}
                                <div ref={endOfMessagesRef} />
                            </Box>))}
                            </List>
                    </Stack>
                </Stack>
                {show&&<Stack sx={{
                    width: { xs: '100%', md: '25%', lg: '19%' },
                    height: "100%",
                    display: { xs: 'none', md: 'block' },
                    mb: { xs: 2, md: 0 },
                    border: 0,
                    overflow: 'hidden',
                    padding: 3
                    }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                    <Typography variant='h4'>Collections</Typography>
                    <Tooltip title="Create Collection" placement='top'>
                        <IconButton onClick={handleOpenCreateCollection}>
                        <AddCircleOutline className='text-lg font-bold' />
                        </IconButton>
                    </Tooltip>
                    </Stack>
                    {collections.length > 0 ? (
                    <SearchSection search={collectionSearch} setSearch={setCollectionSearch} />
                    ) : (
                    <Typography variant="body1">Create New Collection</Typography>
                    )}
                    <Stack sx={{
                        width: '100%',
                        height: "100%",
                    }}>
                        <List sx={{
                            overflow: 'auto',
                            flex: 1,
                            maxHeight: { xs: 'calc(100% - 56px)', md: 'calc(100% - 64px)' }, // Adjust based on SearchSection height
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            },
                            scrollbarWidth: 'none',
                            '-ms-overflow-style': 'none'
                        }}>
                        {filteredCollections.map((item) => (
                            <MenuCard 
                                item={item}
                                index={collections.findIndex(c => c.id === item.id)}
                                nav={nav}
                                setCollections={setCollections}
                                key={item.id}
                            />
                        ))}
                        </List>
                    </Stack>
                </Stack>}
            </Stack>
            <PDFReader file={file} fileName={fileName} openDrawer={openD} close={closePDF} />
            <Dialog 
                open={openCreateCollection} 
                onClose={handleCloseCreateCollection}
                fullWidth
                maxWidth="sm"
            >
            <DialogTitle>Create New Collection</DialogTitle>
            <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                label="Name"
                type="text"
                color="secondary"
                fullWidth
                variant="outlined"
                value={newCollection.name}
                onChange={(e) => setNewCollection({...newCollection, name: e.target.value})}
            />
            <TextField
                margin="dense"
                label="Description"
                type="text"
                fullWidth
                variant="outlined"
                color="secondary"
                multiline
                rows={3}
                value={newCollection.description}
                onChange={(e) => setNewCollection({...newCollection, description: e.target.value})}
            />
            <TextField
                margin="dense"
                label="AI Prompt (optional)"
                type="text"
                fullWidth
                variant="outlined"
                color="secondary"
                multiline
                rows={3}
                value={newCollection.aiPrompt}
                onChange={(e) => setNewCollection({...newCollection, aiPrompt: e.target.value})}
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseCreateCollection} color='error' disabled={isCreating}>Cancel</Button>
            <Button 
                onClick={handleCreateCollection} 
                variant="outlined" 
                color="secondary"
                disabled={!newCollection.name || !newCollection.description || isCreating}
            >
                {isCreating ? 'Creating...' : 'Create'}
            </Button>
            </DialogActions>
            </Dialog>
            <Dialog
                open={openCollectionPopup}
                sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiDialog-paper': {
                    width: {
                    xs: '85vw',
                    sm: '85vw',
                    md: '50vw',
                    lg: '30vw',
                    xl: '30vw'
                    },
                    maxWidth: '80%',
                    height: {
                    md: '25%'
                    },
                    margin: 0
                }
                }}
                onClose={() => {
                setCollectionSelected({cid: "", index: "", selectedCollectionId: ""})
                setOpenCollectionPopup(false)
                }}
                aria-labelledby="collection-dialog-title"
                aria-describedby="collection-dialog-description"
            >
                <DialogTitle id="collection-dialog-title">
                <Typography variant='h3'>Select a Collection</Typography>
                </DialogTitle>
                <DialogContent sx={{
                mt: 4
                }}>
                {collections.length === 0 ? (
                    <Typography>No collections available. Create a new collection first.</Typography>
                ) : (
                    <FormControl
                    sx={{
                        width: "100%",
                        height: "100%"
                    }}
                    >
                    <Select
                        labelId="collection-select-label"
                        id="collection-select"
                        value={collectionSelected.selectedCollectionId}
                        color='secondary'
                        onChange={(event) => {
                        const selectedCollection = collections.find(c => c.id === event.target.value);
                        if (selectedCollection) {
                            console.log(`Selected collection: ${selectedCollection.id}`);
                            setCollectionSelected({...collectionSelected, selectedCollectionId: selectedCollection.id})
                        }
                        }}
                    >
                        {collections.map((collection) => (
                        <MenuItem key={collection.id} value={collection.id}>
                            {collection.name}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                )}
                </DialogContent>
                <DialogActions>
                <Button onClick={() => {
                    setCollectionSelected({cid: "", index: "", selectedCollectionId: ""})
                    setOpenCollectionPopup(false)
                }} color="error" disabled={isAdding}>
                    Cancel
                </Button>
                <Button onClick={async() => {
                    console.log(collectionSelected)
                    const uid = localStorage.getItem("uid")
                    const form = new FormData()
                    form.append("index", collectionSelected.index)
                    form.append("chatID", collectionSelected.cid)
                    setIsAdding(true)
                    await addMessageToCollection(collectionSelected.selectedCollectionId, uid, form)
                    setIsAdding(false)
                    setOpenCollectionPopup(false)
                }} color="secondary" variant='outlined' disabled={!collectionSelected.selectedCollectionId || isAdding}>
                    {isAdding ? 'Adding...' : 'Add'}
                </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Collections
