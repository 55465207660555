import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import HomeRoutes from './HomeRoute';
import TeamPage from './teamPage';
import NotFound from './404';
import ChatRoutes from './chat_routes';
import ChatBOT from './chatScreen';
import Share from './share';
import NotFound2 from './404(2)';
import TenantsRoute from './tenantsRoutes';
import ChatCollection from './collectionRoute';
import ShareCollection from './collectionShare';
import ApiChat from './api_chat';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([HomeRoutes, MainRoutes, AuthenticationRoutes, TeamPage, NotFound, ChatRoutes, ChatBOT, Share, NotFound2, TenantsRoute, ChatCollection, ShareCollection, ApiChat]);
}
