
import Box from '@mui/material/Box';
import { MaterialReactTable } from 'material-react-table';
import { Button, CircularProgress, Drawer, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography, Divider } from '@mui/material';
import { IconX, IconSearch } from '@tabler/icons';
import MainCard from 'ui-component/cards/MainCard';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { ChevronRight, Home} from "@mui/icons-material";

import AddIcon from '@mui/icons-material/Add';
import getUserInfo, { addNewTeam, getKbByName, getMemberRole, getMyTeams, getRoles } from 'services/app.services';
import { useAccess } from 'context/AccessContext';

export default function CustomPaginationActionsTable() {
    const { getmyTeam,activeRole,knowldegeHubsNames,getKb,activeTeam,handleGetKbNames } = useAccess()
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [teamName, setTeamName] = useState("");
    const [teamMembers, setTeamMembers] = useState([{ email: "", role: [], disabled: true }]);
    const [owner, setOwner] = useState("");
    const [selectedKb, setSelectedKb] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [teams, setTeams] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loadingTeam, setLoadingTeam] = useState(false);
    const [loading, setLoading] = useState(false);

    const nav = useNavigate();

    const get_roles = async () => {
        const role = await getRoles();
        setRoles(role.roles);
    };

    const getTeams = async () => {
        setLoadingTeam(true);
        const uid = localStorage.getItem("uid");
        const form = new FormData();
        const user = await getUserInfo(uid);
        const email = localStorage.getItem("email");
        form.append("email", user?.email);
        const organization = localStorage.getItem("organization");
        form.append("organization", organization);
        const data = await getMyTeams(form);
        await getmyTeam()
        if (data.teams.length > 0) {
            // Sort teams by created_at in descending order (latest first)
            const sortedTeams = data.teams.sort((a, b) => {
                return new Date(b.team.created_at) - new Date(a.team.created_at);
            });
            setTeams(sortedTeams);
        } else {
            setTeams([]);
        }
        setLoadingTeam(false);
    };

    useEffect(() => {
        const uid = localStorage.getItem("uid");
        getDetails(uid);
        getTeams();
        get_roles();
    }, []);

    const getDetails = async (uid) => {
        const data = await getUserInfo(uid);
        setOwner(data);
        setTeamMembers([{ email: data.email, role: ["Admin"], disabled: true }]);
    };

    const handleAddMemberInput = () => {
        setTeamMembers((prevMembers) => [...prevMembers, { email: "", role: [], disabled: false }]);
    };

    const handleMemberInputChange = (index, property, value) => {
        const newMembers = [...teamMembers];
        if (property === "role") {
            const selectedRoles = Array.isArray(value) ? value : [value];
            newMembers[index][property] = selectedRoles;
        } else {
            newMembers[index][property] = value;
        }
        setTeamMembers(newMembers);
    };

    const handleRemoveMember = (index) => {
        if(index === 0){
            return
        }
        const newMembers = [...teamMembers];
        newMembers.splice(index, 1); // Remove the member at the specified index
        setTeamMembers(newMembers);
    };

    const handleAddMember = async () => {
        const uid = localStorage.getItem("uid");
        const user = await  getUserInfo(uid)
        const email = user?.email;
        const organization = localStorage.getItem("organization");
        const form = new FormData();
        console.log("ts", new Date().toISOString())
        form.append("uid", uid);
        form.append("organization",organization)
        form.append("team_contact_email", email);
        form.append("email_addresses", teamMembers.map(member => member.email).join(","));
        form.append("knowledge_base_limit", selectedKb);
        form.append("owner", email); // Assuming the owner is the same as the email
        form.append("team_name", teamName);
        form.append("created_at", new Date().toISOString())
        form.append("membersRoles", JSON.stringify(teamMembers.map(member => ({ email: member.email, role: member.role }))));
        
        setLoading(true);
        const data = await addNewTeam(form);
        setLoading(false);
        if (data.message === "New Team has been Created") {
            toast.success(data.message);
            setTeamMembers([{ email: "", role: [], disabled: true }]);
            setTeamName("");
            setDrawerOpen(false);
            getTeams();
        } else {
            toast.error(data.message);
        }
    };

    const handleSelectionChange = (e) => {
        const selectedValues = e.target.value;
        if (selectedValues?.includes('selectAll')) {
            const allKbIds = knowldegeHubsNames.map((item) => item.kb_id);
            setSelectedKb(allKbIds);
        } else {
            const selectedIds = selectedValues.map((name) => {
                const selectedItem = knowldegeHubsNames.find(item => item.kb_name === name);
                return selectedItem ? selectedItem.kb_id : null;
            }).filter(id => id !== null);
            setSelectedKb(selectedIds);
        }
    };

    const selectedKbNames = selectedKb.map(id => {
        const item = knowldegeHubsNames.find(kb => kb.kb_id === id);
        return item ? item.kb_name : null;
    }).filter(name => name !== null);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'team.team_name',
                header: 'Team',
                Cell: ({ row }) => (
                    <span
                        className='hover:underline cursor-pointer'
                        onClick={() => nav(`/teams/${row.original.team.id}`)}
                    >
                        {row.original.team.team_name}
                    </span>
                ),
            },
            {
                accessorKey: 'kb_name',
                header: 'Knowledge Hub Access',
            },
            {
                accessorKey: 'team.owner',
                header: 'Owner',
            },
            {
                accessorKey: 'team.team_contact_email',
                header: 'Email',
            },
            {
                accessorKey: 'team.created_at',
                header: 'Created At',
                Cell: ({ row }) => {
                    const utcTime = new Date(row.original.team.created_at+"Z");
                    const localTime = utcTime.toLocaleString();
                    return localTime
                },
            },
        ],
        [nav]
    );

    return (
        <Stack>
            <MainCard>
                <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                    <div className="flex justify-center items-center">
                        <Link to='/marketplace'>
                            <Home color="secondary" />
                        </Link>
                        <ChevronRight />
                        <Link to='/teams'>
                            <Typography sx={{ fontWeight: 'bold' }}>Teams</Typography>
                        </Link>
                    </div>
                    {(activeRole.split(",").includes("Admin") || activeRole.split(",").includes("Create Teams")) && 
                        <button onClick={()=>setOpenDrawer(true)} className="flex items-center gap-2 px-4 py-2 text-sm text-purple-700 bg-opacity-80 bg-purple-100 hover:bg-opacity-100 hover:text-purple-700 rounded-full transition-colors duration-200">
                            <AddIcon className='text-base' />
                            Team
                        </button>
                    }
                </Stack>
                <Stack sx={{ mt: 1}}><Typography>Users to manage teams, manage team members, and assign specific Knowledge Hubs to enhance collaboration and resource access</Typography></Stack>
            </MainCard>
            <Stack sx={{ mt: 2, borderRadius: '8px', overflow: 'hidden'}}>
                <MaterialReactTable
                    columns={columns}
                    data={teams}
                    enableColumnActions={false}
                    enableColumnFilters={false}
                    enablePagination={true}
                    enableSorting={true}
                    enableBottomToolbar={true}
                    enableTopToolbar={true}
                    muiTableBodyRowProps={{ hover: true }}
                    enableGlobalFilter={true}
                    muiSearchTextFieldProps={{
                        placeholder: 'Search Team',
                        variant: 'outlined',
                        color: 'secondary',
                        InputProps: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconSearch />
                                </InputAdornment>
                            ),
                        },
                    }}
                    muiTablePaginationProps={{
                        labelRowsPerPage: '',
                    }}
                />
                <Drawer
                    anchor={"right"}
                    open={openDrawer}
                    onClose={()=>{
                        setOpenDrawer(false)
                        setSelectedKb([])
                    }}
                >
                    <div className='flex justify-between mb-2 items-center px-7 py-3'>
                        <Typography variant='h3' className="font-bold">Add Team</Typography>
                        <IconButton onClick={()=>{
                            setOpenDrawer(false)
                            setSelectedKb([])
                        }}>
                            <IconX />
                        </IconButton>
                    </div>
                    <Divider></Divider>
                    <Box sx={{width:{xs: "100vw", md: "50vw"},paddingLeft:4, paddingRight:4}}>
                        <Stack sx={{mt:2}}>
                            <Typography variant='h4'>Team Name</Typography>
                            <TextField placeholder='Team name Here' value={teamName} onChange={(e)=>setTeamName(e.target.value)} variant='outlined' color='secondary' sx={{mt:1}} />
                        </Stack>
                        <Stack sx={{mt:2}}>
                            <Typography variant='h4'>Owner</Typography>
                            <TextField placeholder='Team name Here' variant='outlined' value={owner.uname} disabled color='secondary' sx={{mt:1}} />
                        </Stack>
                        <Stack sx={{mt:2}}>
                            <Typography variant='h4'>Choose Knowledge Hub</Typography>
                            <FormControl variant="outlined" sx={{ mt: 2 }}>
                                <InputLabel>Knowledge Hub</InputLabel>
                                <Select
                                    multiple
                                    value={selectedKbNames}
                                    onChange={handleSelectionChange}
                                    label="Knowledge Hub"
                                    color='secondary'
                                >
                                    {knowldegeHubsNames && knowldegeHubsNames?.map((i,index)=>(<MenuItem value={i?.kb_name} key={index}>{i?.kb_name}</MenuItem>))}
                                    <MenuItem value={'selectAll'}>Select All Knowledge Hub</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        <Stack sx={{overflow:"auto", mb:5}}>
                            <Typography variant='h4' sx={{ my:2 }}>Add Members</Typography>
                            {teamMembers.map((member, index) => (
                                <Stack>
                                    <Stack key={index} direction="row" justifyContent="space-between">
                                        <OutlinedInput
                                            key={index}
                                            disabled={member.disabled}
                                            placeholder={`Member ${index + 1} email Here`}
                                            variant='outlined'
                                            color='secondary'
                                            sx={{ mt: 1 }}
                                            value={member.email}
                                            onChange={(e) => handleMemberInputChange(index, "email", e.target.value)}
                                            endAdornment={
                                                <InputAdornment>
                                                    <IconButton onClick={()=>handleRemoveMember(index)}>
                                                        <IconX />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                        <FormControl variant="outlined" sx={{ mt: 1, ml: 1, width: "150px" }}>
                                            <InputLabel>Role</InputLabel>
                                            <Select
                                                multiple
                                                value={member.role}
                                                disabled={member.disabled}
                                                onChange={(e) => handleMemberInputChange(index, "role", e.target.value)}
                                                label="Role"
                                                color='secondary'
                                            >
                                                {roles?.map((i)=>(<MenuItem value={i?.roles} key={i?.id}>{i?.roles}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                        <Button color='secondary' onClick={handleAddMemberInput}>Add Member</Button>
                                    </Stack>
                                    
                                </Stack>
                            ))}
                        </Stack>
                        <Stack>
                        <Button onClick={handleAddMember} variant='outlined' color='secondary' disabled={loading}>{loading?<CircularProgress color='secondary' />:"Add Team"}</Button>
                        </Stack>
                    </Box>
                </Drawer>
            </Stack>
        </Stack>
    );
}