import Add from '@mui/icons-material/Add';
import Home from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, Typography, Stack, Button } from '@mui/material';
import { IconHome, IconX } from '@tabler/icons';
import { useState } from 'react';

const TopMobNavBar = () => {
  const [open,setOpen] = useState(false)
  return (
    <Box
      sx={{
        bgcolor: 'white',
        height: '60px',
        p: 2,
        display: { sm: 'flex', md: 'none' },
        flexDirection: 'row',
        alignItems: "center"
      }}
    >
      <IconButton onClick={()=>setOpen(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor='left'
        open={open}
        onClose={()=>setOpen(false)}
      >
        <Box sx={{ width: "80vw" }}>
          <Stack justifyContent={"center"} sx={{ height:70,borderBottom: 1, borderColor:"#c1c1c1" }} alignItems={"center"} direction={"row"}>
            <Typography variant='h2'>asklly.ai</Typography>
          </Stack>
          <button className='flex justify-between items-center px-6 py-3 rounded bg-[#b5b5b5] hover:scale-105 transition-all ease-in-out duration-300'>
            <Add
              sx={{
                fontSize: '22px',
                fontWeight: '800',
              }}
            />
            <Typography fontSize={'14px'} fontWeight={'500'} mr={'8px'}>
              New Chat
            </Typography>
          </button>
          <Stack sx={{ padding: 4 }}>
            <Stack>
              <Home />
              <Typography variant='h2'>Home</Typography>
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </Box>
  );
};

export default TopMobNavBar;
