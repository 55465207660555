// assets
import { IconDashboard, IconDatabase, IconApps, IconBuildingStore } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconDatabase, IconBuildingStore, IconApps };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  title: 'AI Assistants',
  type: 'group',
  children: [
    {
      id: 'bots',
      title: 'Available AI Assistants',
      type: 'item',
      url: '/mybots',
      icon: icons.IconApps,
      breadcrumbs: true
    },
  ]
};

export default dashboard;
