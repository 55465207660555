import MinimalLayout from 'layout/MinimalLayout';
import AuthReset from 'views/pages/authentication/authentication3/Reset3';
import AuthLogin3 from 'views/pages/authentication/authentication3/Login3'
import AuthOTP from 'views/pages/authentication/authentication3/otp3';
import AuthRegister3 from 'views/pages/authentication/authentication3/Register3';
import AuthNewPassword from 'views/pages/authentication/auth-forms/AuthNewPassword';
import NewPassword from 'views/pages/authentication/authentication3/NewPassword3';
import AuthAccept from 'views/pages/authentication/auth-forms/AuthAccept';
import Accept from 'views/pages/authentication/authentication3/Accept3';

// login option 3 routing
// const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
// const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin3 />
    },
    {
      path: '/register',
      element: <AuthRegister3 />
    },
    {
      path: '/otp',
      element: <AuthOTP />
    },
    {
      path: '/reset',
      element: <AuthReset />
    },
    {
      path: '/reset/:id',
      element: <NewPassword />
    },
    {
      path: '/accept-invite/:id',
      element: <Accept />
    }
  ]
};

export default AuthenticationRoutes;
