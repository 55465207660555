import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Avatar, Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import { Button} from '@mui/material';
import kb_img from 'assets/images/heroimage.png';
import ai_img from 'assets/images/heroimage.png';
import cb_img from 'assets/images/heroimage.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import getUserInfo, { getMemberRole } from 'services/app.services';

import HubIcon from '@mui/icons-material/Hub';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { ChatBubbleOutline } from '@mui/icons-material';

export default function BuildKnowledgeCard() {
  const nav = useNavigate()
  const [roles,setRoles] = useState([])

  const getRoles = async() => {
    try {
      const uid = localStorage.getItem("uid")
      const usr = await getUserInfo(uid)
      const form2 = new FormData()
      form2.append("email",usr?.email)
      const role = await getMemberRole(form2)
      let acces = role.role.role.split(",")
      setRoles(acces)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    getRoles()
  },[])

  const handleKBSubmit = async() =>{
    nav('/kb')
  }
  const handleAISubmit = async() =>{
    nav('/chat-with-bot')
  }
  const handleCBSubmit = async() =>{
    nav('/mybots')
  }
  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: blue[100] }} aria-label="recipe">
                        <HubIcon stroke={1.5} size="1.3rem" />
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title="Build Knowledge"
                    // subheader="AI Knowledge Hub"
                  />
                  <CardMedia
                    component="img"
                    height="140"
                    image={kb_img}
                    className='hidden md:block'
                    alt="Create Knowledge Hub"
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      Upload a variety of document formats including PDF, docx, free text, and curated insights. 
                    </Typography>
                    {roles.some(i => i === "Admin" || i === 'Create Knowledge Base')&&<Button 
                      variant='outlined'
                      color='secondary'
                      sx={{mt:2}}
                      onClick={handleKBSubmit}
                      >
                      Go to Knowledge Hub
                    </Button>}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: blue[100] }} aria-label="recipe">
                      <ChatBubbleOutline stroke={1.5} size="1.3rem" />
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title="Explore Knowledge"
                  // subheader="Chat with your AI Knowledge Hub"
                />
                <CardMedia
                  component="img"
                  height="140"
                  image={ai_img}
                  className='hidden md:block'
                  alt="Create Knowledge Hub"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    Quickly tap into insights from your Knowledge Hub using an AI powered chat interface.
                  </Typography>
                  {roles.some(i => i === "Admin" || i === 'Only Query Knowledge Base')&&<Button 
                    variant='outlined'
                    color='secondary'
                    sx={{mt:2}}
                    onClick={handleAISubmit}
                  >
                    Let's chat with your Knowledge
                  </Button>}
                </CardContent>
                {/* <CardActions disableSpacing>
                  <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                  </IconButton>
                  <IconButton aria-label="share">
                    <ShareIcon />
                  </IconButton>
                  <ExpandMore
                    expand={expandedAI}
                    onClick={handleExpandAIClick}
                    aria-expanded={expandedAI}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expandedAI} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph>Example:</Typography>
                    <Typography paragraph>
                      Using "Upload Files"
                    </Typography>
                    <Typography paragraph>
                      Go to "AI Build Knowldege Hub menu section"
                    </Typography>
                    <Typography paragraph>
                      ...
                    </Typography>
                    <Typography>
                      ...
                    </Typography>
                  </CardContent>
                </Collapse> */}
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={6} sm={6} xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <Card>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: blue[100] }} aria-label="recipe">
                      <SmartToyIcon stroke={1.5} size="1.3rem" />
                    </Avatar>
                  }
                  action={
                    <IconButton aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title="Share Knowledge"
                  // subheader="Embed your AI Assistant anywhere"
                />
                <CardMedia
                  component="img"
                  className='hidden md:block'
                  height="140"
                  image={cb_img}
                  alt="Create Knowledge Hub"
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                  Share your knowledge with others, allowing them to interact with your AI assistant anytime.
                  </Typography>
                  {roles.includes('Admin')&&<Button 
                    variant='outlined'
                    color='secondary'
                    sx={{mt:2}}
                    onClick={handleCBSubmit}
                  >
                    Share your knowledge
                  </Button>}
                </CardContent>
                {/* <CardActions disableSpacing>
                  <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                  </IconButton>
                  <IconButton aria-label="share">
                    <ShareIcon />
                  </IconButton>
                  <ExpandMore
                    expand={expandedCB}
                    onClick={handleExpandCBClick}
                    aria-expanded={expandedCB}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expandedCB} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph>Example:</Typography>
                    <Typography paragraph>
                      Using "Upload Files"
                    </Typography>
                    <Typography paragraph>
                      Go to "AI Build Knowldege Hub menu section"
                    </Typography>
                    <Typography paragraph>
                      ...
                    </Typography>
                    <Typography>
                      ...
                    </Typography>
                  </CardContent>
                </Collapse> */}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  );
}