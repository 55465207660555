import { Button } from '@mui/material'
import NavComp from 'components/NavComp/NavComp'
import { useAccess } from 'context/AccessContext'
import Nav from 'layout/MainPage/Components/nav'
import React from 'react'

function NoOrganization() {
    const { logOut } = useAccess()
    return (
        <div className='h-screen'>
            <Nav />
            <div className='h-full flex flex-col justify-center items-center text-center font-semibold text-xl'>
                You haven't assigned any roles or not in any Organization<br />If any please contact your admin
                <Button variant='outlined' color='error' onClick={logOut}>LogOut</Button>
            </div>
        </div>
    )
}

export default NoOrganization