import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import getUserInfo, { addkbupdate, getKbByName, getMemberRole } from "services/app.services";
import { useNavigate } from "react-router-dom";
import del from 'assets/images/delete.png'
import MainCard from 'ui-component/cards/MainCard';
import { Button, CircularProgress, TextField, Typography, Select, MenuItem, IconButton } from "@mui/material";
import { Stack } from "@mui/system";
import { Close } from "@mui/icons-material";

function UploadComp({close,getKbData,fetchKbNameTop}) {
  const [isDragging, setIsDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dataa, setData] = useState(null);
  const [bot, setBot] = useState("");
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState();
  const [user, setUser] = useState();
  const [uname,setUname] = useState();
  const [kbname,setKbName] = useState([]);
  const [val,setVal] = useState("")
  const [access,setAccess] = useState(false)
  const [kb_id,setKb_Id] = useState("")
  const nav = useNavigate();
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState('');

  useEffect(() => {
    const id = localStorage.getItem("uid");
    setUid(id);
    getUserInfo(id).then((res)=>{
      setUname(res.uname)
      localStorage.setItem("email", res.email)
    })
    fetchKbName()
  }, []);

  const fetchKbName = async() => {
    const form = new FormData()
    const uid = localStorage.getItem("uid")
    const user = await getUserInfo(uid)
    form.append("email", user?.email)
    const organization = localStorage.getItem("organization")
    form.append("organization",organization)
    const data = await getKbByName(form)
    setKbName(data.kb)
    const form2 = new FormData()
    form2.append("email",user?.email)
    const role = await getMemberRole(form2)
    let acces = role.role.role.split(",")?.some(i => i === "Admin" || i === "Create Knowledge Base")
    setAccess(acces)
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFilesSelected(e.dataTransfer.files);
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const handleTagInputKeyDown = (e) => {
    if (e.key === 'Enter' && currentTag.trim()) {
      e.preventDefault();
      if (!tags.includes(currentTag.trim())) {
        setTags([...tags, currentTag.trim()]);
        setCurrentTag('');
      }
    }
  };

  const handleAddKb = async() => {
    setLoading(true)
    if(val === true && bot === ""){
      setLoading(false)
      return toast.error("Enter the knowledge hub name to continue", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if(val === ""){
      setLoading(false)
      return toast.error("Select a knowledge hub name to continue", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if(tags.length === 0){
      setLoading(false)
      return toast.error("Please add at least one tag", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    
    const body = new FormData();
    const dateObj = new Date();
    body.append("tags", tags.join(","));
    body.append("created_id", uid);
    body.append("created_by", uname);
    if(val !== true){
      body.append("kb_name", val);
    }else if(bot){
      body.append("kb_name", bot);
    }
    body.append("last_modified", dateObj.getTime().toString());
    body.append("file_type", "upload");
    body.append("kbdata", "");
    body.append("kb_id", kb_id);
    const organization = localStorage.getItem("organization");
    body.append("organization", organization);
    
    for (const file of selectedFiles) {
      // Check file size and page count restrictions
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
      const filePageCount = await getFilePageCount(file); // Function to get page count
      if (fileSizeInMB > 5 || filePageCount > 54) {
        toast.error(`File ${file.name} exceeds size or page limit. Max size: 5MB, Max pages: 50`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        return;
      }
      body.append("file", file);
    }
    
    const res = await addkbupdate(body);
    if (res) {
      setData(null);
      setBot("");
      setSelectedFiles([]);
      close(false);
      nav("/kb");
    }
    getKbData();
    fetchKbNameTop();
    close(false);
    setLoading(false);
  }

  const handleFilesSelected = async (files) => {
    if (files.length > 0) {
      // Check if the total number of selected files exceeds 3
      if (selectedFiles.length + files.length > 3) {
        return toast.error("You can select a maximum of 3 files", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setSelectedFiles([...selectedFiles, ...files]);
    }
  };

  const handleInputDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFilesSelected(e.dataTransfer.files);
  };

  const removeFile = async(fileToRemove) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
    if(updatedFiles.length === 0){
      return setData(null)
    }
  };

  const isAddButtonDisabled = () => {
    if (selectedFiles.length === 0) return true;
    if (val === "") return true;
    if (tags.length === 0) return true;
    if (val === true && bot === "") return true;
    return false;
  };

  // Function to get the page count of a PDF file
  const getFilePageCount = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const pdfData = new Uint8Array(event.target.result);
        const loadingTask = window.pdfjsLib.getDocument({ data: pdfData });
        loadingTask.promise.then((pdf) => {
          resolve(pdf.numPages);
        }).catch(reject);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <Stack sx={{ mt: -5}}>
      <div className="flex justify-between w-full items-center">
          <Typography variant="h3">Upload files to your Knowledge Hub</Typography>
          <IconButton onClick={()=>close(false)}>
              <Close />
          </IconButton>
      </div>
      <Stack justifyContent={'center'}  sx={{mt:2}} alignItems={'center'}>
        <Stack sx={{width: '100%'}}>
          <MainCard>
            <div
              className={`max-w-xl flex flex-col justify-center w-full h-40 px-4 transition bg-white border-2 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none ${
                isDragging ? "border-blue-600" : "border-gray-300"
              }`}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <label htmlFor="file_input" className="flex flex-col items-center space-x-2">
                <span className="flex flex-col items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <span className="font-medium text-gray-600">
                    {selectedFiles.length > 0
                      ? `Drop files to Attach (${selectedFiles.length} selected)`
                      : isDragging
                      ? "Drop files to Attach"
                      : "Drag files here, or "}
                    &nbsp;
                    <span className="text-blue-600 underline">browse</span>
                  </span>
                  <span className="text-xs text-gray-500">
                    Supported formats: PDF, DOC, DOCX, TXT (Max 5MB or 50 pages)
                  </span>
                </span>
                <input
                  type="file"
                  name="file_upload"
                  className="hidden"
                  id="file_input"
                  disabled={loading}
                  multiple
                  accept=".jpg,.png,.jpeg,.webp,.pdf,.docx,.txt,.xls,.xlsx,.csv"
                  onChange={(e) => handleFilesSelected(e.target.files)}
                  onDrop={handleInputDrop}
                />
              </label>
            </div>
            <div>
              {selectedFiles.length > 0 && (
                <div className="mt-5">
                  <span className="font-bold">Selected Files:</span>
                  <ul>
                    {selectedFiles.map((file, index) => (
                      <li key={index}className="flex justify-between items-center">
                        {file.name}
                        <button onClick={() => removeFile(file)}><img src={del} className="w-5" /></button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="border-2 p-7 mt-4 rounded-xl">
              <label className="label">Knowledge Hub</label>
              <div className="flex flex-col items-center">
                <Select
                  onChange={(e)=>{
                    setVal(e.target.value)
                    if(e.target.value !== true){
                      const selectedKb = kbname.find(kb => kb.kb_name === e.target.value);
                      setKb_Id(selectedKb?.kb_id)
                    }
                  }}
                  value={val}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  disabled={loading}
                >
                  {kbname&&kbname?.map((i,index)=>(<MenuItem value={i?.kb_name} key={index}>{i?.kb_name}</MenuItem>))}
                  {access&&<MenuItem value={true}>New Knowledge Hub</MenuItem>}
                </Select>
                {val === true &&<TextField
                  variant="outlined"
                  label="Enter the Knowledge Hub name"
                  color="secondary"
                  onChange={(e)=>{
                    setBot(e.target.value)
                    setKb_Id(e.target.value)
                  }}
                  fullWidth
                  disabled={loading}
                  sx={{mt:2}}
                />}
              </div>
              
              {/* Tags Section */}
              <div className="mt-6 space-y-2">
                <label className="text-sm font-medium text-gray-700">Add Tags</label>
                <div className="flex flex-wrap gap-2 mb-3">
                  {tags.map((tag, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
                    >
                      #{tag}
                      <button
                        onClick={() => removeTag(tag)}
                        className="ml-2 hover:text-blue-600"
                      >
                        <Close className="w-4 h-4" />
                      </button>
                    </span>
                  ))}
                </div>
                <div className="flex items-center">
                  <input
                    type="text"
                    value={currentTag}
                    onChange={(e) => setCurrentTag(e.target.value)}
                    onKeyDown={handleTagInputKeyDown}
                    placeholder="Type tag and press Enter"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    disabled={loading}
                  />
                </div>
              </div>

              <div className="flex mt-3 justify-center">
                {!loading? (
                  <Button
                  onClick={handleAddKb}
                  variant="outlined"
                  color="secondary"
                  sx={{mt:2}}
                  disabled={isAddButtonDisabled()}
                  >
                    Add to Knowledge Hub
                </Button>
                ) : (
                <Button 
                    disabled 
                    variant="outlined"
                    color="secondary"
                    sx={{mt:2}}
                  >
                  <CircularProgress color="secondary" />
                </Button>
                )}
              </div>
            </div>
          </MainCard>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default UploadComp;
