import * as React from 'react';
import { Typography, TextField } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useState, useEffect } from 'react';
import { getAudits } from 'services/app.services';
import { format } from 'date-fns';
import MaterialReactTable from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/system';
import { Link } from "react-router-dom";
import { ChevronRight, Home} from "@mui/icons-material";

export default function Audit() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const org = localStorage.getItem("organization")
    const uid = localStorage.getItem("uid")
    getAudits(org, uid).then((res) => setRows(res));
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = rows.filter((row) =>
    row.uname.toLowerCase().includes(search.toLowerCase()) ||
    row.title.toLowerCase().includes(search.toLowerCase()) ||
    row.message.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(()=>{
    console.log(filteredRows)
  },[filteredRows])

  const columns = [
    {
      header: 'Date',
      accessorKey: 'created_at',
      Cell: ({ cell }) => {
        const utcTime = new Date(cell.getValue()+"Z");
        const localTime = utcTime.toLocaleString();
        return localTime;
      },
    },
    {
      header: 'User',
      accessorKey: 'uname',
    },
    {
      header: 'Action',
      accessorKey: 'title',
    },
    {
      header: 'Detailed',
      accessorKey: 'message',
    },
  ];

  const handleRowClick = (row) => {
    const nav = filteredRows[row?.index]
    // window.open(nav?.nav,"_blank");
  };

  return (
    <Stack>
    <MainCard>
      <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
      <div className="flex justify-center items-center">
          <Link to='/marketplace'>
          <Home color="secondary" />
          </Link>
          <ChevronRight />
          <Typography sx={{ fontWeight: 'bold' }}>Activities</Typography>
      </div>
      </Stack>
      <Stack sx={{ mt: 1}}><Typography>User activity log records all actions for tracking and transparency</Typography></Stack>
    </MainCard>
    <Stack sx={{ mt: 2, borderRadius: '8px', overflow: 'hidden'}}>
      <MaterialReactTable
        initialState={{
          sorting: [{ id: 'created_at', desc: true }]
        }}
        data={filteredRows}
        columns={columns}
        getRowId={(row) => row.id}
        sx={{
          borderRadius: '8px', // Adjust the radius as needed
          overflow: 'hidden', // Ensure the corners are rounded
        }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleRowClick(row)
        })}
        muiTablePaginationProps={{
          labelRowsPerPage: '',
        }}
      />
    </Stack>
  </Stack>
  );
}