import React, { useState } from 'react';
import { Copy, Check } from 'lucide-react';

const ResponsiveEmbedCode = ({ apiKey }) => {
    const [copied, setCopied] = useState(false);
    
    const embedCode = `<script>
    window.chatx = {
        chatbotKey: "${apiKey}"
    }
</script>
<script 
    src="https://cdn.jsdelivr.net/npm/asklly_windowed_chat/dist/index.min.js">
</script>`;

    const formatHtml = (code) => {
        const lines = code.split('\n');
        return lines.map((line, index) => (
            <div key={index} className="line">
                <span className="select-none text-gray-400 text-xs mr-3 inline-block w-8 text-right">
                    {index + 1}
                </span>
                {line.includes('chatbotKey') ? (
                    <span>
                        <span className="text-gray-600 ml-8">chatbotKey: </span>
                        <span className="text-green-600">{`"${apiKey}"`}</span>
                    </span>
                ) : line.includes('window.chatx') ? (
                    <span>
                        <span className="text-blue-600">window</span>
                        <span className="text-gray-600">.chatx = {'{'}</span>
                    </span>
                ) : line.includes('src=') ? (
                    <span>
                        <span className="text-purple-600">&lt;script</span>
                        {' '}
                        <span className="text-blue-600">src</span>
                        <span className="text-gray-600">=</span>
                        <span className="text-green-600">"https://cdn.jsdelivr.net/npm/asklly_windowed_chat/dist/index.min.js"</span>
                        <span className="text-purple-600">&gt;</span>
                    </span>
                ) : (
                    <span className={line.trim().startsWith('<') ? 'text-purple-600' : ''}>
                        {line}
                    </span>
                )}
            </div>
        ));
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(embedCode);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <div className="space-y-4">
            <div className="bg-gray-50 rounded-lg border border-gray-200 overflow-hidden shadow-sm">
                <div className="px-4 py-3 bg-gray-100 border-b border-gray-200 flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <span className="ml-2 text-sm font-medium text-gray-600">Code</span>
                    </div>
                    <button
                        onClick={handleCopy}
                        className="flex items-center gap-2 px-3 py-1.5 text-sm rounded-md bg-white border border-gray-200 hover:bg-gray-50 transition-colors duration-150 ease-in-out shadow-sm"
                    >
                        {copied ? (
                            <>
                                <Check size={14} className="text-green-500" />
                                <span className="text-green-500">Copied!</span>
                            </>
                        ) : (
                            <>
                                <Copy size={14} className="text-gray-500" />
                                <span>Copy</span>
                            </>
                        )}
                    </button>
                </div>
                
                <div className="p-4 bg-white overflow-x-auto">
                    <pre className="text-sm font-mono leading-relaxed">
                        <code className="whitespace-pre">
                            {formatHtml(embedCode)}
                        </code>
                    </pre>
                </div>
            </div>
        </div>
    );
};

export default ResponsiveEmbedCode;