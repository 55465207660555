import { AddCircleOutline, Close, ContentCopy, Edit, Repeat, Send, Share, ThumbDown, MoreVert, Add } from '@mui/icons-material'
import { AppBar, Box, Button, Chip, CircularProgress, IconButton, InputAdornment, InputLabel, List, Modal, TextField, Toolbar, Tooltip, Typography, useTheme, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem, FormControl, Select } from '@mui/material'
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import getUserInfo, { addFeedback, addMessageToCollection, createCollection, deleteChat, deleteCollection, fetchtrainedApi, getAllChats, getChatById, getCollections, getKbByName, getMemberRole } from 'services/app.services'
import MainCard from 'ui-component/cards/MainCard'
import copy from 'copy-to-clipboard'
import ChatCard from 'components/chatCard/ChatCard';
import jwt_decode from 'jwt-decode';
import SubjectIcon from '@mui/icons-material/Subject';
import ListIcon from '@mui/icons-material/List';
import PDFReader from 'components/pdf/PDFViewer';
import { purple } from '@mui/material/colors';
import useWebSocket from 'react-use-websocket';
import Answering from './AnswerRendering';
import { Stack } from '@mui/system';
import ChatHeader from 'layout/MainLayout/Header/ChatHeader'
import SearchSection from 'layout/MainLayout/Header/SearchSection/index';
import { Copy, FileVolume, Plus, ThumbsDown, Trash, VolumeOff } from 'lucide-react'
import NavComp from 'components/NavComp/NavComp'
import MenuCard from 'components/menuCard'
import { useAccess } from 'context/AccessContext'

function ChatWithBot() {
  const theme = useTheme()
  const nav = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const inputRef = useRef();
  const { fetchtrainedBots, getKb } = useAccess()

  const [showData, setShowData] = useState([]);
  const [access, setAccess] = useState();
  const [uidd, setUid] = useState();
  const [adaccess,setadaccess] = useState(false)
  const [kbname, setKbName] = useState({kbs: [], api: []});
  const [sp,setSp] = useSearchParams();
  const [chatId,setChatId] = useState("")
  const [show,setShow] = useState(!isMobile);
  const [showC,setShowC] = useState(!isMobile);
  const endOfMessagesRef = useRef(null);
  const [allChat,setAllChat] = useState([]);
  const [cloading,setCloading] = useState(false) 
  const [nloading,setNloading] = useState(false)
  const [disabled,setDisabled] = useState(false)
  const [user,setUser] = useState();
  const [prof,setProf] = useState();
  const [openFeedback,setOpenFeedBack] = useState(false)
  const [feedback,setFeedback] = useState("")
  const [edit,setEdit] = useState(false);
  const [editQuestion,setEditQuestion] = useState("");
  const [optionalFeedback,setOptionalFeedback] = useState("")
  const [chatIndex,setChatIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredChats, setFilteredChats] = useState({
    today: [],
    yesterday: [],
    previous7Days: [],
    previous30Days: [],
    lastYear: []
  });
  const [collectionSearch,setCollectionSearch] = useState("")
  const { sendMessage, lastMessage, readyState } = useWebSocket();

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl)

  const [kbs,setKbs] = useState([])
  const [collections, setCollections] = useState([])
  const [qna,setQna] = useState([])
  const [botNames,setBotNames] = useState([])
  const [data,setData] = useState()
  const [q,setQ] = useState("")
  const [bots, setBots] = useState([])
  const [bot,setBot] = useState([]);
  const [api,setApi] = useState("")
  const [isOpen,setIsOpen] = useState(false)
  const [btnText,setBtnText] = useState("Copy To Clipboard")
  const [links,setLinks] = useState([])
  const [openD,setOpen] = useState(false)
  const [file,setFile] = useState("")
  const [fileName,setFileName] = useState([])
  const [collectionMenuAnchorEl, setCollectionMenuAnchorEl] = useState([]);
  const [openCollectionPopup, setOpenCollectionPopup] = useState(false);
  const [isCreating,setIsCreating] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [apis, setApis] = useState([])
  const [collectionSelected, setCollectionSelected] = useState({
    cid: "",
    index: "",
    selectedCollectionId: ""
  })

  useEffect(()=>{
    setShow(!isMobile)
  },[isMobile])

  const script = `<script src="https://cdn.jsdelivr.net/npm/chat_x_interface/dist/index.min.js"></script>`

  const copyy = () => {
    const copyText1 = `
      <head>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/chat_x_interface/dist/output.css">
      </head>
      <script>
        window.chatx = {
          chatbotKey: "${api}"
        }
      </script>
      <script src="https://cdn.jsdelivr.net/npm/chat_x_interface/dist/index.min.js"></script>
    `;
    copy(copyText1);
    setBtnText("Copied");
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false)
  };

  const closeModal = () =>{
    setIsOpen(false)
  }

  const handleSelectChange = (event) => {
    const value = event.target.value;
    console.log(value);
    if (!bot.includes(value) && bot.length < 3) {
      setBot(prevBot => [...prevBot, value]);
    } else if (bot.includes(value)) {
      setBot(prevBot => prevBot.filter(b => b !== value));
    }
  };

  const getBots = async() => {
    try {
      const uid = localStorage.getItem("uid");
      const bots = await fetchtrainedApi(uid);
      console.log(bots);
      setApis(bots.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setBot([])
    setBots([])
    const query = sp.get('id')
    setQna([])
    setFile("")
    setFileName("")
    setOpen(false)
    setLinks([])
    setChatId(query)
    fetchKbName()
    getBots()
    const uid = localStorage.getItem('uid')
    setNloading(true)
    getUser(uid)
  
    fetchtrainedBots(uid).then((res)=>{
      if(res){
        setBots(res.data[0])
        const newBotNames = res.data[0]?.map((i) => i.title);
        setBotNames(newBotNames);
      }else{
        setBots([])
      }
      setNloading(false)
    })
    getAllChats(uid).then((res)=>{
      setAllChat(res)
    })
    if(query){
      setCloading(true) 
      setQna([])
      getChatById(uid,query).then((res)=>{
        if(Object.keys(res).length === 0 && res.constructor === Object){
          setCloading(false)
          return nav("/chat-with-bot")
        }
        setBot(res?.kbname?.split(","))
        res.chatMessages?.map((i)=>{
          const referencesMatch = i?.answer?.match(/references\s*([\s\S]+)$/i);
          const referencesArray = [];
          if (referencesMatch && referencesMatch[1]) {
            const referencesString = referencesMatch[1].trim();
            referencesArray.push(...referencesString.split(',').map(reference => reference.trim()));
          }       
          
          const parseToMarkDown = i?.answer?.replace(/\\n/gi, "\n");
          setQna((prev)=>[...prev,{...i, answer: parseToMarkDown?.split("references;;")[1] ? parseToMarkDown?.split("references;;")[0]:parseToMarkDown?.split("references")[1]?parseToMarkDown?.split("references")[0]: parseToMarkDown, reference: i?.answer?.split("references;;")[1] ? i?.answer?.split("references;;")[1].split(';;;'):parseToMarkDown?.split("references")[1].split(';')?parseToMarkDown?.split("references")[1].split(';').map(ref => ref.replace(/<br\/>/gi, " ")):[],isLoading: false,links:i.link ? i.link : []}])
          if(i.link){
            setLinks(i.link)
          }else{
            setLinks([])
          }
        })
        setCloading(false)
      })
    }
  },[sp])

  async function getKbData() {
    const uid = localStorage.getItem("uid");
    const usr = await getUserInfo(uid);
    setUid(uid);
    const form = new FormData();
    const email = usr.email;
    if (email == null) {
      email = localStorage.getItem("email");
    }
    form.append("email", email);
    const organization = localStorage.getItem("organization");
    form.append("organization", organization);
    const resp = await getKb(form);
    const acc = await getMemberRole(form);
    let acces = acc.role.role.split(",")?.some(i => i === "Admin" || i === "Create Knowledge Base");
    let adacces = acc.role.role.split(",")?.some(i => i === "Admin");
    setadaccess(adacces)
    console.log(acces)
    setAccess(acces);
    if (resp?.kb.length > 0) {
      setData(resp?.kb);
      setShowData(resp?.kb);
    } else {
      setData([]);
    }
  }

  useEffect(()=>{
    if(bots?.length > 0){
      bots?.map((i)=>{
        if(i.title === bot){
          setApi(i.apikey)
        }
      })
    }
  },[bot])

  const toogleData = async() =>{
    setShow(!show)
  }

  const navi = () =>{
    setQna([])
    setChatId("")
    setBot([])
    setBots([])
    nav('/chat-with-bot')
  }

  const getUser = async() => {
    const id = localStorage.getItem('uid')
    const gauth = localStorage.getItem("gauthcred")
    const usr = await getUserInfo(id)
    if(usr){
      localStorage.setItem("usr",usr)
      setUser(usr)
      if(usr?.prof !== ""){
        setProf(usr?.prof)
        localStorage.setItem("image",usr?.prof)
      }else if(gauth){
        const user = jwt_decode(gauth)
        localStorage.setItem("image",user?.picture)
        setProf(user?.picture)
      }else{
        setProf("")
      }
    }
  }

  const fetchKbName = async () => {
    const form = new FormData();
    const uid = localStorage.getItem("uid");
    const user = await getUserInfo(uid);
    form.append("email", user?.email);
    const organization = localStorage.getItem("organization");
    form.append("organization", organization);
    const data = await getKbByName(form);
    setKbName(data.kb);
    const form2 = new FormData();
    form2.append("email", user?.email);
    const role = await getMemberRole(form2);
    const acces = role.role.role.split(",")?.some(i => i === "Admin" || i === "Create Knowledge Base");
    setAccess(acces)
    setKbs(data.kb)
  }

  useEffect(() => {
    getUser();
  }, []);

  const openPDF = async(index, reference, link) => {
    if (!links || links.length === 0) {
      return;
    } else {
      // Extract the file name from the reference
      const fileName = reference.trim();

      // Find the correct link by matching the file name
      const correctLink = links.find(url => url.includes(fileName));

      if (correctLink) {
        setFile(correctLink);
        setFileName(fileName);
        setOpen(true);
        console.log("Opening PDF:", correctLink); // For debugging
      } else {
        console.error("Link not found for file:", fileName);
        // Optionally, show an error message to the user
      }
    }
  }

  const [deletingCollections, setDeletingCollections] = useState([]);

  const handleDeleteCollection = async (collectionId, index) => {
    setDeletingCollections(prev => [...prev, collectionId]);
    try {
      await deleteCollection(collectionId, uid);
      setCollections(prev => prev.filter(collection => collection.id !== collectionId));
    } catch (error) {
      console.error("Error deleting collection:", error);
    } finally {
      setDeletingCollections(prev => prev.filter(id => id !== collectionId));
    }
    handleCollectionMenuClose(index);
  };

  const uid = localStorage.getItem("uid")

  const closePDF = async() => {
    setFile("")
    setFileName("")
    setOpen(false)
  }
  // Get today's date
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Get yesterday's date
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  // Get the date 7 days ago
  const previous7Days = new Date(today);
  previous7Days.setDate(previous7Days.getDate() - 7);
  previous7Days.setHours(0, 0, 0, 0);

  // Get the date 30 days ago
  const previous30Days = new Date(today);
  previous30Days.setDate(previous30Days.getDate() - 30);
  previous30Days.setHours(0, 0, 0, 0);

  // Get the date 1 year ago
  const lastYear = new Date(today);
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  lastYear.setHours(0, 0, 0, 0);

  const splitChatsByTimeframe = () => {
    const todayChats = [];
    const yesterdayChats = [];
    const previous7DaysChats = [];
    const previous30DaysChats = [];
    const lastYearChats = [];

    allChat.forEach(chat => {
      const chatDate = new Date(chat.timestamp * 1000);
      const diffTime = today - chatDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays === 0) {
        todayChats.push(chat);
      } else if (diffDays === 1) {
        yesterdayChats.push(chat);
      } else if (diffDays <= 7) {
        previous7DaysChats.push(chat);
      } else if (diffDays <= 30) {
        previous30DaysChats.push(chat);
      } else {
        lastYearChats.push(chat);
      }
    });

    return { todayChats, yesterdayChats, previous7DaysChats, previous30DaysChats, lastYearChats };
  };

  useEffect(()=>{
    scrollToBottom()
  },[qna])

  const handleSubmitQuestion = useCallback(async () => {
    try {
      const query = sp.get('id')
      const urlParam =  new URLSearchParams(window.location.search).get("id");
      const uid = localStorage.getItem("uid")
      if(bot === "Select a Bot"){
        return toast.error("Select your knowledge hub to chat", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
      setQna(prevQna => [...prevQna, { question: q, answer: 'Generating the answer...', reference:[],isLoading: true,links: []}]);
      setQ("");
      setDisabled(true);
      scrollToBottom();
      const orgn = localStorage.getItem("organization")
      const body = {
        question: q,
        orgn: orgn,
        kbs: bot.join(","),
        cid:  urlParam || "null",
        uid: uid
      }
      const socket = new WebSocket('wss://stream.asklly.ai/v2/ws/chat')
      // const socket = new WebSocket('ws://localhost:51730/v2/ws/chat')
      socket.addEventListener("open", (event) => {
        socket.send(JSON.stringify(body))
      });
      let receivedText = ""
      let streamText = ""
      let baseUrl = window.location.href.split('?')[0];
      socket.addEventListener("message", (event) => {
        receivedText += event.data.toString('utf8')
        const splitText = receivedText?.split("references;;");
        streamText = splitText[0].replace(/\\n/gi, "\n");
        const reference = receivedText?.split("references;;")[1]?.split("::id::")[0] ? receivedText?.split("references;;")[1]?.split("::id::")[0].split(";;;") : [];
        const cid = receivedText?.split("::id::")[1]?.split("##links##")[0];
        const link = receivedText?.split("##links##")[1];
        const parsed = receivedText?.split("##links##")[1]?.split("$;$")
        if(link){
          setLinks([...links,...parsed])
        }
        const { history } = window;
        if(cid){
          var newUrl = baseUrl + '?id=' + cid;
          history.pushState(null, '', newUrl);
        }
        setQna(prevChat => {
          const lastItemIndex = prevChat.length - 1;
          const updatedChat = prevChat.map((item, index) => {
          if (index === lastItemIndex) {
            return {
              ...item,
              answer: streamText,
              reference: reference,
              isLoading: false,
              links: parsed ? parsed : []
            };
          } else {
            return item;
          }
        });
        return updatedChat;
      });
    });
    receivedText = '';
    socket.addEventListener("close",()=>{
      getAllChats(uid).then((res)=>{
        setAllChat(res)
      })
    })
      setDisabled(false)
    } catch (error) {
      console.error(error)
    }
  }, [q, bot, sp, links]);

  const handleDelete = async(id) => {
    const uid = localStorage.getItem("uid")
    const del = await deleteChat(uid,id)
    sp.delete("id")
    nav('/chat-with-bot')
  }

  const shareChat = (chatId) => {
    const { origin } = window.location;
    const url = `${origin}/share?id=${chatId}`
    copy(url)
    toast.success("Coppied Url",{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }

  const copyText = (i) => {
    const answerWithoutBr = qna[i].answer.replace(/<br\/?>/g, '');
    copy(answerWithoutBr)
    toast.success("Coppied text",{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }

  const sendQuestionAndUpdateAnswer = useCallback(async (questionIndex) => {
    try {
      const uid = localStorage.getItem("uid");
      const query = sp.get('id');
      const urlParam =  new URLSearchParams(window.location.search).get("id")
      const body = {
        question: qna[questionIndex].question,
        orgn: localStorage.getItem("organization"),
        kbs: bot.join(","),
        model: "llama2",
        cid: urlParam || "null",
        uid: uid
      };
      setQna(prevQna => {
        const updatedQna = [...prevQna];
        updatedQna[questionIndex] = { ...updatedQna[questionIndex], isLoading: true, answer: ""};
        return updatedQna;
      });
      setDisabled(true);
      const socket = new WebSocket('wss://stream.asklly.ai/v2/ws/rechat')
      socket.addEventListener("open", (event) => {
        socket.send(JSON.stringify(body))
      });
      let receivedText = ""
      let streamText = ""
      let baseUrl = window.location.href.split('?')[0];
      socket.addEventListener("message", (event) => {
        receivedText += event.data.toString('utf8')
        const splitText = receivedText?.split("references;;");
        streamText = splitText[0].replace(/\\n/gi, "\n");
        const reference = receivedText?.split("references;;")[1]?.split("::id::")[0] ? receivedText?.split("references;;")[1]?.split("::id::")[0].split(";;;") : [];
        const cid = receivedText?.split("::id::")[1]?.split("##links##")[0];
        const link = receivedText?.split("##links##")[1];
        const parsed = receivedText?.split("##links##")[1]?.split("$;$")
        if(link){
          setLinks([...links,...parsed])
        }
        setQna(prevChat => {
          const lastItemIndex = prevChat.length - 1;
          const updatedChat = prevChat.map((item, index) => {
          if (index === lastItemIndex) {
            return {
              ...item,
              answer: streamText,
              reference: reference,
              isLoading: false,
              links: parsed ? parsed : []
            };
          } else {
            return item;
          }
        });
        return updatedChat;
      });
    });
      receivedText = '';
      socket.addEventListener("close",()=>{
        getAllChats(uid).then((res)=>{
          setAllChat(res)
        })
      })
      return { success: true, message: "Answer refreshed successfully" };
    } catch (error) {
      console.error(error);
      setDisabled(false);
      return { success: false, message: "Failed to refresh answer" };
    }
  }, [qna, bot, sp, links]);

  const sendUpdatedQuestion = useCallback(async (questionIndex) => {
    setEdit(false)
    try {
      const uid = localStorage.getItem("uid");
      const urlParam =  new URLSearchParams(window.location.search).get("id")
      const query = sp.get('id');
      const body = {
        question: editQuestion,
        orgn: localStorage.getItem("organization"),
        kbs: bot.join(","),
        model: "llama2",
        cid: urlParam || "null",
        uid: uid
      };
      setQna(prevQna => {
        const updatedQna = [...prevQna];
        updatedQna[questionIndex] = { ...updatedQna[questionIndex],question: editQuestion, isLoading: true, answer: ""};
        return updatedQna;
      });
      setDisabled(true);
      const socket = new WebSocket('wss://stream.asklly.ai/v2/ws/rechat')
      socket.addEventListener("open", (event) => {
        socket.send(JSON.stringify(body))
      });
      let receivedText = ""
      let streamText = ""
      let baseUrl = window.location.href.split('?')[0];
      socket.addEventListener("message", (event) => {
        receivedText += event.data.toString('utf8')
        const splitText = receivedText?.split("references;;");
        streamText = splitText[0].replace(/\\n/gi, "\n");
        const reference = receivedText?.split("references;;")[1]?.split("::id::")[0] ? receivedText?.split("references;;")[1]?.split("::id::")[0].split(";;;") : [];
        const cid = receivedText?.split("::id::")[1]?.split("##links##")[0];
        const link = receivedText?.split("##links##")[1];
        const parsed = receivedText?.split("##links##")[1]?.split("$;$")
        if(link){
          setLinks([...links,...parsed])
        }
        setQna(prevChat => {
          const lastItemIndex = prevChat.length - 1;
          const updatedChat = prevChat.map((item, index) => {
          if (index === lastItemIndex) {
            return {
              ...item,
              answer: streamText,
              reference: reference,
              isLoading: false,
              links: parsed ? parsed : []
            };
          } else {
            return item;
          }
        });
        return updatedChat;
      });
    });
      receivedText = '';
      socket.addEventListener("close",()=>{
        getAllChats(uid).then((res)=>{
          setAllChat(res)
        })
      })
      setDisabled(false);
      return { success: true, message: "Answer refreshed successfully" };
    } catch (error) {
      setDisabled(false);
      return { success: false, message: "Failed to refresh answer" };
    }
  }, [editQuestion, bot, sp, links]);
  
  const HandleEditQuestion = () =>{
    setEditQuestion(qna[qna.length - 1].question);
    setEdit(true)
    inputRef.current.focus()
  }

  const handleCancel = () => {
    setEdit(false)
    setEditQuestion(" ");
  }

  const handleFeedback = async() => {
    const uid = localStorage.getItem("uid")
    if(!feedback){
      return toast.error("Select an feedback", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (feedback === "Others" && optionalFeedback === ""){
      return toast.error("Enter an feedback", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    const body = {
      useruid: uid,
      review: "negative",
      feedback: feedback + " " +optionalFeedback,
      chatid: chatId,
      chatindex: chatIndex
    }
    await addFeedback(body)
    setOpenFeedBack(false)
    setFeedback("")
    setOptionalFeedback("")
  }
  
  const { todayChats, yesterdayChats, previous7DaysChats, previous30DaysChats, lastYearChats } = splitChatsByTimeframe();
  const sortedTodayChats = [...todayChats]
  const sortedYesterdayChats = [...yesterdayChats]
  const sortedPrevious7DaysChats = [...previous7DaysChats]
  const sortedPrevious30DaysChats = [...previous30DaysChats]
  const sortedLastYearChats = [...lastYearChats]

  const passedDays = (ts) => {
    const timestamp = ts * 1000;
    const now = Date.now();
    const diffInMs = now - timestamp;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays
  }

  const sortChats = (chats) => {
    return chats.sort((a, b) => b.timestamp - a.timestamp);
  };

  useEffect(() => {
    const filterChats = (chats) => {
      return sortChats(chats.filter(chat => 
        chat.chatName.toLowerCase().includes(searchTerm.toLowerCase())
      ));
    };
  
    setFilteredChats({
      today: filterChats(sortedTodayChats),
      yesterday: filterChats(sortedYesterdayChats),
      previous7Days: filterChats(sortedPrevious7DaysChats),
      previous30Days: filterChats(sortedPrevious30DaysChats),
      lastYear: filterChats(sortedLastYearChats)
    });
  }, [searchTerm, sortedTodayChats, sortedYesterdayChats, sortedPrevious7DaysChats, sortedPrevious30DaysChats, sortedLastYearChats]);

  const renderChatSection = (title, chats) => {
    if (chats.length === 0) return null;
    return (
      <Stack>
        <Typography sx={{fontWeight: 400}}>{title}</Typography>
        {chats.map((i) => (
          <ChatCard i={i} handleDelete={handleDelete} shareChat={()=>shareChat(i.chatID)} setShow={setShow} isMobile={isMobile} />
        ))}
      </Stack>
    );
  };

  const [openCreateCollection, setOpenCreateCollection] = useState(false);
  const [newCollection, setNewCollection] = useState({ name: '', description: '', aiPrompt: '' });

  const handleOpenCreateCollection = () => {
    setOpenCreateCollection(true);
  };

  const handleCloseCreateCollection = () => {
    setOpenCreateCollection(false);
    setNewCollection({ name: '', description: '', aiPrompt: '' });
  };

  const handleCreateCollection = async() => {
    setIsCreating(true)
    const uid = localStorage.getItem("uid")
    const form = new FormData()
    form.append("collection_name", newCollection.name)
    form.append("collection_description", newCollection.description)
    form.append("ai_prompt", newCollection.aiPrompt || "null")
    form.append("uid", uid)
    await createCollection(form)
    await handleGetCollections()
    handleCloseCreateCollection();
    setIsCreating(false)
  };

  const handleGetCollections = async() => {
    try {
      const uid = localStorage.getItem("uid")
      console.log(uid)
      const all_collections = await getCollections(uid)
      console.log(all_collections.collections)
      setCollections(all_collections.collections)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(()=>{
    setBots([])
    setBot([])
    handleGetCollections()
  },[])

  const handleCollectionMenuClick = (event, index) => {
    event.stopPropagation();
    setCollectionMenuAnchorEl(prevState => {
      const newState = [...prevState];
      newState[index] = event.currentTarget;
      return newState;
    });
  };

  const handleCollectionMenuClose = (index) => {
    setCollectionMenuAnchorEl(prevState => {
      const newState = [...prevState];
      newState[index] = null;
      return newState;
    });
  };

  const [speakingIndex, setSpeakingIndex] = useState(null);

  const speak = async(idx) => {
    if (speakingIndex !== null) {
      window.speechSynthesis.cancel(); // Stop any ongoing speech
    }
    
    const answerWithoutBr = qna[idx].answer.replace(/<br\/?>/g, '');
    const utter = new SpeechSynthesisUtterance(answerWithoutBr);
    
    setSpeakingIndex(idx);
    
    utter.onend = () => {
      setSpeakingIndex(null);
    };
    
    window.speechSynthesis.speak(utter);
  }

  const stopSpeak = () => {
    window.speechSynthesis.cancel();
    setSpeakingIndex(null);
  }

  const filteredCollections = useMemo(() => {
    return collections.filter((item) =>
      item.name.toLowerCase().includes(collectionSearch.toLowerCase().trim())
    );
  }, [collections, collectionSearch]);

  return (
    <Box sx={{
      backgroundColor: '#fff',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
        }}
      >
        <Toolbar>
          <ChatHeader sp={sp} api={apis} handleLeftDrawerToggle={null} showHome={true} bot={bot} handleSelectChange={handleSelectChange} kbs={kbs} navi={navi} toogleData={toogleData} chatId={chatId} shareChat={shareChat} />
        </Toolbar>
      </AppBar>
      <Stack direction={{ xs: 'column', md: 'row' }} sx={{flex: 1, marginTop: { xs: 10, md: 9 }, overflow: "hidden"}}>
        {show && (
        <Stack sx={{
          width: { xs: '100%', md: '25%', lg: '19%' },
          height: "100%",
          display: { xs: show ? 'block' : 'none', md: 'block' },
          mb: { xs: 2, md: 0 },
          border: 0,
          overflow: 'hidden',
          padding: 3
        }}>
          <SearchSection search={searchTerm} setSearch={setSearchTerm} />
          <List sx={{
            overflow: 'auto',
            flex: 1,
            maxHeight: { xs: 'calc(100% - 56px)', md: 'calc(100% - 64px)' }, // Adjust based on SearchSection height
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none'
          }}>
            {!nloading ? 
              <>
                {renderChatSection("Today", filteredChats.today)}
                {renderChatSection("Yesterday", filteredChats.yesterday)}
                {renderChatSection("Past 7 Days", filteredChats.previous7Days)}
                {renderChatSection("Past 30 Days", filteredChats.previous30Days)}
                {renderChatSection("Last Year", filteredChats.lastYear)} 
              </>:<Stack justifyContent={'center'} alignItems={'center'} sx={{height:'100%'}}>
                <CircularProgress color='secondary' /> 
              </Stack> 
            }
          </List>
        </Stack>
        )}
        <Stack
          sx={{
            flex: 1, 
            backgroundColor: "#EEF2F6", 
            borderTopLeftRadius: { xs: 0, md: 10 },
            borderTopRightRadius: { xs: 0, md: 10 },
            marginTop: { xs: 0, md: 2 },
            padding: { xs: "8px", sm: "16px" },
            overflow: "hidden",
            border: 0
          }}
        >
          {cloading ? 
              <Stack justifyContent={'center'} alignItems={'center'} sx={{height:'100%'}}>
                <CircularProgress color='secondary' /> 
              </Stack>:qna.length > 0 ?<Stack
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              height: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)' }, // Adjust based on your AppBar height
              overflow: 'hidden',
              backgroundColor: "white",
              borderRadius: 2
            }}
          >
            <List
              sx={{
                flex: 1,
                overflow: 'auto',
                padding: { xs: 1, sm: 2 },
                  '&::-webkit-scrollbar': {
                  display: 'none',
                },
                scrollbarWidth: 'none',
                '-ms-overflow-style': 'none',
              }}
            >
            {qna?.length > 0 && qna?.map((i,index)=>(<Box sx={{ overflow:'auto', borderBottom:0 , borderColor:"#c1c1c1" }}>
                <Stack direction={'row'} sx={{height:'fit-content',p:{xs:1,sm:2}}} alignItems={'center'}>
                  <Stack direction={"column"} sx={{width: "100%"}}>
                    {edit === true && index === qna.length - 1?
                    <Stack direction={"column"}>
                      <TextField
                        onChange={(e)=>setEditQuestion(e.target.value)}
                        value={editQuestion}
                        multiline
                        fullWidth
                        variant='outlined'
                        color='secondary'
                        ref={inputRef}
                      />
                      <Stack direction={"row"} sx={{mt: 2}}>
                        <Button variant='outlined' color='secondary' onClick={()=>sendUpdatedQuestion(index)}>Submit</Button>
                        <Button sx={{ml: 2}} onClick={handleCancel} variant='text' color='error'>Cancel</Button>
                      </Stack>
                    </Stack>
                    :<Stack direction={"column"}>
                      <Typography sx={{fontWeight:'500'}} variant='h2'>{i.question}</Typography>
                      {index === qna.length - 1 && 
                      <div className='w-20'>
                        <Tooltip title="edit question" placement='bottom'>
                          <IconButton disabled={i.isLoading} onClick={HandleEditQuestion}>
                            <Edit sx={{width:20}} />
                          </IconButton>
                        </Tooltip>
                      </div>}
                    </Stack>}
                  </Stack>
                </Stack>
                <Stack direction={'row'} sx={{height:'fit-content',p:{xs:1,sm:2}}} alignItems={'flex-start'}>
                  <Box sx={{ display:{xs:"none",sm:"block"} }}>
                    <SubjectIcon />
                  </Box>
                  <Stack direction={"column"}>
                    <Typography variant='h3' sx={{fontWeight:'700',ml:{xs:0,sm:2},mb:2}}>
                      Answer
                    </Typography>
                    <Answering i={i} />
                  </Stack>
                </Stack>
                {i.isLoading === false && <Stack sx={{ml:{xs:0,sm:5}}} justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    {index === qna.length - 1 && 
                      <Stack direction={"row"} alignItems={"center"}>
                        <Tooltip title="Repeat Question" placement='bottom'>
                          <IconButton onClick={()=>sendQuestionAndUpdateAnswer(index)}>
                            <Repeat sx={{width:20}} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    }
                    <Stack direction={"row"} alignItems={"center"} >
                      <Tooltip title={speakingIndex === index ? "Stop Speaking" : "Read Aloud"} placement='bottom'>
                        <IconButton onClick={() => speakingIndex === index ? stopSpeak() : speak(index)}>
                          {speakingIndex === index ? <VolumeOff size={18} /> : <FileVolume size={18} />}
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} >
                      <Tooltip title="copy" placement='bottom'>
                        <IconButton onClick={()=>copyText(index)}>
                          <Copy  size={18} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Tooltip title="Bad Review" placement='bottom'>
                        <IconButton onClick={()=>{
                          setChatIndex(index)
                          setOpenFeedBack(true)
                        }}>
                          <ThumbsDown  size={18} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Tooltip title="Add To Collection" placement='bottom'>
                        <IconButton onClick={()=>{
                          const urlParam =  new URLSearchParams(window.location.search).get("id")
                          setCollectionSelected({...collectionSelected,cid: urlParam, index: index})
                          setOpenCollectionPopup(true)
                        }}>
                          <Plus size={18} />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Stack>}
                {i?.reference?.length > 0 &&<Stack direction={'row'} sx={{height:'fit-content',p:{xs:1,sm:2}}} alignItems={'flex-start'}>
                  <Box sx={{ display:{xs:"none",sm:"block"} }}>
                    <ListIcon />
                  </Box>
                  {i.isLoading === false && <Stack direction={"column"}>
                    <Typography variant='h3' sx={{fontWeight:'700',ml:{xs:0,sm:2},mb:2}}>
                      Sources
                    </Typography>
                    <div className='grid md:grid-cols-8 sm:grid-cols-4 grid-cols-2 gap-3 mt-2'>
                      {i?.reference?.map((name,index) => (
                        <Chip
                          key={index}
                          label={name !== "\n\n" && name}
                          sx={{ color: purple[800]}}
                          variant = "outlined"
                          onClick={() => openPDF(index, name, i.links[index])}
                        />
                      ))}  
                    </div>
                  </Stack>}
                </Stack>}
                <div ref={endOfMessagesRef} />
              </Box>))}
            </List>
            
            <Box sx={{ p: { xs: 1, sm: 2 } }}>
              <TextField
                fullWidth
                color='secondary'
                label="Ask a follow-up question"
                value={q}
                onChange={(e) => setQ(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmitQuestion()
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton disabled={disabled} id="button" onClick={handleSubmitQuestion}>
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Typography sx={{ mt: 2, color: "#b1b1b1", textAlign: "center" }}>
                asklly.ai can make mistakes.
              </Typography>
            </Box>
          </Stack>:
          <Stack
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              height: { xs: 'calc(100vh - 56px)', sm: 'calc(100vh - 64px)' }, // Adjust based on your AppBar height
              overflow: 'hidden',
              backgroundColor: "white",
              borderRadius: 2,
              padding: 5
            }}
          >
            <Typography variant='h2' sx={{
              mb:2,
              fontWeight: "900"
            }}>What can i help you with?</Typography>
            <div className="w-full flex items-center border border-gray-300 rounded-full px-4 py-2 shadow-sm bg-white">
              <NavComp fetchKbName={fetchKbName} getKbData={getKbData} clip />
              <input
                type="text"
                placeholder="Ask a question"
                className="flex-grow outline-none text-sm bg-white text-black placeholder-gray-400"
                value={q}
                onChange={(e) => {
                  setQ(e.target.value)
                }}
              />
              <button 
                className="p-2 rounded-full bg-black text-white hover:bg-gray-800 disabled:bg-gray-400 disabled:cursor-not-allowed"
                disabled={!q.length}
                onClick={handleSubmitQuestion}
              >
                <svg className="w-5 h-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M10.293 2.293a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 01-2 0V5.414L4.707 9.707A1 1 0 013.293 8.293l6-6z" clipRule="evenodd" />
                </svg>
              </button>
          </div>
  
          </Stack>} 
        </Stack>
          {show&&<Stack sx={{
            width: { xs: '100%', md: '25%', lg: '19%' },
            height: "100%",
            display: { xs: 'none', md: 'block' },
            mb: { xs: 2, md: 0 },
            border: 0,
            overflow: 'hidden',
            padding: 3
          }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant='h4'>Collections</Typography>
            <Tooltip title="Create Collection" placement='top'>
              <IconButton onClick={handleOpenCreateCollection}>
                <AddCircleOutline className='text-lg font-bold' />
              </IconButton>
            </Tooltip>
          </Stack>
          {collections.length > 0 ? (
            <SearchSection search={collectionSearch} setSearch={setCollectionSearch} />
          ) : (
            <Typography variant="body1">Create New Collection</Typography>
          )}
          <Stack sx={{
            width: '100%',
            height: "100%",
            display: { xs: show ? 'block' : 'none', md: 'block' },
            mb: { xs: 2, md: 0 },
            border: 0,
            overflow: 'hidden',
          }}>
            <List sx={{
              overflow: 'auto',
              flex: 1,
              maxHeight: { xs: 'calc(100% - 56px)', md: 'calc(100% - 64px)' }, // Adjust based on SearchSection height
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              scrollbarWidth: 'none',
              '-ms-overflow-style': 'none'
            }}>
              {filteredCollections.map((item) => (
                <MenuCard 
                  item={item}
                  index={collections.findIndex(c => c.id === item.id)}
                  nav={nav}
                  setCollections={setCollections}
                  key={item.id}
                />
              ))}
            </List>
          </Stack>
        </Stack>} 
      </Stack>
      <Modal
        open={openFeedback}
        onClose={()=>setOpenFeedBack(false)}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '60%', md: '50%' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: { xs: 2, sm: 4 },
          borderRadius: 2,
        }}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems="center">
            <Typography variant='h2'>Feedback</Typography>
            <IconButton onClick={()=>setOpenFeedBack(false)}>
              <Close />
            </IconButton>
          </Stack>
          <Typography variant='body1'>Your feedback will help us improve the model</Typography>
          <Box sx={{ my: 3, display: 'grid', gridTemplateColumns: { xs: '1fr 1fr', sm: '1fr 1fr 1fr' }, gap: 2 }}>
            {['Answer Inaccurate', 'Didnt fully followed the prompt', 'No Answer came', 'Being lazy', 'Others'].map((label) => (
              <Chip
                key={label}
                label={label}
                sx={{ color: purple[800] }}
                variant={feedback === label ? "filled" : "outlined"}
                onClick={() => setFeedback(label)}
              />
            ))}
          </Box>
          <TextField
            fullWidth
            color='secondary'
            placeholder='(optional) Enter your Feedback about the answer'
            onChange={(e)=>setOptionalFeedback(e.target.value)}
            multiline
            rows={3}
          />
          <Button variant='outlined' color='secondary' sx={{mt:2}} onClick={handleFeedback}>Submit</Button>
        </Box>
      </Modal>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: '70%' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: { xs: 2, sm: 4 },
          borderRadius: 2,
          maxHeight: '90vh',
          overflow: 'auto'
        }}>
          <Typography variant="h6" component="h2">Script Code</Typography>
          <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              <code>
                {"<head>\n"}
                {'   <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/chat_x_interface/dist/output.css">\n'}
                {'</head>\n'}
                {'<script>\n'}
                {'   window.chatx = {\n'}
                {`        chatbotKey: "${api}"\n`}
                {'   }\n'}
                {'</script>\n'}
                {script}
              </code>
            </pre>
          </Box>
          <Button variant="contained" color="primary" onClick={copyy} sx={{ mt: 2, mr: 1 }}>{btnText}</Button>
          <Button variant="contained" color="error" onClick={closeModal} sx={{ mt: 2 }}>Close</Button>
        </Box>
      </Modal>
      <PDFReader file={file} fileName={fileName} openDrawer={openD} close={closePDF} />

      {/* Create Collection Dialog */}
      <Dialog 
        open={openCreateCollection} 
        onClose={handleCloseCreateCollection}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Create New Collection</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            color="secondary"
            fullWidth
            variant="outlined"
            value={newCollection.name}
            onChange={(e) => setNewCollection({...newCollection, name: e.target.value})}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            color="secondary"
            multiline
            rows={3}
            value={newCollection.description}
            onChange={(e) => setNewCollection({...newCollection, description: e.target.value})}
          />
          <TextField
            margin="dense"
            label="AI Prompt (optional)"
            type="text"
            fullWidth
            variant="outlined"
            color="secondary"
            multiline
            rows={3}
            value={newCollection.aiPrompt}
            onChange={(e) => setNewCollection({...newCollection, aiPrompt: e.target.value})}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateCollection} color='error' disabled={isCreating}>Cancel</Button>
          <Button 
            onClick={handleCreateCollection} 
            variant="outlined" 
            color="secondary"
            disabled={!newCollection.name || !newCollection.description || isCreating}
          >
            {isCreating ? 'Creating...' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCollectionPopup}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiDialog-paper': {
            width: {
              xs: '85vw',
              sm: '85vw',
              md: '50vw',
              lg: '30vw',
              xl: '30vw'
            },
            maxWidth: '80%',
            
            margin: 0
          }
        }}
        onClose={() => {
          setCollectionSelected({cid: "", index: "", selectedCollectionId: ""})
          setOpenCollectionPopup(false)
        }}
        aria-labelledby="collection-dialog-title"
        aria-describedby="collection-dialog-description"
      >
        <DialogTitle>
          <Typography variant='h3'>Select a Collection</Typography>
        </DialogTitle>
        <DialogContent sx={{
          mt: 1
        }}>
          {collections.length === 0 ? (
            <Typography>No collections available. Create a new collection first.</Typography>
          ) : (
            <FormControl
              sx={{
                width: "100%",
                height: "100%"
              }}
            >
              <Select
                labelId="collection-select-label"
                id="collection-select"
                value={collectionSelected.selectedCollectionId}
                color='secondary'
                onChange={(event) => {
                  const selectedCollection = collections.find(c => c.id === event.target.value);
                  if (selectedCollection) {
                    console.log(`Selected collection: ${selectedCollection.id}`);
                    setCollectionSelected({...collectionSelected, selectedCollectionId: selectedCollection.id})
                  }
                }}
              >
                {collections.map((collection) => (
                  <MenuItem key={collection.id} value={collection.id}>
                    {collection.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setCollectionSelected({cid: "", index: "", selectedCollectionId: ""})
            setOpenCollectionPopup(false)
          }} color="error" disabled={isAdding}>
            Cancel
          </Button>
          <Button onClick={async() => {
            console.log(collectionSelected)
            const uid = localStorage.getItem("uid")
            const form = new FormData()
            form.append("index", collectionSelected.index)
            form.append("chatID", collectionSelected.cid)
            setIsAdding(true)
            await addMessageToCollection(collectionSelected.selectedCollectionId, uid, form)
            setIsAdding(false)
            setOpenCollectionPopup(false)
          }} color="secondary" variant='outlined' disabled={!collectionSelected.selectedCollectionId || isAdding}>
            {isAdding ? 'Adding...' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ChatWithBot