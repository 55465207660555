import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyD3hl_TDJVGrBH5pWLk1QsRiZdpSfUXJBo",
  authDomain: "fileuploader-d9bac.firebaseapp.com",
  projectId: "fileuploader-d9bac",
  storageBucket: "fileuploader-d9bac.appspot.com",
  messagingSenderId: "425990424883",
  appId: "1:425990424883:web:7757d8f0f487c7112e9646",
  measurementId: "G-WFY3PTDQ83"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)