import { ContentCopy, Email, Instagram, Print, Twitter, WhatsApp } from '@mui/icons-material'
import { AppBar, Box, Button, CircularProgress, IconButton, List, Modal, Skeleton, TextField, Toolbar, Tooltip, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useRef, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { sharedChat } from 'services/app.services'
import chat from "assets/images/chatnotfound1.png"
import MainCard from 'ui-component/cards/MainCard'
import copy from 'copy-to-clipboard'
import SubjectIcon from '@mui/icons-material/Subject';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import LogoSection from 'layout/MainLayout/LogoSection'

function ShareChat() {
  const theme = useTheme()
  const [sp, setSp] = useSearchParams();
  const [chatId, setChatId] = useState("")
  const endOfMessagesRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [qna, setQna] = useState([])
  const [bot, setBot] = useState('Select a Bot');
  const [links, setLinks] = useState([])

  useEffect(() => {
    const query = sp.get('id')
    setQna([])
    setChatId(query)
    if (query) {
      setLoading(true)
      setQna([])
      sharedChat(query).then((res) => {
        setLoading(false)
        if (!res || !res.chatMessages) {
          setQna([]);
          return;
        }
        setBot(res.kbname)
        res.chatMessages?.forEach((i) => {
          const referencesMatch = i?.answer?.match(/references\s*([\s\S]+)$/i);
          const referencesArray = [];
          if (referencesMatch && referencesMatch[1]) {
            const referencesString = referencesMatch[1].trim();
            referencesArray.push(...referencesString.split(',').map(reference => reference.trim()));
          }
          const parseToMarkDown = i?.answer?.replace(/\\n/gi, "\n").replace(/\n/gi, "<br/>");
          setQna((prev) => [...prev, { ...i, answer: referencesArray ? parseToMarkDown?.split("references")[0] : parseToMarkDown, reference: i?.answer?.split("references")[1] ? i?.answer?.split("references")[1].split(';') : [], isLoading: false }])
          if (i.link) {
            setLinks(i.link)
          } else {
            setLinks([])
          }
        })
      }).catch(() => {
        setLoading(false);
        setQna([]);
      });
    }
  }, [sp])

  const copyText = (i) => {
    const answerWithoutBr = qna[i].answer.replace(/<br\/?>/g, '');
    copy(answerWithoutBr)
    toast.success("Copied text", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }

  return (
    <Box sx={{
      height: "90vh",
      backgroundColor: "#fff"
    }}>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
        }}
      >
        <Toolbar>
          <Box
            sx={{
              width: '100vw',
              display: 'flex',
              justifyContent: "space-between"
            }}
          >
            <Box component="span" sx={{ display: { xs: 'none', md: 'block' } }}>
              <LogoSection />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
              <Tooltip title="Share via Email" arrow>
                <IconButton onClick={() => window.open(`mailto:?subject=Check out this chat&body=${encodeURIComponent(qna.map(item => `${item.question}: ${item.answer}`).join('\n'))}`)} aria-label="Email">
                  <Email />
                </IconButton>
              </Tooltip>
              <Tooltip title="Print this chat" arrow>
                <IconButton onClick={() => window.print()} aria-label="Print">
                  <Print />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share on WhatsApp" arrow>
                <IconButton onClick={() => window.open(`whatsapp://send?text=${encodeURIComponent(qna.map(item => `${item.question}: ${item.answer}`).join('\n'))}`)} aria-label="WhatsApp">
                  <WhatsApp />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share on Twitter" arrow>
                <IconButton onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(qna.map(item => `${item.question}: ${item.answer}`).join('\n'))}`)} aria-label="X">
                  <Twitter />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share on Instagram" arrow>
                <IconButton onClick={() => alert("Instagram sharing is not supported directly. Please copy the text to share.")} aria-label="Instagram">
                  <Instagram />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {loading?
        <Stack sx={{
          height: "110vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}>
          <CircularProgress />
          <h1>Loading ...</h1>
        </Stack>
      :<Stack direction={'row'} sx={{ mt: 9 }}>
        {qna.length > 0 ? (
          <MainCard sx={{ width: '100%', minHeight: '80vh', px: 2 }}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant='h4'>Knowledge Hub: {bot}</Typography>
              </Stack>
            </Stack>
            <List>
              {qna?.map((i, index) => (
                <Box sx={{ borderBottom: 1, borderColor: "#c1c1c1" }} key={index}>
                  <Stack direction={'row'} sx={{ height: 'fit-content', p: { sm: 1, md: 2 } }} alignItems={'center'}>
                    <Stack direction={"column"} sx={{ width: "100%" }}>
                      <Stack direction={"column"}>
                        <Typography sx={{ fontWeight: '500' }} variant='h2'>{i.question}</Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack direction={'row'} sx={{ height: 'fit-content', p: { sm: 1, md: 2 } }} alignItems={'flex-start'}>
                    <Box sx={{ display: { sm: "none", md: "block" } }}>
                      <SubjectIcon />
                    </Box>
                    <Stack direction={"column"}>
                      <Typography variant='h3' sx={{ fontWeight: '700', ml: 2, mb: 2 }}>
                        Answer
                      </Typography>
                      <Markdown children={i.answer} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} className="foo" />
                    </Stack>
                  </Stack>
                  {i.isLoading === false && (
                    <Stack sx={{ ml: { sm: 1, md: 5 } }} justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
                      <Stack direction={"row"} alignItems={"center"}>
                        <Tooltip title="copy" placement='bottom'>
                          <IconButton onClick={() => copyText(index)}>
                            <ContentCopy sx={{ cursor: "pointer", p: 1, ":hover": { backgroundColor: "#f1f1f1" }, borderRadius: 20, fontSize: 30 }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  )}
                  <div ref={endOfMessagesRef} />
                </Box>
              ))}
            </List>
            <Typography sx={{ mt: 2, color: "#b1b1b1" }} style={{ justifyContent: "center", alignItems: "center" }}>
              asklly.ai can make mistakes. Consider checking important information.
            </Typography>
          </MainCard>
        ) : (
          <Stack direction={"column"} height={"75vh"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
            <img alt='no chat' src={chat} className='w-72' />
            <Typography variant='h3' sx={{ textAlign: "center" }}>
              You came wrong way!<br />Chat Not Found<br />Check the url and try again
            </Typography>
          </Stack>
        )}
      </Stack>}
    </Box>
  )
}

export default ShareChat