import { Box } from '@mui/material';

const SideNavBarLogin = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        borderRadius: 1,
      }}
    >
      Login
    </Box>
  );
};

export default SideNavBarLogin;
