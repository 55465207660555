// material-ui
import { List, Select, Typography, MenuItem } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { useState } from 'react';
import { useEffect } from 'react';
import getUserInfo, { getAskllyAdmin, getMemberRole } from 'services/app.services';
import pages from 'menu-items/pages';
import team_page from 'menu-items/team_page';
import chat_page from 'menu-items/chat_pages';
import utilities from 'menu-items/utilities';
import bot_pages from 'menu-items/bot_pages';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { IconBuilding, IconGraph } from '@tabler/icons';
import { useAccess } from 'context/AccessContext';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const [menu, setMenu] = useState([]);
  const nav = useNavigate();
  const { disabled, myTeams, activeTeam, handleTeamChange, activeRole } = useAccess();

  useEffect(() => {
    getUserRole();
  }, [activeRole]);

  async function getUserRole() {
    const uid = localStorage.getItem("uid");
    const user = await getUserInfo(uid);
    const form = new FormData();
    form.append("email", user?.email);
    const usr = await getUserInfo(uid);
    const org = localStorage.getItem("organization");
    let orgn = false; // Initialize orgn variable
    const { data } = await axios.post("https://fast.asklly.ai/get/org", {
      email: usr?.email
    });
    const body = {
      email: usr.email,
      organization: data,
      team_id: activeTeam
    };
    
    orgn = await getAskllyAdmin(body);
    const finalRole = activeRole ? activeRole.split(",") : [];
    console.log(activeRole ? activeRole.split(",").includes("Admin") : []);
    if (activeRole.split(",").includes("Admin") && orgn) {
      if (!team_page.children.some(child => child.id === 'Tenants')) {
        team_page.children.unshift({
          id: 'Tenants',
          title: 'Tenants',
          type: 'item',
          url: '/tenants/view',
          icon: IconBuilding
        });
      }
      if (!team_page.children.some(child => child.id === 'Activities')) {
        team_page.children.push({
          id: 'Activities',
          title: 'Activities',
          type: 'item',
          url: '/audit',
          icon: IconGraph
        });
      }
    } else {
      team_page.children = team_page.children.filter(child => child.id !== 'Tenants' && child.id !== 'Activities');
    }
    let menuItems = [bot_pages];
    if (finalRole.includes("Admin")) {
      menuItems = [bot_pages, pages, chat_page, team_page, utilities];
    } else {
      if (finalRole.includes("Create Knowledge Base")) {
        menuItems.push(pages);
      }
      if (finalRole.includes("Only Query Knowledge Base")) {
        menuItems.push(chat_page);
      }
      if (finalRole.includes("Create Teams")) {
        menuItems.push(team_page);
      }
    }

    // Reorder menu items based on the specified order
    const orderMap = { pages: 1, chat_page: 2, bot_pages: 3, team_page: 4, utilities: 5 };
    menuItems.sort((a, b) => orderMap[a.id] - orderMap[b.id]);

    setMenu(menuItems);
  }

  const navItems = menu.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>
    <List>
      <Select
        fullWidth
        disabled={disabled}
        color='secondary'
        value={activeTeam}
        onChange={(e) => handleTeamChange(e.target.value)} // Update setActiveTeam on change
      >
        {myTeams.map((team) => (
          <MenuItem color='secondary' key={team.id} value={team.id}>
            {team.team_name}
          </MenuItem>
        ))}
      </Select>
    </List>
    {navItems}
  </>;
};

export default MenuList;