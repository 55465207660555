import Stack from '@mui/material/Stack';
import SideNavBarAppName from './SideNavBarAppName';
import SideNavBarIcons from './SideNavBarIcons';
import SideNavBarLinks from './SideNavBarLinks';
import SideNavBarLogin from './SideNavBarLogin';
import SideNavBarPageLinks from './SideNavBarPageLinks';

const SideNavBar = () => {
  return (
    <Stack
      direction='column'
      sx={{
        display: { sx: 'none', md: 'block' },
        width: '300px',
        height:"100%"
      }}
    >
      {/* App Name */}
      <SideNavBarAppName />

      {/* App Links */}
      <SideNavBarPageLinks />

      {/* Login  */}
      <SideNavBarLogin />

      {/* Links  */}
      <SideNavBarLinks />

      {/* Icons  */}
      <SideNavBarIcons />
    </Stack>
  );
};

export default SideNavBar;
