import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase } from '@mui/material';
import { MENU_OPEN } from 'store/actions';
import { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import getUserInfo, { getSingleTenants } from 'services/app.services';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  const [org,setOrg] = useState("")
  const [image,setImage] = useState("")

  useEffect(() => {
    const orgn = localStorage.getItem("organization")
    if(!orgn){
      getOrgName().then()
    }else{
      setOrg(orgn)
      getSingleTenants(orgn).then(res=>setImage(res?.logo_url))
    }
  }, [org]);

  const getOrgName = async() => {
    const uid = localStorage.getItem("uid")
    const user = await getUserInfo(uid)
    const { data } = await axios.post("https://fast.asklly.ai/get/org",{
      email: user?.email
    })
    const res = await getSingleTenants(data)
    setImage(res?.logo_url)
    setOrg(data)
  }

  const orgName = () => {
    return org
  }

  return (
    <ButtonBase 
      disableRipple 
      onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} 
      component={Link} 
      to={"/marketplace"} 
      sx={{ height: '5vh', width: '100%', display: 'flex', alignItems: 'left', justifyContent: 'left' }}
    >
      {image? <><img src={image || "logo.png"} style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain', borderRadius: '50%', display: 'block',boxSizing: 'border-box' }}/>
        &nbsp;&nbsp;<span className="font-sans text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-blue-700">{org}</span></>
        :<span className="font-sans text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-blue-700">{org} &nbsp;</span>} 
    </ButtonBase>
  );
};

export default LogoSection;
