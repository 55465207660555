import React, { useState } from 'react';
import { Autocomplete, TextField, Typography, Stack, Chip, useTheme, useMediaQuery, Modal, Box, IconButton } from '@mui/material';
import { useEffect } from 'react';

const ChatSearch = ({ kbhub, kbs, api, bot, handleSelectChange, nav, id }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    
    const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
        if (bot && bot.length > 0) {
            const initialSelected = kbhub 
                ? kbs.filter(kb => bot.includes(kb.kb_id))
                    .map(kb => ({
                        type: 'kb',
                        id: kb.kb_id,
                        label: kb.kb_name
                    }))
                : api.filter(a => bot.includes(a.api_key))
                    .map(a => ({
                        type: 'api',
                        id: a.api_key,
                        label: a.bot_name
                    }));
            setSelectedValues(initialSelected);
        }
    }, [bot, kbs, api, kbhub]);

    const options = [
        { type: 'title', label: 'Select Knowledge Base', disabled: true },
        ...kbs.map(kb => ({
            type: 'kb',
            id: kb.kb_id,
            label: kb.kb_name
        })),
        { type: 'title', label: 'Select Assistants', disabled: true },
        ...api.map(assistant => ({
            type: 'api',
            id: assistant.api_key,
            label: assistant.bot_name
        }))
    ];

    useEffect(()=>{
        setSelectedValues([])
    },[id])

    const handleOptionSelect = (event, newValue) => {
        // Keep only the most recently selected value
        const latestValue = newValue[newValue.length - 1];
        if (latestValue) {
            setSelectedValues([latestValue]);
            
            if (latestValue.type === 'kb') {
                handleSelectChange({
                    target: { value: latestValue.id }
                });
            } else if (latestValue.type === 'api') {
                nav(`/api/${latestValue.id}`);
            }
        } else {
            setSelectedValues([]);
        }
    };

    return (
        <div style={{ flex: 1 }} className={`relative w-full ${isMobile ? 'mx-2' : isTablet ? 'ml-2' : 'md:ml-4'} mr-4`}>
            <Stack spacing={1}>
                <Autocomplete
                    multiple
                    value={selectedValues}
                    options={options}
                    getOptionLabel={(option) => option.label || ''}
                    onChange={handleOptionSelect}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            color='secondary'
                            placeholder={selectedValues.length === 0 ? (isMobile ? "Select" : "Select Knowledge Hubs/AI Assistant") : ""}
                            className="bg-white"
                            sx={{
                                '& .MuiInputBase-root': {
                                    minHeight: isMobile ? '45px' : '56px'
                                },
                                '& .MuiInputBase-input': {
                                    fontSize: isMobile ? '0.875rem' : '1rem'
                                }
                            }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li 
                            {...props}
                            style={{
                                ...(option.disabled && {
                                    pointerEvents: 'none',
                                    opacity: 0.6,
                                })
                            }}
                        >
                            <Typography 
                                variant={isMobile ? "body2" : "body1"}
                                sx={{
                                    ...(option.disabled && {
                                        fontWeight: 'bold',
                                        color: '#666'
                                    })
                                }}
                            >
                                {option.label}
                            </Typography>
                        </li>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                key={option.id}
                                label={option.label}
                                size={isMobile ? "small" : "medium"}
                                onDelete={() => {
                                    setSelectedValues([]);
                                    handleSelectChange({
                                        target: { value: option.id }
                                    });
                                }}
                                sx={{
                                    backgroundColor: '#f0f0f0',
                                    '&:hover': {
                                        backgroundColor: '#e0e0e0'
                                    },
                                    fontSize: isMobile ? '0.75rem' : '0.875rem'
                                }}
                            />
                        ))
                    }
                />
            </Stack>
        </div>
    );
};

export default ChatSearch;