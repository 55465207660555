import React from 'react';
import { Shield, Lock, AlertTriangle, FileText } from 'lucide-react';

const SecuritySection = () => {
  return (
    <section className="py-12 md:py-24 px-4 bg-gradient-to-br from-gray-900 via-blue-900 to-gray-900 text-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="grid lg:grid-cols-2 gap-12">
          <div className="space-y-8">
            <div>
              <h2 className="text-4xl lg:text-5xl font-bold mb-6">
                Supervision, security and auditing
              </h2>
              <p className="text-lg text-gray-300 mb-12 max-w-3xl">
                To mitigate risks inherent in the non-deterministic nature of LLMs, AskLLY.ai
                wraps LLMs in supervisory layers to reduce hallucinations, ensure security, and
                prevent abuse.
              </p>
            </div>

            <div className="transform hover:-translate-y-1 transition-all duration-300 bg-white/5 backdrop-blur-lg p-6 rounded-lg border border-white/10">
              <div className="flex items-start gap-4">
                <div className="w-12 h-12 rounded-lg bg-blue-500/20 flex items-center justify-center flex-shrink-0">
                  <Shield className="w-6 h-6 text-blue-400" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Filters and monitoring</h3>
                  <p className="text-gray-400">
                    Built-in monitors and filters for topics and keywords, with per-session evaluation capabilities. Reviews user input and LLM responses to ensure truthful, relevant, and helpful interactions.
                  </p>
                </div>
              </div>
            </div>

            <div className="transform hover:-translate-y-1 transition-all duration-300 bg-white/5 backdrop-blur-lg p-6 rounded-lg border border-white/10">
              <div className="flex items-start gap-4">
                <div className="w-12 h-12 rounded-lg bg-purple-500/20 flex items-center justify-center flex-shrink-0">
                  <Lock className="w-6 h-6 text-purple-400" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Secure integration</h3>
                  <p className="text-gray-400">
                    Enterprise-grade security measures ensure safe integration with your existing systems while maintaining data privacy and compliance requirements.
                  </p>
                </div>
              </div>
            </div>

            <div className="transform hover:-translate-y-1 transition-all duration-300 bg-white/5 backdrop-blur-lg p-6 rounded-lg border border-white/10">
              <div className="flex items-start gap-4">
                <div className="w-12 h-12 rounded-lg bg-green-500/20 flex items-center justify-center flex-shrink-0">
                  <AlertTriangle className="w-6 h-6 text-green-400" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Prompt injection detection</h3>
                  <p className="text-gray-400">
                    Advanced security features to detect and prevent malicious prompt injection attempts and maintain system integrity.
                  </p>
                </div>
              </div>
            </div>

            <div className="transform hover:-translate-y-1 transition-all duration-300 bg-white/5 backdrop-blur-lg p-6 rounded-lg border border-white/10">
              <div className="flex items-start gap-4">
                <div className="w-12 h-12 rounded-lg bg-yellow-500/20 flex items-center justify-center flex-shrink-0">
                  <FileText className="w-6 h-6 text-yellow-400" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Auditing and inspection</h3>
                  <p className="text-gray-400">
                    Comprehensive audit trails and inspection tools to monitor system usage, track interactions, and ensure compliance with security policies.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="relative hidden lg:block">
            <div className="absolute inset-0">
              {/* Animated Security Visualization */}
              <div className="relative h-full">
                {/* Circular Background Glow */}
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 h-96 bg-blue-500/20 rounded-full blur-3xl animate-pulse" />
                
                {/* Security Grid Pattern */}
                <div className="absolute inset-0 grid grid-cols-8 grid-rows-8 gap-4">
                  {Array(64).fill(null).map((_, i) => (
                    <div
                      key={i}
                      className="w-full h-full bg-white/5 rounded-lg transform transition-all duration-1000 animate-bounce"
                      style={{
                        animationDelay: `${(i % 8) * 100}ms`,
                        animationDuration: '3s'
                      }}
                    />
                  ))}
                </div>

                {/* Central Shield Icon */}
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-32 h-32 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-full flex items-center justify-center animate-pulse">
                  <Shield className="w-16 h-16 text-white" />
                </div>

                {/* Orbiting Security Elements */}
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-64 h-64 animate-spin" style={{ animationDuration: '20s' }}>
                  {[0, 90, 180, 270].map((rotation, i) => (
                    <div
                      key={i}
                      className="absolute w-12 h-12 bg-white/10 rounded-lg flex items-center justify-center transform -translate-x-1/2 -translate-y-1/2"
                      style={{ 
                        transform: `rotate(${rotation}deg) translateX(8rem) rotate(-${rotation}deg)`
                      }}
                    >
                      {i === 0 && <Lock className="w-6 h-6 text-purple-400" />}
                      {i === 1 && <AlertTriangle className="w-6 h-6 text-green-400" />}
                      {i === 2 && <Shield className="w-6 h-6 text-blue-400" />}
                      {i === 3 && <FileText className="w-6 h-6 text-yellow-400" />}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecuritySection;