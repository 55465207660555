import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import { useState } from 'react';
import Joyride from 'react-joyride';
import { useEffect } from 'react';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const [ob,setOb] = useState(false);
  const [{ run,steps },setState] = useState({
    run: true,
    steps: [
      {
        content:<h2>Lets Begin your journey</h2>,
        locate:{skip:<strong>Skip</strong>},
        placement: "center",
        target: "body"
      },
      {
        content:<h2>You can view all the created AI bots here</h2>,
        loacte:{skip:<strong>Skip</strong>},
        placement: "right",
        target:"#bots"
      },
      {
        content:<h2>You can view all your documents/files in your knowledge hub</h2>,
        loacte:{skip:<strong>Skip</strong>},
        placement: "right",
        target:"#repo"
      },
      {
        content:<h2>You can create your knowledge hub by uploading files, capturing the data, adding Q/A and etc.</h2>,
        loacte:{skip:<strong>Skip</strong>},
        placement: "right",
        target:"#kb"
      },
      {
        content:<h2>Train your custom AI chatbot by using your knowledge hub</h2>,
        loacte:{skip:<strong>Skip</strong>},
        placement: "right",
        target:"#create_bot"
      },
      {
        content:<h2>And finally you can test by chating with your AI Assistant here...</h2>,
        loacte:{skip:<strong>Skip</strong>},
        placement: "right",
        target:"#chat"
      },
    ]
  })
  useEffect(()=>{
    const o = localStorage.getItem("ob")
    setState(prev=>{
      return{
        run:o,
        steps:[...prev.steps]
      }
    })
    setOb(o)
  },[])
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <Joyride
        continuous
        callback={()=>{}}
        run={ob  === "true"}
        steps={steps}
        hideCloseButton
        showSkipButton
        scrollToFirstStep
        showProgress
      /> */}
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} showHome={false} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
        <Outlet />
      </Main>
    </Box>
  );
};

export default MainLayout;
