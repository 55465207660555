import React, { useState, useEffect } from 'react';
import { Search, Plus, ChevronRight, Star, MessageSquare, Users, 
  GridIcon, List as ListIcon, ChevronDown, ArrowUpDown, Info,
  ChevronLeft, ChevronRight as ChevronRightIcon, 
  Edit,
  Clock,
  BotIcon
} from 'lucide-react';
import { Link } from "react-router-dom";
import { IconButton, Stack, Typography, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router';
import {  Home, MoreVert, SmartToy } from "@mui/icons-material";
import { useAccess } from 'context/AccessContext';
import save from "assets/images/save.png"

// Base styled components remain the same as before, excluding unused components
const MainCard = ({ children, className = '' }) => (
  <div className={`bg-white rounded-lg shadow-sm p-6 ${className}`}>
    {children}
  </div>
);

const TextField = ({ startAdornment, className = "", ...props }) => {
  return (
    <div className="relative w-full">
      {startAdornment && (
        <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none">
          {startAdornment}
        </div>
      )}
      <input
        {...props}
        className={`w-full h-12 px-4 bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 ${
          startAdornment ? 'pl-10' : ''
        } ${className}`}
      />
    </div>
  );
};

const Button = ({ variant = 'contained', color = 'primary', startIcon, children, className = '', disabled, ...props }) => {
  const variants = {
    contained: `${disabled ? 'bg-gray-300' : 'bg-[#5E35B1] hover:bg-[#5E35E6]'} text-white`,
    outlined: `border-2 ${disabled ? 'border-gray-300 text-gray-400' : 'border-purple-600 text-purple-600 hover:bg-purple-50'}`,
    text: `${disabled ? 'text-gray-400' : 'text-purple-600 hover:bg-purple-50'}`
  };

  return (
    <button
      disabled={disabled}
      className={`px-4 py-2 rounded-lg flex items-center gap-2 transition-colors ${variants[variant]} ${className}`}
      {...props}
    >
      {startIcon}
      {children}
    </button>
  );
};

const Fab = ({ children, ...props }) => (
  <button
    className="flex items-center gap-2 px-4 py-2 rounded-full bg-purple-100 text-purple-800 hover:bg-purple-200 transition-colors"
    {...props}
  >
    {children}
  </button>
);

const Select = ({ value, onChange, children, startIcon }) => (
  <div className="relative">
    <select
      value={value}
      onChange={onChange}
      className="appearance-none pl-10 pr-8 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 bg-white"
    >
      {children}
    </select>
    <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
      {startIcon}
    </div>
    <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
  </div>
);

const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  
  if (totalPages <= 1) return null;

  return (
    <div className="flex items-center justify-center gap-2">
      <Button
        variant="outlined"
        className="p-2"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <ChevronLeft className="w-4 h-4" />
      </Button>
      
      {pages.map(page => (
        <Button
          key={page}
          variant={currentPage === page ? 'contained' : 'outlined'}
          className={`w-10 h-10 p-0 ${currentPage === page ? '' : 'hover:bg-purple-50'}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </Button>
      ))}
      
      <Button
        variant="outlined"
        className="p-2"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ChevronRightIcon className="w-4 h-4" />
      </Button>
    </div>
  );
};

const AIMarketplace = () => {
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState('newest');
  const [viewMode, setViewMode] = useState('grid');
  const [assistants, setAssistants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedCard, setExpandedCard] = useState(null);

  const nav = useNavigate();
  const itemsPerPage = 6;

  useEffect(()=>{
    getAllBots();
    return () => {
      
    };
  },[])

  const { fetchtrainedBots } = useAccess()

  const getAllBots = async () => {
    setLoading(true);
    const res = await fetchtrainedBots();
    if (res.data.length !== 0) {
      console.log(res.data[0]);
      setAssistants(res.data[0])
    }
    setLoading(false);
  };

  const sortAssistants = (assistants) => {
    switch (sortBy) {
      case 'newest':
        return [...assistants].sort((a, b) => {
          const timestampA = a.created_at ? (a.created_at.includes('/') ? new Date(a.created_at.split('/').reverse().join('-')).getTime() : parseInt(a.created_at)) : 
                            a.timestamp ? (a.timestamp.includes('/') ? new Date(a.timestamp.split('/').reverse().join('-')).getTime() : parseInt(a.timestamp)) : 0;
          const timestampB = b.created_at ? (b.created_at.includes('/') ? new Date(b.created_at.split('/').reverse().join('-')).getTime() : parseInt(b.created_at)) :
                            b.timestamp ? (b.timestamp.includes('/') ? new Date(b.timestamp.split('/').reverse().join('-')).getTime() : parseInt(b.timestamp)) : 0;
          return timestampB - timestampA;
        });
      case 'most_chats':
        return [...assistants].sort((a, b) => b.chats - a.chats);
      case 'most_views':
        return [...assistants].sort((a, b) => b.views - a.views);
      case 'a_to_z':
        return [...assistants].sort((a, b) => a.botname.localeCompare(b.botname));
      case 'z_to_a':
        return [...assistants].sort((a, b) => b.botname.localeCompare(a.botname));
      default:
        return assistants;
    }
  };

  const filteredAssistants = sortAssistants(
    assistants.filter(assistant =>
      assistant.botname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      assistant.initial?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      assistant.organization?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAssistants.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setExpandedCard(null); // Reset expanded card when changing pages
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getDaysAgo = (timestamp) => {
    if (!timestamp) return 'Today';
    
    // Handle both date string and timestamp formats
    const date = timestamp.includes('/') 
      ? new Date(timestamp.split('/').reverse().join('-')) 
      : new Date(parseInt(timestamp));
      
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays === 0 ? 'Today' : `${diffDays}d`;
  };

  const uid = localStorage.getItem('uid')
  const BotCard = ({ assistant }) => {
    const isExpanded = expandedCard === assistant.id;
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleMenuClick = (event) => {
      event.preventDefault(); // Prevent navigation
      event.stopPropagation(); // Stop event bubbling
      setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setMenuAnchorEl(null);
    };

    const handleEdit = (event) => {
      event.preventDefault(); // Prevent navigation
      event.stopPropagation(); // Stop event bubbling
      nav(`/edit/${assistant.id}`);
      handleMenuClose();
    };

    const handleCardClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      window.location.href = `/api/${assistant.apikey}`;
    };

    return (
      <Link to={`/api/${assistant.apikey}`}>
        <MainCard 
          className={`flex flex-col h-full transition-all hover:shadow-md cursor-pointer
            ${isExpanded ? 'ring-2 ring-purple-500' : ''}`}
          onClick={handleCardClick}
        >
          <div className="flex flex-col h-full">
            <div className="flex items-start justify-between mb-4">
              <div className='flex items-center'>
                {assistant?.image && assistant.image !== "null" ?
                  (
                    <img className='w-10 h-10 rounded-full mr-2' src={assistant.image} />
                  ) : (
                    <SmartToy className='w-8 h-8 text-[#5E35B1] rounded-full mr-2' />
                  )
                }
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">{assistant.botname}</h3>
                  <p className="text-sm text-gray-500">{assistant.organization}</p>
                </div>
              </div>
              {uid === assistant?.uid &&<>
                <IconButton onClick={handleMenuClick}>
                  <MoreVert className={`w-5 h-5 text-purple-500 transition-transform ${isExpanded ? 'rotate-180' : ''}`} />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                  >
                  <MenuItem onClick={handleEdit}><Edit />&nbsp;&nbsp;&nbsp; Edit</MenuItem>
                </Menu>
              </>}
            </div>
            <p className="text-sm text-gray-600 mb-4">
              {assistant.initial}
            </p>
            <div className="flex flex-wrap gap-2 mb-4">
              {assistant.training_files?.split(',').map(file => (
                <span key={file} className="px-2 py-1 bg-purple-50 text-purple-700 rounded-full text-xs">
                  {file}
                </span>
              ))}
            </div>
            <div className="flex flex-wrap gap-2 mb-4">
              {assistant.tags?.map(tag => (
                <span key={tag} className="px-2 py-1 bg-blue-50 text-blue-700 rounded-full text-xs">
                  #{tag}
                </span>
              ))}
            </div>
            <div className="mt-auto">
              <div className="flex items-center gap-4 mb-4">
                <div className="flex items-center gap-1">
                  <Users className="w-4 h-4 text-gray-400" />
                  <span className="text-sm">{assistant?.views}</span>
                </div>
                <div className="flex items-center gap-1">
                  <MessageSquare className="w-4 h-4 text-gray-400" />
                  <span className="text-sm">{assistant?.chats}</span>
                </div>
                <div className="flex items-center gap-1">
                  <Clock className="w-4 h-4 text-gray-400" />
                  <span className="text-sm">{getDaysAgo(assistant?.created_at || assistant?.timestamp)}</span>
                </div>
              </div>
              <Button variant="contained" onClick={handleCardClick} className="w-full">
                Start Chat
              </Button>
            </div>
          </div>
        </MainCard>
      </Link>
    );
  };

  const BotListItem = ({ assistant }) => (
    <Link to={`/api/${assistant.apikey}`} className='mx-6'>
    <MainCard onClick={()=>nav(`/api/${assistant.apikey}`)} className="hover:shadow-md transition-all cursor-pointer">
      <div className="flex items-start gap-6">
        <div className="flex-1">
          <div className="flex items-center">
            {assistant?.image && assistant.image !== "null" ? (
                <img className='w-10 h-10 rounded-full mr-2' src={assistant.image} />
              ) : (
                <SmartToy className='w-8 h-8 text-[#5E35B1] rounded-full mr-2' />
              )
            }
            <div>
              <h3 className="text-lg font-semibold text-gray-900">{assistant.botname}</h3>
              <p className="text-sm text-gray-500">{assistant.organization}</p>
            </div>
          </div>
          <p className="text-sm text-gray-600 my-2">{assistant.initial}</p>
          <div className="flex items-center gap-4 mb-4">
            <div className="flex items-center gap-1">
              <Users className="w-4 h-4 text-gray-400" />
              <span className="text-sm">{assistant?.views}</span>
            </div>
            <div className="flex items-center gap-1">
              <MessageSquare className="w-4 h-4 text-gray-400" />
              <span className="text-sm">{assistant?.chats}</span>
            </div>
            <div className="flex items-center gap-1">
              <Clock className="w-4 h-4 text-gray-400" />
              <span className="text-sm">{getDaysAgo(assistant?.created_at || assistant?.timestamp)}</span>
            </div>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            {assistant.training_files?.split(',').map(file => (
              <span key={file} className="px-2 py-1 bg-purple-50 text-purple-700 rounded-full text-xs">
                File {file}
              </span>
            ))}
          </div>
          <div className="flex flex-wrap gap-2">
            {assistant.tags?.map(tag => (
              <span key={tag} className="px-2 py-1 bg-blue-50 text-blue-700 rounded-full text-xs">
                {tag}
              </span>
            ))}
          </div>
        </div>
        <Button variant="contained" color='secondary' size="sm" onClick={(e) => {
          nav(`/api/${assistant.apikey}`);
        }}>
          Start Chat
        </Button>
      </div>
    </MainCard>
    </Link>
  );

  return (
    <Stack>
      <MainCard>
        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
          <div className="flex justify-center items-center">
            <Link to='/marketplace'>
              <Home color="secondary" />
            </Link>
            <ChevronRight />
            <Typography sx={{fontWeight: 'bold'}}>AI Marketplace</Typography>
          </div>
          <Fab variant="extended" size="medium" color="secondary"
            onClick={()=>nav("/kb")}
          >
            <Plus className="w-4 h-4" />
            Create AI
          </Fab>
        </Stack>
        <Stack sx={{ mt: 1}}><Typography>Find the perfect AI assistant for your enterprise needs</Typography></Stack>
      </MainCard>

      <Stack sx={{ mt: 2, borderRadius: '8px', overflow: 'hidden'}}>
      <MainCard>
        {!loading && (
          <div className="space-y-6">
            <div className="flex flex-col md:flex-row gap-4">
              <TextField
                placeholder="Search AI assistants"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                startAdornment={<Search className="w-5 h-5" />}
                className="flex-1"
              />
              <div className="flex gap-4">
                <Select 
                  value={sortBy} 
                  onChange={(e) => setSortBy(e.target.value)}
                  startIcon={<ArrowUpDown className="w-5 h-5" />}
                >
                  <option value="newest">Newest First</option>
                  <option value="most_chats">Most Chats</option>
                  <option value="most_views">Most Views</option>
                  <option value="a_to_z">Sort A to Z</option>
                  <option value="z_to_a">Sort Z to A</option>
                </Select>
                <div className="flex gap-2">
                  <Button
                    variant={viewMode === 'grid' ? 'contained' : 'outlined'}
                    onClick={() => setViewMode('grid')}
                  >
                    <GridIcon className="w-4 h-4" />
                  </Button>
                  <Button
                    variant={viewMode === 'list' ? 'contained' : 'outlined'}
                    onClick={() => setViewMode('list')}
                  >
                    <ListIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </div>

            {filteredAssistants.length > 0 ? viewMode === 'grid' ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {currentItems.map((assistant) => (
                  <BotCard key={assistant.id} assistant={assistant} />
                ))}
              </div>
            ) : (
              <div className="space-y-4">
                {currentItems.map((assistant) => (
                  <BotListItem key={assistant.id} assistant={assistant} />
                ))}
              </div>
            ): filteredAssistants.length === 0 ? (
              <div className="h-[60vh] flex flex-col items-center justify-center text-center">
                <h2 className="text-2xl font-bold mb-2">No AI Assistant Found</h2>
                <p className="text-gray-600 mb-4">Create your new AI assistant!</p>
                <Button variant="outlined">
                  Create AI Assistant
                </Button>
              </div>
            ) : null}

            <Pagination
              totalItems={filteredAssistants.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        )}

        {loading && (
          <div className="h-60 flex items-center justify-center">
            <div className="w-16 h-16 border-4 border-purple-200 border-t-purple-600 rounded-full animate-spin"></div>
          </div>
        )}
      </MainCard>
      </Stack>
    </Stack>
  );
};

export default AIMarketplace;