import { Box, Typography } from '@mui/material';
// import appIcon from '../../images/favicon-32x32.png';

const SideNavBarAppName = () => {
  return (
    <Box>
      <Box
        my={2}
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          borderRadius: 1,
        }}
      >
        {/* <img
          src={appIcon}
          style={{
            width: '30px',
          }}
          alt=''
        /> */}
        <Typography variant='h5' pl={1} sx={{ fontWeight: 'bold' }}>
          LinkedHash
        </Typography>
      </Box>
      <hr style={{ borderColor: '#0000001f', opacity: '50%' }} />
    </Box>
  );
};

export default SideNavBarAppName;
