import { Close, Delete, Done, Edit, MoreHoriz } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { ExternalLink, Share } from 'lucide-react';
import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'; // Ensure react-router-dom is imported
import { changeChatName } from 'services/app.services';

function ChatCard({i, handleDelete, shareChat, isMobile, setShow}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(i.chatName);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hover, setHover] = useState(false); // Track hover state
    const [showMore, setShowMore] = useState(false); // New state to control More button visibility
    const timeoutRef = useRef(null);
    const [link,setLink] = useState('')

    useEffect(()=>{
        console.log(i)
        if(i?.api){
            setLink(`/api/${i?.chatID}`)
        }else{
            setLink(`/chat-with-bot?id=${i.chatID}`)
        }
    },[])

    useEffect(() => {
        // Update showMore when open or hover changes
        setShowMore(open || hover);
    }, [open, hover]);

    const handleClick = (event) => {
        event.stopPropagation(); // Prevent navigation on click of More button
        setOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
        // Add a small delay before hiding the More button
        timeoutRef.current = setTimeout(() => {
            if (!hover) {
                setShowMore(false);
            }
        }, 100);
    };

    const handleMouseEnter = () => {
        setHover(true);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    const handleMouseLeave = () => {
        setHover(false);
        if (!open) {
            setShowMore(false);
        }
    };

    const editName = async () => {
        setLoading(true);
        const uid = localStorage.getItem("uid");
        const org = localStorage.getItem("organization");
        const form = new FormData();
        form.append("organization", org);
        form.append("name", value);
        form.append("userID", uid);
        form.append("chatId", i.chatID);
        const res = await changeChatName(form);
        console.log(res);
        setEdit(false);
        setOpen(false);
        setAnchorEl(null);
        setLoading(false);
    };

    return (
        <Stack
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="hover:bg-[#EDE7F6] hover:text-[#5E35B1] rounded-md cursor-pointer"
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ p: 1, minHeight: '50px' }} // Add a minimum height to prevent bouncing
        >
            {!edit ? (
                <>
                    <Link 
                        key={i.chatID} 
                        to={link} 
                        style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', flexGrow: 1 }}
                        onClick={(e) => {
                            if (anchorEl) {
                                // Prevent link navigation if the menu is open
                                e.preventDefault();
                            }
                            if(isMobile){
                                setShow(false)
                            }
                        }}
                        className='w-full'
                    >
                        <Typography variant='body1' sx={{ ml: 0 }}>{value}</Typography>
                    </Link>
                    <div style={{ width: '40px', display: 'flex', justifyContent: 'flex-end' }}> {/* Fixed width container for the button */}
                        {showMore && (
                            <IconButton
                                id="demo-positioned-button"
                                aria-controls={open ? 'demo-positioned-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                onMouseDown={(e) => e.stopPropagation()} // Prevent click on Link
                            >
                                <MoreHoriz />
                            </IconButton>
                        )}
                    </div>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={shareChat}><ExternalLink size={20} />&nbsp;&nbsp;&nbsp; Share</MenuItem>
                        <MenuItem onClick={() => setEdit(true)}><Edit />&nbsp;&nbsp;&nbsp; Rename</MenuItem>
                        {/* <MenuItem onClick={() => setEdit(true)}><ArchiveOutlined />&nbsp;&nbsp;&nbsp; Archive</MenuItem> */}
                        <MenuItem onClick={() => handleDelete(i.chatID)}><Delete sx={{ color: 'red' }} />&nbsp;&nbsp;&nbsp; Delete</MenuItem>
                    </Menu>
                </>
            ) : (
                <Stack direction={"row"} sx={{ mt: 2, px: 1, py: 1 }}>
                    <TextField value={value} disabled={loading} onChange={(e) => setValue(e.target.value)} variant='standard' />
                    <IconButton
                        onClick={editName}
                        disabled={loading}
                    >
                        {!loading ? <Done sx={{ color: "lightgreen" }} />
                        :
                        <div className=''>
                            <svg className='svg' viewBox="25 25 50 50">
                                <circle className='circle' r="20" cy="50" cx="50"></circle>
                            </svg>
                        </div>}
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            setOpen(false);
                            setAnchorEl(null);
                            setEdit(false);
                        }}
                        disabled={loading}
                    >
                        <Close sx={{ color: "red" }} />
                    </IconButton>
                </Stack>
            )}
        </Stack>
    );
}

export default ChatCard;