import axios from "axios";
import { useState } from "react";
import { useContext } from "react";
import { useEffect, createContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { googleLogout } from '@react-oauth/google';
import getUserInfo, { getCollections, getKbByName, getSingleTenants } from "services/app.services";

export const AccessContext = createContext(null)

export function AccessProvider({children}){
    const nav = useNavigate()
    const URL = "https://fast.asklly.ai"

    const [uid, setUid] = useState("");
    const [org, setOrg] = useState("");
    const [state, setState] = useState(false)
    const [logout, setLogout] = useState(false)
    
    useEffect(()=>{
        setUid("")
        setOrg("")
    },[logout])

    useEffect(()=>{
        const uuid = localStorage.getItem("uid")
        const orgn = localStorage.getItem("organization")
        console.log(uuid, orgn)
        if (uuid && orgn){
            setOrg(orgn)
            setUid(uuid)
        }else{
            getOrgName().then()
        }
    },[state, uid])

    const logOut = async() => {
        if(localStorage.getItem("gauthcred")){
            localStorage.removeItem("gauthcred");
            googleLogout();
        }
        setLogout(true)
        localStorage.clear()
        nav("/login")
    }

    const getOrgName = async() => {
        const uuid = localStorage.getItem("uid")
        const user = await getUserInfo(uuid)
        const { data } = await axios.post(`${URL}/get/org`,{
          email: user?.email
        })
        setOrg(data)
        return data
    }
    
    async function signup(fullname,email,password,gauth){
        try {
            const body = {
                email:email, 
                password:password,
                fullname:fullname,
                gauth:gauth
            }
            const { data } = await axios.post(`${URL}/create_user`,body)
            ;
            localStorage.setItem("ob",true)
            if(nav){
                toast("Check your inbox for otp", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                localStorage.setItem('email',email);
                nav('/otp')
            }
            return data
        } catch (error) {
            console.log(error);
            if (error.response) {
                toast.error(`${error.response.data.detail || "There was an error in Signing up"}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in uploading data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function verifyOtp(email,otp){
        try {
            const body = {
                email:email, 
                otp:otp
            }
            const { data } = await axios.post(`${URL}/verify-otp`,body)
            ;
            localStorage.setItem("ob",true)
            if(nav){
                setUid(data?.uid)
                setState(true)
                localStorage.setItem('uid',data.uid)
                localStorage.setItem('user',JSON.stringify(data.user))
                nav('/marketplace')
            }
            return data
        } catch (error) {
            console.log(error);
            if (error.response) {
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("Server Error", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function resendOtp(email){
        try {
            const body = {
                email:email
            }
            const { data } = await axios.post(`${URL}/resend-otp`,body)
            ;
            toast("Otp Sent", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return data
        } catch (error) {
            console.log(error);
            if (error.response) {
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in uploading data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function login(email,password){
        try {
            const body = {
                email:email, 
                password:password
            }
            const { data } = await axios.post(`${URL}/login_user`,body)
            ;
            if(data.status_code){
                return toast.error(data.detail, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            localStorage.setItem('uid',data.uid)
            setUid(data?.uid)
            setState(true)
            localStorage.setItem('user',JSON.stringify(data.user))
            nav('/marketplace')
            return data
        } catch (error) {
            console.log(error);
            if (error.response) {
                if (error.response.status === 401) {
                    localStorage.setItem('email',email);
                    nav("/otp")
                    return toast.error(`${error.response.data.detail }`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }); 
                }
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in uploading data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function forgetPassword(email){
        try {
            const body = new FormData()
            body.append("email",email)
            const { data } = await axios.post(`${URL}/forget-password`,body)
            ;
            toast("Reset email sent", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return data
        } catch (error) {
            console.log(error);
            if (error.response) {
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in uploading data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function isEmail(email){
        try {
            const body = new FormData()
            body.append("email",email)
            const { data } = await axios.post(`${URL}/decrypt-email`,body)
            ;
            if(data?.email){
                return data
            }else{
                nav("/404")
                return data
            }
        } catch (error) {
            console.log(error);
            if (error.response) {
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in uploading data", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    
    async function changePasswordForgot(token,password){
        try {
            const body = new FormData()
            body.append("token",token)
            body.append("password",password)
            const { data } = await axios.post(`${URL}/reset-password`,body)
            ;
            toast(data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            nav("/login")
            return data
        } catch (error) {
            console.log(error);
            if (error.response) {
                toast.error(`${error.response.data.detail }`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });  
            } else {
                toast.error("There was an error in changing password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }

    async function fetchtrainedBots(){
        try {
            const uuid = localStorage.getItem("uid")
            const orgn = await getOrgName()
            if(!uuid) return alert("no uid")
            const headers = {
                'X-User-Id':uuid,
                'X-Org': orgn
            }
            console.log(headers)
            console.log(uuid, uid);
            const { data } = await axios.get(`${URL}/getmybots`,{headers})
            console.log("Response :",data.res)
            return {ok:true,data:data.res}
        } catch (error) {
            console.log(error)
        }
    }

    async function getKb(){
        try {
            const uuid = localStorage.getItem("uid");
            const form = new FormData();
            const usr = await getUserInfo(uuid || uid);
            const email = usr.email;
            if (email == null) {
                email = localStorage.getItem("email");
            }
            form.append("email", email);
            const organization = await getOrgName();
            form.append("organization", organization || org);
            const { data } = await axios.post(`${URL}/get/kb`, form);
            return data
        } catch (error) {
            console.error(error)
            toast.error("Something went wrong", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    
    const handleGetCollections = async() => {
        try {
          const uid = localStorage.getItem("uid")
          console.log(uid)
          const all_collections = await getCollections(uid)
          console.log(all_collections.collections)
          return all_collections.collections
        } catch (error) {
          console.error(error)
        }
    }

    const handleGetKbNames = async() => {
        try {
            const uuid = localStorage.getItem("uid");
            const form = new FormData()
            const user = await getUserInfo(uuid)
            form.append("email", user?.email)
            const organization = await getOrgName()
            form.append("organization",organization)
            console.log(user?.email, organization)
            const data = await getKbByName(form)
            return data.kb
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <AccessContext.Provider value={{ handleGetKbNames,handleGetCollections,signup,login,forgetPassword,resendOtp,verifyOtp,isEmail,changePasswordForgot,setUid,fetchtrainedBots,getOrgName,getKb,logOut }}>
            {children}
        </AccessContext.Provider>
    )
}

export function useAccess() {
    const context = useContext(AccessContext);
    if (!context) {
        throw new Error('useAccess must be used within an AccessProvider');
    }
    return context;
}
