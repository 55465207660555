import { AppBar, Box, Button, ButtonBase, Divider, Drawer, Hidden, IconButton, Stack, Typography, styled } from "@mui/material";

/* Footer Component */
function Footer() {
    return ( 
        <div className="md:px-20 px-7 h-30 mt-6">
            <footer className="bg-gray-100 rounded-t-xl h-full p-7">
                <div align="left">
                    <span className="font-[Caveat] text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">asklly.ai&nbsp;</span> 
                </div>
                <div align="right">
                    <Typography variant="h5" sx={{ fontWeight: 500 }}>
                        Privacy Policy
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: 500 }}>
                        Terms of Service
                    </Typography>
                    © 2024 asklly.ai, All rights reserved.
                </div>
            </footer>
        </div>
    );
}

export default Footer;