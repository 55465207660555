import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from "config/firebase.config";
import copy from 'copy-to-clipboard'
import { createApi, deleteApi, deleteTrainingFiles, fetchbotapi } from "services/app.services";
import Loader from "components/Loader";
import { toast } from 'react-toastify'
import { Button, Checkbox, Chip, IconButton, Tab, Tabs, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Add, Delete, Home, MoreVert } from "@mui/icons-material";
import Modal from '@mui/material/Modal';
import { useLocation } from 'react-router-dom';
import { Box, Stack } from "@mui/system";
import notFound from "assets/images/404.png";
import { X, Send, ChevronRight, Trash2 } from 'lucide-react';
import MainCard from "ui-component/cards/MainCard";
import ResponsiveEmbedCode from './ResponsiveEmbedCode';
import { IoLogoAppleAr, IoCodeSlash, IoDocumentsSharp } from 'react-icons/io5'
import PDFReader from "components/pdf/PDFViewer";
import { useAccess } from "context/AccessContext";


function CustomizeBot() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { id } = useParams()
    const idd = queryParams.get('id')

    const { fetchtrainedBots } = useAccess()

    const [loading, setLoading] = useState(false)
    const [trainingFiles, setTrainingFiles] = useState([])
    const [settings, setSettings] = useState({
        title: '',
        initial: '',
        color: '#f97316',
        textColor: '#ffffff',
        tags: [] // New field for tags
    })
    const [file, setFile] = useState(null)
    const [iid, setIid] = useState(0)
    const [btnText, setBtnText] = useState("Copy to clipboard")
    const [url, seturl] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [kbOpen, setkbOpen] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [mainBtnText, setMainBtnText] = useState("Create AI Assistant")
    const [isok, setIsOk] = useState(false)
    const isMobile = useMediaQuery('(max-width:768px)');
    const [newTag, setNewTag] = useState(''); // State for new tag input
    const [tabValue, setTabValue] = useState(0)
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChange = (event) => {
        setSettings({ ...settings, [event.target.name]: event.target.value })
    }

    const handleTagChange = (event) => {
        setNewTag(event.target.value);
    }

    const handleTagKeyDown = (event) => {
        if (event.key === 'Enter' && newTag.trim() !== '') {
            setSettings(prev => ({ ...prev, tags: [...prev.tags, newTag.trim()] }));
            setNewTag(''); // Clear input after adding tag
        }
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const [api, setApi] = useState("");
    useEffect(() => {
        if (queryParams.get('id')) {
            setIid(queryParams.get('id'))
        }
        const uid = localStorage.getItem('uid')
        if (id === 'new') {
            fetchbotapi(idd).then((res) => {
                if (res.ok) {
                    setSettings({ title: res?.data?.botname, initial: res?.data?.initial, color: res?.data?.color, textColor: res?.data?.textcolor, tags: res?.data?.tags || [] }) // Load tags if available
                    seturl(res?.data?.image === "null" ? null : res?.data?.image)
                    setApi(res?.data?.apikey)
                    setTrainingFiles(res.kb)
                } else {
                    setSettings(null)
                }
            })
        } else {
            setMainBtnText("Update Settings")
            fetchbotapi(id).then((res) => {
                if (res.ok) {
                    setSettings({ title: res?.data?.title, initial: res?.data?.initial, color: res?.data?.color, textColor: res?.data?.textcolor, tags: res?.data?.tags || [] }) // Load tags if available
                    seturl(res?.data?.image === "null" ? null : res?.data?.image)
                    setApi(res?.data?.apikey)
                    setTrainingFiles(res.kb)
                } else {
                    setSettings(null)
                }
            })
        }
    }, [])

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const script = `<script src="https://cdn.jsdelivr.net/npm/asklly_windowed_chat/dist/index.min.js"></script>`
    const nav = useNavigate()
    const closeModal = () => {
        setIsOpen(false);
        nav('/marketplace')
    }
    const closeKbModel = () => {
        setkbOpen(false)
    }
    const copyy = () => {
        const copyText1 = `
            <script>
                window.chatx = {
                    chatbotKey: "${api}"
                }
            </script>
            ${script}
        `;
        copy(copyText1);
        setBtnText("Copied");
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(settings.tags.join(","))
        if (!api) {
            if (settings.title === '') {
                setLoading(false);
                return toast.error("All fields Required", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        setLoading(true);
        const form = new FormData()
        const uid = localStorage.getItem("uid")
        const dateObj = new Date
        const day = dateObj.getDate().toString()
        const mon = dateObj.getMonth() + 1
        const month = mon.toString()
        const year = dateObj.getFullYear().toString()
        try {
            let snap;
            if (file) {
                const s = ref(storage, `images/${file.name}`);
                await uploadBytes(s, file);
                snap = await getDownloadURL(s);
                seturl(snap);
                form.append("image", snap)
            } else if (url) {
                form.append("image", url)
            } else {
                form.append("image", url)
            }
            const initial = settings.initial || "Hello There! how can i help you today"
            const org = localStorage.getItem("organization")
            form.append("organization", org)
            form.append("tags",settings.tags.join(","))
            form.append("color", settings.color)
            form.append("textcolor", settings.textColor)
            form.append("title", settings.title)
            form.append("initial", initial)
            form.append("apikey", api ? api : "no")
            form.append("id", id === "new" ? idd : id)
            form.append("uid", uid)
            form.append("created_at", `${day.toString()}/${month}/${year.toString()}`)
            const res = await createApi(form);
            fetchtrainedBots()
            if (res) {
                setApi(res);
            }
        } catch (error) {
            console.error("Error:", error);
            // Handle errors here
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteApi = async () => {
        try {
            if (isok) {
                const form = new FormData()
                const org = localStorage.getItem("organization")
                form.append("organization", org)
                form.append("id", id)
                const uid = localStorage.getItem("uid")
                form.append("uid", uid)
                form.append("files", selectedFiles.join(","))
                const res = await deleteApi(form)
                setOpenDelete(false)
                nav("/marketplace")
            } else {
                toast.error("Please check the box to continue", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }

    const navBack = () => {
        nav("/marketplace")
    }

    const handleTagDelete = (indexToDelete) => {
        const updatedTags = settings.tags.filter((_, index) => index !== indexToDelete);
        setSettings(prevSettings => ({
            ...prevSettings,
            tags: updatedTags
        }));

    }

    const handleFileSelect = (fileId) => {
        setSelectedFiles((prevSelectedFiles) => {
            if (prevSelectedFiles.includes(fileId)) {
                return prevSelectedFiles.filter((id) => id !== fileId);
            } else {
                return [...prevSelectedFiles, fileId];
            }
        });
    };

    const [fileType, setFileType] = useState();
    const [fileUrl, setFileUrl] = useState();
    const [fileName, setFileName] = useState();
    const [openDrawer, setOpenDrawer] = useState(false);

    const closeDrawer = () => {
        setFileUrl(null);
        setFileType(null);
        setOpenDrawer(false);
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    const totalPages = Math.ceil(trainingFiles.length / itemsPerPage);
    const paginatedFiles = trainingFiles.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage) || [];
    
    function openFileDrawer(index) {
        const row = paginatedFiles[index]; // Changed from showData to trainingFiles
        console.log(row?.location)
        if (row?.file_name.toLowerCase().includes(".pdf")) {
          setFileType("pdf");
        } else if (row?.file_name.toLowerCase().includes(".docx") || row?.file_name.toLowerCase().includes(".doc")) {
          setFileType("docx");
        } else if (row?.file_name.toLowerCase().includes(".txt")) {
          setFileType("txt");
          fetch(file).then((res) => res.text().then(d => {
          }));
        } else {
          setFileType("No");
        }
        setFileUrl(row?.location);
        setFileName(row?.file_name);
        setOpenDrawer(true);
    }

    const handleDeleteFile = async () => {
        try {
            if(selectedFiles.length === paginatedFiles.length){
                return toast.error("All files can't be deleted", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
            const fileId = selectedFiles.join(",")
            const uid = localStorage.getItem("uid");
            await deleteTrainingFiles(id, fileId, uid);
            setSelectedFiles([]);
            setTrainingFiles(paginatedFiles.filter(file => !selectedFiles.includes(file.id)));
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    }

    return (
        <div className="h-full w-full">
            <MainCard>
                <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                    <div className="flex justify-center items-center">
                        <Link to='/marketplace'>
                            <Home color="secondary" />
                        </Link>
                        <ChevronRight />
                        <Typography sx={{fontWeight: 'bold'}}>AI Studio</Typography>
                    </div>
                </Stack>
                <Stack sx={{ mt: 1}}><Typography>Design your AI Assistant by adding or removing knowledge through documents, and seamlessly publish it to the AI Marketplace for wider access</Typography></Stack>
            </MainCard>
            <Stack sx={{ mt: 2, borderRadius: '8px', overflow: 'hidden'}}>
                <MainCard>
                    {settings ? (
                        <div className="">
                            <div className="">
                                <div className="relative flex justify-between items-center">
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={tabValue} textColor='secondary' indicatorColor="secondary" onChange={handleTabChange} aria-label="">
                                            <Tab iconPosition="start" icon={<IoLogoAppleAr />} label="Design" />
                                            <Tab iconPosition="start" icon={<IoDocumentsSharp />} label="Knowledge" />
                                            <Tab iconPosition="start" icon={<IoCodeSlash />} label="Embed" />
                                        </Tabs>
                                    </Box>
                                    <Tooltip title="Delete Assistant">
                                        <IconButton onClick={()=>setOpenDelete(true)}>
                                            <Trash2 className="text-red-500" size={18} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="p-2 md:p-2 w-full max-w-5xl mx-auto">
                                <div className="flex flex-col lg:flex-row gap-4 md:gap-6">
                                    {tabValue === 0 && <div className="md:p-2 p-2 flex flex-col md:flex-row">
                                    <form onSubmit={handleSubmit}>
                                        <label className="label">Assistant Name</label>
                                        <input
                                            autoComplete="off"
                                            placeholder="Enter an Title"
                                            name="title"
                                            id="Email"
                                            className="input w-full mt-2 mb-4"
                                            type="text"
                                            onChange={handleChange}
                                            value={settings.title}
                                        />
                                        <label className="label">Description or Initial Message</label>
                                        <input
                                            autoComplete="off"
                                            placeholder="Enter an Initial Message"
                                            name="initial"
                                            id="Email"
                                            className="input w-full mt-2 mb-4"
                                            type="text"
                                            onChange={handleChange}
                                            value={settings.initial}
                                        />
                                        <label className="label">Tags</label>
                                        <input
                                            autoComplete="off"
                                            placeholder="Press Enter to add a tag"
                                            className="input w-full mt-2 mb-4"
                                            type="text"
                                            value={newTag}
                                            onChange={handleTagChange}
                                            onKeyDown={handleTagKeyDown}
                                        />
                                        <div className="flex flex-wrap mt-2">
                                            {settings.tags.map((tag, index) => (
                                                <Chip 
                                                    color="secondary" 
                                                    key={index} 
                                                    label={`#${tag}`} 
                                                    className="mr-2 mb-2" 
                                                    onDelete={() => handleTagDelete(index)} 
                                                />
                                            ))}
                                        </div>
                                        <label className="label">Upload Photo</label>
                                        <input type="file" name="file" id="file-input" className="block border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400
                                            file:bg-transparent file:border-0
                                            file:bg-gray-200 w-full file:mr-4
                                            file:py-3 file:px-4
                                            dark:file:bg-gray-700 dark:file:text-gray-400 mb-4"
                                            accept=".jpeg,.jpg,.png,.webp"
                                            onChange={handleFileChange}
                                        />
                                        <div className="flex">
                                            <div>
                                                <label className="label">Choose Chat Background Colour</label>
                                                <input id="nativeColorPicker1" type="color" name="color" onChange={handleChange} className="mt-2 mb-4 cursor-pointer" value={`${settings.color}`} />
                                            </div>
                                            <div>
                                                <label className="label">Choose Chat Text Colour</label>
                                                <input id="nativeColorPicker1" type="color" name="textColor" onChange={handleChange} className="mt-2 mb-4 cursor-pointer" value={`${settings.textColor}`} />
                                            </div>
                                        </div>
                                        {!loading ? <button type="submit" className="bg-black w-full h-10 rounded-xl mt-4 text-white font-semibold hover:scale-105 transition-all flex justify-center items-center duration-200 ease-in-out">{mainBtnText}</button> : <button disabled className="bg-black cursor-not-allowed w-full h-10 rounded-xl mt-4 text-white font-semibold hover:scale-105 transition-all flex justify-center items-center duration-200 ease-in-out"><Loader /></button>}
                                    </form></div>}
                                    {tabValue === 0 && <div className="flex-1 relative">
                                        <Stack sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                                        <div className="absolute inset-0 bg-black/60 z-10 rounded-lg flex items-center justify-center">
                                            <div className="bg-gray-800/90 text-white px-4 py-2 rounded-md text-sm md:text-base">
                                            Design Preview Mode
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="w-full md:w-80 h-[30rem] bg-white rounded-lg shadow-xl overflow-hidden flex flex-col">
                                                {/* <Typography sx={{ fontWeight: 500, fontSize: 18, mb: 1 }}>Preview</Typography> */}
                                                <div className="bg-black text-white p-4 flex justify-between items-center">
                                                    <div className="flex items-center gap-3">
                                                        {!file && url ? <img accept=".png,.jpg,.jpeg,.svg,.gif" src={url} className="h-10 w-10 rounded-full" /> : file && <img accept=".png,.jpg,.jpeg,.svg,.gif" src={URL.createObjectURL(file)} className="h-10 w-10 rounded-full" />}
                                                        <h1 className="font-bold text-xl ml-3">{settings.title || 'Profile name'}</h1>
                                                    </div>
                                                    <button className="text-white hover:opacity-80">
                                                        <X size={24} />
                                                    </button>
                                                </div>

                                                <div className="flex-grow overflow-y-auto p-4 space-y-4">
                                                    <div className="flex justify-start">
                                                        <div className="max-w-[70%] p-3 rounded-lg bg-gray-200 text-black rounded-bl-none">
                                                            {settings.initial || 'Hello there how can i help you'}
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        <div className="max-w-[70%] p-3 rounded-lg rounded-br-none"
                                                            style={{
                                                                backgroundColor: settings.color,
                                                                color: settings.textColor
                                                            }}
                                                        >
                                                            Hi! I have a question
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-start">
                                                        <div className="max-w-[70%] p-3 rounded-lg bg-gray-200 text-black rounded-bl-none">
                                                            Of course! I'd be happy to help.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="p-4 border-t border-gray-200 flex items-center">
                                                    <input
                                                        type="text"
                                                        placeholder="Type your message..."
                                                        disabled
                                                        className="flex-grow p-2 border bg-white text-black border-gray-300 rounded-lg mr-2 focus:outline-none focus:ring-2 focus:ring-black"
                                                    />
                                                    <button className="p-2 bg-black text-white rounded-full hover:bg-gray-800">
                                                        <Send size={20} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        </Stack>
                                        </div> }
                                </div>
                                </div>
                                <CustomTabPanel index={1} value={tabValue}>
                                    <div className="overflow-auto min-h-fit">
                                        <IconButton onClick={()=>nav("/kb?bot="+id)}>
                                            <Tooltip title="Add Knowledge">
                                                <Add />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton disabled={selectedFiles.length === 0} onClick={handleDeleteFile}>
                                            <Tooltip title={selectedFiles.length !== 0 ? "Delete Files" : "Select a file to delete"}>
                                                <Delete />
                                            </Tooltip>
                                        </IconButton>
                                        {paginatedFiles.map((file, index) => (
                                            <div key={index} className="mb-4 cursor-pointer last:mb-0 bg-gray-50 rounded-lg p-4 shadow" onClick={() => openFileDrawer(index)}>
                                                <div className="flex items-center mb-2">
                                                    <Checkbox 
                                                        color="secondary" 
                                                        onChange={(e) => {
                                                            setOpenDrawer(false)
                                                            e.stopPropagation(); // Stop event propagation
                                                            handleFileSelect(file.id);
                                                        }} 
                                                        checked={selectedFiles.includes(file.id)}
                                                    />
                                                    <h3 className="font-semibold text-lg">{file.file_name}</h3>
                                                </div>
                                                <div className="text-sm text-gray-600 mb-2">
                                                    <span className="font-medium">Knowledge Hub:</span>
                                                    <span className="ml-1">{file.kb_name}</span>
                                                </div>
                                                <div className="grid grid-cols-2 gap-2 text-sm">
                                                    <div>
                                                        <span className="font-medium">Created By:</span>
                                                        <span className="ml-1">{file.created_by}</span>
                                                    </div>
                                                </div>
                                                <div className="flex flex-wrap gap-2">
                                                    {file.tags && file.tags.map((tag, index) => (
                                                        <Chip 
                                                            key={index} 
                                                            label={`#${tag}`}
                                                            color="secondary"
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex justify-between mt-4">
                                        <Button 
                                            disabled={currentPage === 0} 
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                            color="secondary"
                                        >
                                            Previous
                                        </Button>
                                        <Button 
                                            disabled={currentPage >= totalPages - 1} 
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            color="secondary"
                                        >
                                            Next
                                        </Button>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel value={tabValue} index={2}>
                                    <Box sx={{ maxWidth: '100%', overflow: 'auto', mb: 2 }}>
                                        <ResponsiveEmbedCode apiKey={api}/>
                                    </Box>
                                </CustomTabPanel>
                            </div>
                            
                            <Modal
                                open={isOpen}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: {xs: '90%', sm: '80%', md: '60%', lg: '50%'},
                                    maxHeight: '90vh',
                                    bgcolor: 'background.paper',
                                    boxShadow: 24,
                                    p: {xs: 2, sm: 3, md: 4},
                                    borderRadius: 2,
                                    overflow: 'auto'
                                }}>
                                    <Typography variant="h5" component="h2" gutterBottom>
                                        Script Code
                                    </Typography>
                                    <Box sx={{ maxWidth: '100%', overflow: 'auto', mb: 2 }}>
                                        <div className="relative bg-gray-100 border border-gray-300 rounded-md p-4">
                                            {/* Code block */}
                                            <pre className="whitespace-pre-wrap break-all text-sm leading-relaxed font-mono overflow-x-auto">
                                                <code className="text-gray-800 block">
                                                    <span className="">{'<script>\n'}</span>
                                                    <span className="">{'   window.chatx = {\n'}</span>
                                                    <span className="">{`        chatbotKey: "${api}"\n`}</span>
                                                    <span className="">{'   }\n'}</span>
                                                    <span className="">{'</script>\n'}</span>
                                                    <span className="text-black">{script}</span>
                                                </code>
                                            </pre>
                                        </div>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
                                        <Button variant="outlined" onClick={copyy} color="secondary">
                                            {btnText}
                                        </Button>
                                        <Button variant="text" color="error" onClick={closeModal}>
                                            Close
                                        </Button>
                                    </Box>
                                </Box>
                            </Modal>
                            <Modal
                                open={kbOpen}
                                onClose={closeKbModel}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <div className="h-full w-full flex justify-center items-center">
                                    <div className="md:p-10 p-3 rounded-md bg-white flex-col justify-center items-center">
                                        <Typography sx={{ fontWeight: 700, fontSize: 20, mb: 1 }}>Knowledge Hub Used</Typography>
                                        <div className="overflow-auto min-h-fit mb-5 max-h-48 w-full md:w-96">
                                            {trainingFiles.map((file, index) => (
                                                <div key={index} className="mb-4 last:mb-0 bg-gray-50 rounded-lg p-4 shadow">
                                                    <div className="flex items-center mb-2">
                                                        <div className="w-2 h-2 bg-blue-500 rounded-full mr-2"></div>
                                                        <h3 className="font-semibold text-lg">{file.file_name}</h3>
                                                    </div>
                                                    <div className="text-sm text-gray-600 mb-2">
                                                        <span className="font-medium">Knowledge Hub:</span>
                                                        <span className="ml-1">{file.kb_name}</span>
                                                    </div>
                                                    <div className="grid grid-cols-2 gap-2 text-sm">
                                                        <div>
                                                            <span className="font-medium">Created By:</span>
                                                            <span className="ml-1">{file.created_by}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <Button variant="outlined" color="error" onClick={() => setkbOpen(false)}>Close</Button>
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                open={openDelete}
                                onClose={() => setOpenDelete(false)}
                            >
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                    <div className='bg-white h-auto md:h-[35vh] w-[90%] md:w-[30vw] rounded-md flex flex-col justify-center items-center p-4'>
                                        <div className='flex flex-col justify-center items-start w-full'>
                                            <Typography variant='h3' sx={{ mb: 2 }}>Delete Assistant</Typography>
                                            <div className='flex items-center mt-2 w-full'>
                                                <Checkbox id="ok" color='secondary' value={isok} onClick={() => { setIsOk(!isok); }} />
                                                <label htmlFor="ok" className='font-semibold'>I Understand that deleting items cannot be recovered</label>
                                            </div>
                                            <div className='flex items-center mt-4 w-full justify-between'>
                                                <Button color='secondary' sx={{ mr: 2 }} onClick={() => {
                                                    setOpenDelete(false);
                                                }}>Cancel</Button>
                                                <Button color="error" variant="outlined" onClick={handleDeleteApi}>Delete</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Modal>
                        </div>
                    ) : (
                        <div className="flex flex-col md:flex-row justify-center items-center md:justify-between p-4 h-full">
                            <img src={notFound} className="w-72 mb-4 md:mb-0" />
                            <div className="w-full md:w-2/3 text-center md:text-left">
                                <h1 className="text-[#5E35B1] font-semibold text-2xl mb-3">Took a Wrong Turn</h1>
                                <p className="mb-4">The Assistant you are searching for is not found</p>
                                <Button onClick={navBack} variant="outlined" color="secondary">Nav Back To AI Marketplace</Button>
                            </div>
                        </div>
                    )}
                </MainCard>
            </Stack>
            <PDFReader close={closeDrawer} file={fileUrl} openDrawer={openDrawer} fileName={fileName} />
        </div>
    );
}

export default CustomizeBot;