import { Close, Refresh, SmartToy } from "@mui/icons-material";
import { CircularProgress, SwipeableDrawer, Tab, Tabs, Typography, IconButton, Chip, TextField, Button } from "@mui/material";
import { useState } from "react";
import { DocumentViewer } from "react-documents";
import { useAccess } from "context/AccessContext";
import { useEffect } from "react";
import MainCard from "ui-component/cards/MainCard";
import { Link, useNavigate } from "react-router-dom";
import { Clock, MessageSquare, Users } from "lucide-react";

const PDFReader = ({ file, close, openDrawer, fileName, kbName }) => {
  const { getFileSummary,changeTags } = useAccess();
  const nav = useNavigate();

  const [tags, setTags] = useState([]);
  const [value, setValue] = useState(0);
  const [newTag, setNewTag] = useState("");
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const [hloading, setHLoading] = useState(false)

  const getSummary = async () => {
    if (fileName) {
      setLoading(true);
      try {
        setSummary({});
        setValue(0);
        const summary = await getFileSummary(fileName);
        const tag = summary?.tags || [];
        setTags(tag);
        setSummary(summary);
        console.log(summary)
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      console.log("No File Name");
      return;
    }
  };

  const handleRefresh = () => {
    const val = value
    getSummary();
    setValue(val)
  };

  useEffect(() => {
    getSummary();
  }, [fileName]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTagInputChange = (event) => {
    setNewTag(event.target.value);
  };

  const handleTagInputKeyDown = (event) => {
    if (event.key === "Enter" && newTag.trim() !== "") {
      setTags((prevTags) => [...prevTags, newTag.trim()]);
      setNewTag("");
    }
  };

  const handleTagDelete = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const handleTagsChange = async() => {
    setHLoading(true)
    const joined_tags = tags.length > 0 ? tags.join(",") : "null"
    const form = new FormData()
    form.append("tags",joined_tags)
    form.append("id",file)
    await changeTags(form)
    setNewTag("")
    setHLoading(false)
  }

  if (loading) {
    return (
      <SwipeableDrawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => {
          if (loading) {
            return null;
          }
          close();
        }}
      >
        <div className="md:w-[50vw] w-[80vw] bg-white">
          <div className="h-[80vh] flex flex-col justify-center items-center">
            <CircularProgress size={30} color="secondary" />
            <Typography variant="h5" sx={{ mt: 2 }}>
              Loading ...
            </Typography>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }

  const getDaysAgo = (timestamp) => {
    if (!timestamp) return 'Today';
    
    // Handle both date string and timestamp formats
    const date = timestamp.includes('/') 
      ? new Date(timestamp.split('/').reverse().join('-')) 
      : new Date(parseInt(timestamp));
      
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    return diffDays === 0 ? 'Today' : `${diffDays}d`;
  };

  const BotListItem = ({ assistant }) => (
    <Link to={`/api/${assistant.apikey}`} className="w-full">
      <MainCard onClick={()=>nav(`/api/${assistant.apikey}`)} className="hover:shadow-md transition-all cursor-pointer">
        <div className="flex items-start gap-6">
          <div className="flex-1">
            <div className="flex items-center">
              {assistant?.image && assistant.image !== "null" ? (
                  <img className='w-10 h-10 rounded-full mr-2' src={assistant.image} />
                ) : (
                  <SmartToy className='w-8 h-8 text-[#5E35B1] rounded-full mr-2' />
                )
              }
              <div>
                <h3 className="text-lg font-semibold text-gray-900">{assistant.botname}</h3>
                <p className="text-sm text-gray-500">{assistant.organization} | {assistant.team_name}</p>
              </div>
            </div>
            <p className="text-sm text-gray-600 my-2">{assistant.initial}</p>
            <div className="flex items-center gap-4 mb-4">
              <div className="flex items-center gap-1">
                <Users className="w-4 h-4 text-gray-400" />
                <span className="text-sm">{assistant?.views}</span>
              </div>
              <div className="flex items-center gap-1">
                <MessageSquare className="w-4 h-4 text-gray-400" />
                <span className="text-sm">{assistant?.chats}</span>
              </div>
              <div className="flex items-center gap-1">
                <Clock className="w-4 h-4 text-gray-400" />
                <span className="text-sm">{getDaysAgo(assistant?.created_at || assistant?.timestamp)}</span>
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              {assistant.tags?.map(tag => (
                <span key={tag} className="px-2 py-1 bg-blue-50 text-blue-700 rounded-full text-xs">
                  #{tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      </MainCard>
    </Link>
  );

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={openDrawer}
      onClose={() => {
        if (loading) {
          return null;
        }
        setValue(0);
        close();
      }}
    >
      <div className="md:w-[50vw] w-[80vw] bg-white">
        <div className="flex items-center justify-between p-6">
          <h2 className="font-bold text-xl">{fileName}</h2>
          <div className="flex items-center gap-2">
            <IconButton onClick={handleRefresh} title="Refresh Document">
              <Refresh />
            </IconButton>
            <button
              onClick={() => {
                setValue(0);
                close();
              }}
            >
              <Close className="cursor-pointer" />
            </button>
          </div>
        </div>
        <Tabs
          value={value}
          textColor="secondary"
          indicatorColor="secondary"
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Summary" />
          <Tab label="File" />
        </Tabs>
        {value === 1 && (
          <div className="h-[80vh] p-1">
            <DocumentViewer
              viewerUrl={"https://docs.google.com/gview?url=%URL%&embedded=true"}
              url={file || ""}
              loaded={() => setLoading(false)}
              viewer="url"
            />
          </div>
        )}
        {value === 0 && summary && (
          <div className="h-[80vh] p-10">
            <Typography variant="h3">Summary</Typography>
            <p className="mt-3">{summary?.summary}</p>
            <Typography className="mt-4" variant="h3">
              Keywords
            </Typography>
            <div className="mt-3 flex flex-wrap gap-2">
              {summary?.keywords &&
                summary?.keywords?.split(",").map((item, idx) => (
                  <span key={idx} className="bg-gray-100 px-4 py-1 rounded-full">
                    {item}
                  </span>
                ))}
            </div>
            <Typography className="mt-4" variant="h3">
              Ai Assistance
            </Typography>
            <div className="mt-3 flex flex-wrap gap-2">
              {summary.associated_bots.length > 0 ? (
                summary.associated_bots.map((item, idx) => (
                  <BotListItem assistant={item} key={idx} />
                ))
              ) : (
                <Typography className="mt-4" variant="h3">
                  No Ai Assistance
                </Typography>
              )}
            </div>
            <Typography className="mt-4" variant="h3">
              Tags
            </Typography>
            <div className="mt-3 flex flex-wrap gap-2">
              {tags.length > 0 ? (
                tags.map((item, idx) => (
                  <Chip
                    color="secondary"
                    key={idx}
                    label={`#${item}`}
                    className="mr-2 mb-2"
                    onDelete={() => handleTagDelete(item)}
                  />
                ))
              ) : (
                <Typography className="mt-4" variant="h3">
                  No Tags Entered
                </Typography>
              )}
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Add Tags"
              value={newTag}
              onChange={handleTagInputChange}
              onKeyDown={handleTagInputKeyDown}
              color="secondary"
            />
            <Button
              variant="outlined"
              color="secondary"
              className="mt-4"
              onClick={handleTagsChange}
              disabled={hloading}
            >{hloading?
              <>
                <CircularProgress color="secondary" size={25} />
              </>
            :"Add Tags"}</Button>
          </div>
        )}
      </div>
    </SwipeableDrawer>
  );
};

export default PDFReader;