import React, { useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Link, useNavigate, useLocation } from "react-router-dom"; // Added useLocation
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import getUserInfo, { deleteKb, getMemberRole, getKbByName, changeKbName, trainKb, getBotsName } from 'services/app.services';
import MainCard from 'ui-component/cards/MainCard';
import {
  Box,
  Button,
  Typography,
  Modal,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import { Stack } from '@mui/system';
import { ChevronRight, Home, Delete, Edit, Search, FilterList, ViewColumn, Fullscreen } from "@mui/icons-material";
import NavComp from 'components/NavComp/NavComp';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs } from "react-pdf";
import PDFReader from 'components/pdf/PDFViewer';
import Chip from '@mui/material/Chip';
import { purple } from '@mui/material/colors';
import { toast } from 'react-toastify';
import { BrainCircuit } from 'lucide-react';
import { useAccess } from 'context/AccessContext';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ManageTrainAI = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Added useLocation
  const { getKb,loading,knowldegeHubs,knowldegeHubsNames,activeRole,handleGetKbNames,activeTeam } = useAccess()
  const [rowSelection, setRowSelection] = useState({});
  const [showData, setShowData] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [ok, setOK] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [fileType, setFileType] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [selectedKbNames, setSelectedKbNames] = useState([]);
  const [docs, setDocs] = useState([]);
  const [access, setAccess] = useState();
  const [editModel, setEditModel] = useState(false);
  const [editModelInp, setEditModelInp] = useState("");
  const [selectedOldKbName, setSelectedOldKbName] = useState("");
  const [open, setOpen] = useState(false);
  const [botNames,setBotNames] = useState([])
  const [botname,setBotname] = useState("")
  const [deleteLoading,setDeleteLoading] = useState(false)
  const [sel, setSel] = useState(false);
  const [bots, setBots] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed

  useEffect(()=>{
    console.log("kb: ",knowldegeHubs, "len :", knowldegeHubs.length)
    setShowData(knowldegeHubs)
  },[knowldegeHubs])

  // Get filter from URL
  const queryParams = new URLSearchParams(location.search);
  const filter = queryParams.get('filter');
  const botQueryId = queryParams.get('bot'); // Get bot query from URL

  // Set selected bot if bot query exists
  useEffect(() => {
    if (botQueryId) {
      const filteredBot = botNames.filter(bot => bot.id == botQueryId);
      if (filteredBot.length > 0) {
        setSel(filteredBot[0].title); // Set the selected bot name
      }
    }
  }, [botQueryId, botNames, navigate]);

  // Status badge styles mapping
  const getStatusBadgeStyle = (status) => {
    const styles = {
      'Ready to train': 'bg-blue-100 text-blue-800 hover:bg-blue-200',
      'In Progress': 'bg-yellow-100 text-yellow-800 hover:bg-yellow-200',
      'Completed': 'bg-green-100 text-green-800 hover:bg-green-200',
      'Failed': 'bg-red-100 text-red-800 hover:bg-red-200',
    };
    return styles[status] || 'bg-gray-100 text-gray-800 hover:bg-gray-200';
  };

  // Define columns based on screen size
  const columns = isMobile
    ? [
        {
          accessorKey: 'kb_name',
          header: 'Knowledge Hub',
          Cell: ({ cell }) => {
            const cell_value = cell.getValue();
            return (
              <span>
                {cell_value.length > 20 ? `${cell_value.substring(0, 20)}...` : cell_value}
              </span>
            );
          },
        },
        {
          accessorKey: 'file_name', // Only show file_name on mobile
          header: 'File Name',
          Cell: ({ cell }) => {
            const fileName = cell.getValue();
            return (
              <span>
                {fileName.length > 25 ? `${fileName.substring(0, 25)}...` : fileName}
              </span>
            );
          },
        },
      ]
    : [
        {
          accessorKey: 'kb_name',
          header: 'Knowledge Hub',
        },
        {
          accessorKey: 'file_name',
          header: 'Documents',
          Cell: ({ cell }) => {
            const fileName = cell.getValue();
            return (
              <span>
                {fileName.length > 25 ? `${fileName.substring(0, 25)}...` : fileName}
              </span>
            );
          },
        },
        {
          accessorKey: 'created_by',
          header: 'Created By',
        },
        {
          accessorKey: 'associated_bots',
          header: 'Ai Asistants',
        },
        {
          accessorKey: 'last_modified',
          header: 'Created On',
          Cell: ({ cell }) => {
            const timestamp = cell.getValue();
            if(timestamp.split("/").length > 1){
              return(
                <span>
                  {timestamp}
                </span>
              )
            } else {
              const date = new Date(parseInt(timestamp));
              const month = (date.getMonth() + 1).toString().padStart(2, '0');
              const day = date.getDate().toString().padStart(2, '0');
              const year = date.getFullYear();
              const hours = date.getHours().toString().padStart(2, '0');
              const minutes = date.getMinutes().toString().padStart(2, '0');
              return (
                <span>
                  {`${month}/${day}/${year} ${hours}:${minutes}`}
                </span>
              );
            }
          },
        },
        {
          accessorKey: 'file_size',
          header: 'Size (mb)',
        },
        {
          accessorKey: 'status',
          header: 'Status',
          Cell: ({ row }) => (
            <Chip className={getStatusBadgeStyle(row.original.status)} key={row.original.status} label={row.original.status}/>
          ),
        },
      ];

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  function openFileDrawer(index) {
    const row = showData[index];
    if (row?.file_name.toLowerCase().includes(".pdf")) {
      setFileType("pdf");
    } else if (row?.file_name.toLowerCase().includes(".docx") || row?.file_name.toLowerCase().includes(".doc")) {
      setFileType("docx");
    } else if (row?.file_name.toLowerCase().includes(".txt")) {
      setFileType("txt");
      fetch(file).then((res) => res.text().then(d => {
      }));
    } else {
      setFileType("No");
    }
    setFile(row?.location);
    setFileName(row?.file_name);
    setDocs([{ uri: row?.location }]);
    setOpenDrawer(true);
  }

  const fetchKbName = async () => {
    const acces = activeRole.split(",")?.some(i => i === "Admin" || i === "Create Knowledge Base");
    console.log("Roles: ",activeRole, access)
    setAccess(acces)
  }

  useEffect(() => {
    fetchKbName();
  }, [activeRole]);

  useEffect(() => {
    if (filter) {
      // Wait for data to be loaded before applying the filter
      if (knowldegeHubs.length > 0) {
        const filteredData = knowldegeHubs.filter(item => item.kb_name === filter);
        setShowData(filteredData);
        setSelectedKbNames([filter]); // Highlight the chip
      }
    }
  }, [filter, knowldegeHubs]);

  const closeDrawer = () => {
    setFile(null);
    setFileType(null);
    setOpenDrawer(false);
  }

  const handleKBClick = (kbName) => {
    setSelectedKbNames((prevSelected) => {
      const newSelectedKbNames = prevSelected.includes(kbName)
        ? prevSelected.filter((name) => name !== kbName)
        : [...prevSelected, kbName];

      // Update showData based on the new selection
      const filteredData = newSelectedKbNames.length > 0
        ? knowldegeHubs.filter((item) => newSelectedKbNames.includes(item.kb_name))
        : knowldegeHubs;

      setShowData(filteredData);
      return newSelectedKbNames;
    });
  };

  const handleClearFilter = () => {
    setSelectedKbNames([]);
    setShowData(knowldegeHubs);
  };

  const handleChangeKbName = async (kbName) => {
    setEditModel(true);
    setSelectedOldKbName(kbName);
    setEditModelInp(kbName);
  }

  const handleEditModelChange = async () => {
    const organization = localStorage.getItem("organization");
    const formData = new FormData();
    formData.append("oldname", selectedOldKbName);
    formData.append("newname", editModelInp);
    formData.append("organization", organization);
    console.log(selectedOldKbName)
    console.log(editModelInp)
    await changeKbName(formData);
    await getKb(activeTeam);
    await handleGetKbNames(activeTeam)
    setSelectedOldKbName("");
    setSelectedKbNames([]);
    setEditModel(false);
    setEditModelInp("");
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    let deleteKeys = [];
    const uid = localStorage.getItem("uid");
    const org = localStorage.getItem("organization");
    Object.keys(rowSelection).map((i) => {
      deleteKeys.push(showData[i].id);
    });
    const form = new FormData();
    form.append("organization",org)        
    form.append("uid", uid);
    form.append("org", org);
    form.append("id", deleteKeys.join());
    await deleteKb(form);
    getKb(activeTeam);
    await handleGetKbNames(activeTeam)
    setRowSelection({});
    setOK(false);
    setOpen1(false);
    handleClearFilter();
    setDeleteLoading(false)
  }

  const train = async() => {
    let train = []
    Object.entries(rowSelection).map((i)=>{
      train.push(showData[i[0]].id)
    })
    let eid = null
    const dateObj = new Date
    console.log(train)
    const id = localStorage.getItem('uid')
    if(botname){
      const res = await trainKb(activeTeam,botname,train,id,eid,dateObj.getTime().toString())
      if(res.ok){
        handleClose()
        console.log(res.res)
        if(!res.eid){
          navigate(`/edit/new?id=${res?.res?.id}`)
        }else{
          navigate(`/edit/${res.eid}`)
        }
      }
    }else{
      if(sel !== true){
        bots.map((i)=>{
          if(i.title === sel){
            eid = i.id;
          }
        })
      }
      console.log(sel,train,id,eid)
      const res = await trainKb(activeTeam,sel,train,id,eid,dateObj.getTime())
      if(res.ok){
        handleClose()
        console.log(res.eid)
        if(!res.eid){
          navigate(`/edit/new`)
        }else{
          navigate(`/edit/${res.eid}`)
        }
      }
    }
  } 

  const handleClose = () => {
    setOpen(false);
    setBotname('')
  };

  useEffect(()=>{
    const uid = localStorage.getItem("uid");
    const usr = localStorage.getItem("usr")
    console.log(usr)
    getBotsName(uid).then((res)=>{
      if(res.length !== 0){
        console.log(res)
        setBots(res)
        let names = []
        res?.map((i)=>{
          names.push(i.title)
        })
        console.log(names)
        setBotNames(names)
      }
    }).catch((err) => {
      console.error(err);
    });
  },[])

  return (
    <Stack>
      <MainCard>
        <Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
          <div className="flex justify-center items-center">
            <Link to='/marketplace'>
              <Home color="secondary" />
            </Link>
            <ChevronRight />
            <Typography sx={{ fontWeight: 'bold' }}>Knowledge Hub</Typography>
          </div>
          {access && <NavComp fetchKbName={fetchKbName} getKbData={getKb} />}
        </Stack>
        <div className='grid md:grid-cols-8 gap-3 grid-cols-2 mt-5'>
          {knowldegeHubsNames?.map((i) => (
            <Chip
              key={i?.kb_name}
              label={i?.kb_name}
              sx={{ color: purple[800] }}
              variant={selectedKbNames.includes(i?.kb_name) ? "filled" : "outlined"}
              onClick={() => handleKBClick(i?.kb_name)}
              deleteIcon={<Edit />}
              onDelete={() => handleChangeKbName(i?.kb_name)}
            />
          ))}
          {selectedKbNames.length > 0 && (
            <>
              <Chip
                label="Clear Filter"
                sx={{ color: 'primary.contrastText', bgcolor: 'error.main', ml: 1 }}
                onClick={handleClearFilter}
              />
            </>
          )}
        </div>
      </MainCard>

      <Stack sx={{ mt: 2, borderRadius: '8px', overflow: 'hidden'}}>
        <MaterialReactTable
          muiTableBodyRowProps={({ row }) => ({
            onClick: () => {
              openFileDrawer(row.index);
            }
          })}
          columns={columns}
          data={showData}
          enableRowSelection
          state={{ rowSelection: rowSelection }}
          onRowSelectionChange={setRowSelection}
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}>
              
              {activeRole.split(",").includes("Admin") && <Button
                disabled={Object.keys(rowSelection).length === 0}
                onClick={() => setOpen(true)}
                startIcon={<BrainCircuit />}
                variant="outlined"
                color="secondary"
              >
                Choose documents to train your AI Assistant
              </Button>}
            </Box>
          )}
          initialState={{
            sorting: [{ id: 'last_modified', desc: true }]
          }}
          sx={{
            borderRadius: '8px', // Adjust the radius as needed
            overflow: 'hidden', // Ensure the corners are rounded
          }}
          // Customizing the pagination options
          muiTablePaginationProps={{
            labelRowsPerPage: '',
          }}
          enableFullScreenToggle={true}
          renderToolbarInternalActions={({ table }) => (
            <>
              <IconButton onClick={() => table.setShowGlobalFilter(!table.getState().showGlobalFilter)}>
                <Search />
              </IconButton>
              <IconButton onClick={() => table.setShowColumnFilters(!table.getState().showColumnFilters)}>
                <FilterList />
              </IconButton>
              <IconButton
                onClick={() => setOpen1(true)}
                disabled={Object.keys(rowSelection).length === 0}
              >
                <Delete />
              </IconButton>
            </>
          )}
        />
      </Stack>
      <Modal
        open={open1}
        onClose={() => {
          setOK(false);
          setOpen1(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{}}
      >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <div className='bg-white h-[20vh] md:w-[30vw] w-[90%] p-2 rounded-md flex flex-col justify-center items-center'>
            {/* <img src={deleteImg} className='w-40' /> */}
            <div className='flex flex-col justify-center items-start'>
              <Typography variant='h3' sx={{ mx: 1 }}>Delete Items</Typography>
              <div className='flex items-center mt-2'>
                <Checkbox id="ok" color='secondary' value={ok} onClick={() => { setOK(!ok); }} />
                <label htmlFor='ok' className='font-semibold'>I Understand that deleting items cannot be recovered</label>
              </div>
              <div className='flex items-center mt-2'>
                <Button color='secondary' sx={{ mr: 2 }} disabled={deleteLoading} onClick={() => {
                  setOK(false);
                  setOpen1(false);
                }}>Cancel</Button>
                <Button variant='outlined' color='error' disabled={!ok||deleteLoading}
                  onClick={handleDelete}
                >{deleteLoading?<CircularProgress color='error' />:"Delete"}</Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={editModel}
        onClose={() => setEditModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{}}
      >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <div className='bg-white h-[25vh] md:w-[30vw] w-[90%] p-8 rounded-md flex flex-col justify-center items-start'>
            <div className='flex flex-col w-full justify-center items-start'>
              <Typography variant='h3' sx={{ mx: 1 }}>Edit Knowledge Hub Name</Typography>
              <TextField
                variant="outlined"
                className='mt-4 mb-3'
                label="Enter the new Knowledge Hub name"
                id="kbname"
                color="secondary"
                fullWidth
                sx={{ mr: 2 }}
                value={editModelInp}
                onChange={(e) => setEditModelInp(e.target.value)}
              />
              <div className='flex items-center mt-2'>
                <Button color='error' sx={{ mr: 2 }} disabled={loading} onClick={() => {
                  setEditModelInp("");
                  setEditModel(false);
                }}>Cancel</Button>
                <Button variant='outlined' color='secondary' disabled={loading} onClick={handleEditModelChange}>
                  Update Knowledge Hub name
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: {xs: "90%", md: "500px"} }}>
          <Typography variant='h3' sx={{fontWeight: 'semibold',mb:2}}>Enter an AI Assistant name</Typography>
          <Stack>
            <Select
              onChange={(e)=>{
                console.log(e.target.value)
                setSel(e.target.value)
              }}
              value={sel}
              className='mb-5'
              variant="outlined"
              color="secondary"
            >
              <MenuItem value={true}>Create new Assistant</MenuItem>
              {botNames.map((i)=>(<MenuItem value={i}>{i}</MenuItem>))}
            </Select>
            {sel===true&&<TextField onChange={(e)=>setBotname(e.target.value)} value={botname} variant='outlined' color='secondary' label="Enter assistant name here..." sx={{width:'100%',mb:2}} />}
            <Stack direction={'row'} justifyContent={'flex-end'}>
              <Button variant='outlined' sx={{width:'30%'}} color='error' onClick={handleClose}>Cancel</Button>
              <Button variant='outlined' sx={{width:'30%',ml:2}} color='secondary' onClick={train}>Create</Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <PDFReader close={closeDrawer} file={file} openDrawer={openDrawer} fileName={fileName} />
    </Stack>
  );
};

export default ManageTrainAI;