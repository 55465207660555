// assets
import { IconKey, Icon, IconAlertOctagon, IconApps, IconBrain, IconPackage} from '@tabler/icons';
import { ChatBubbleOutline } from '@mui/icons-material';


// constant
const icons = {
  IconKey, IconAlertOctagon, IconApps, IconBrain, IconPackage, ChatBubbleOutline
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const bot_pages = {
  id: 'create_bot',
  // title: 'AI Marketplace',
  // type: 'item',
  type: 'group',

  children: [
    {
      id: 'marketplace',
      title: 'AI Marketplace',
      type: 'item',
      url: '/marketplace',
      icon: icons.IconApps,
      breadcrumbs: true
    },
    // {
    //   id: 'publish_bot',
    //   title: 'Publish AI ChatBot',
    //   type: 'item',
    //   url: '/publish',
    //   icon: icons.IconPackage,
    // },
  ]
};

export default bot_pages;
