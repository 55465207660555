import React, { useEffect, useState } from 'react';
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import SecuritySection from "./SecuritySection";

const Website = () => {
  const [to, setTo] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const nav = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const id = localStorage.getItem('uid')
    console.log(id)
    if (!id) {
      setTo('/login')
    } else {
      setTo('/marketplace')
    }
  }, [])

  const handleNavigate = () => {
    nav(to)
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <nav className="bg-white/80 backdrop-blur-md fixed w-full z-50 border-b">
        <div className="max-w-7xl mx-auto px-4 h-16 flex items-center justify-between">
          <div className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 text-transparent bg-clip-text">
            AskLLY.ai
            <p className="text-base font-medium text-gray-600 hover:text-gray-800">Research Purposes Only</p>
          </div>
          
          <div className="lg:hidden">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="p-2">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex gap-6">
            <button className="px-4 py-2 text-gray-600 hover:text-gray-900">Features</button>
            <button className="px-4 py-2 text-gray-600 hover:text-gray-900">Use cases</button>
            <button className="px-4 py-2 text-gray-600 hover:text-gray-900">About</button>
            <button onClick={handleNavigate} disabled={loading} className="px-4 py-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg">
              {loading ? <CircularProgress /> : "Get Started"}
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className="lg:hidden border-t bg-white">
            <button className="block w-full px-4 py-3 text-left hover:bg-gray-50">Features</button>
            <button className="block w-full px-4 py-3 text-left hover:bg-gray-50">Use cases</button>
            <button className="block w-full px-4 py-3 text-left hover:bg-gray-50">About</button>
            <button onClick={handleNavigate} disabled={loading} className="block w-full px-4 py-3 text-left bg-gradient-to-r from-blue-600 to-purple-600 text-white">
              {loading ? <CircularProgress /> : "Get Started"}
            </button>
          </div>
        )}
      </nav>

      <section className="pt-20 md:pt-32 pb-12 md:pb-24 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-2 gap-8 lg:gap-12 items-center">
            <div className="text-center lg:text-left">
              <h1 className="text-4xl lg:text-5xl font-bold mb-4 lg:mb-6">
                Train Your Perfect
                <span className="block text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600">
                  AI Assistant
                </span>
              </h1>
              <p className="text-lg lg:text-xl text-gray-600 mb-6 lg:mb-8">
                Create customized AI assistants trained on your domain knowledge data with role based user access.
              </p>
              <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                <button onClick={handleNavigate} disabled={loading} className="px-8 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg text-lg w-full sm:w-auto">
                  {loading ? <CircularProgress /> : "Start Building"}
                </button>
                <button className="px-8 py-3 border border-gray-300 rounded-lg text-lg w-full sm:w-auto">
                  Watch Demo
                </button>
              </div>
            </div>
            <div className="relative mt-8 lg:mt-0">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-400/30 to-purple-400/30 rounded-2xl blur-2xl" />
              <div className="relative bg-white/90 p-6 lg:p-8 rounded-2xl shadow-xl border">
                <div className="space-y-4">
                  <div className="flex gap-3 items-center">
                    <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                      <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                    <div className="flex-1">
                      <div className="h-2 bg-blue-200 rounded"></div>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center">
                    <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center">
                      <svg className="w-5 h-5 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                      </svg>
                    </div>
                    <div className="flex-1">
                      <div className="h-2 bg-purple-200 rounded"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 md:py-24 bg-gray-50 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl lg:text-4xl font-bold">
              Everything You Need to Build Your
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600"> AI Assistant</span>
            </h2>
          </div>
          <div className="grid md:grid-cols-3 gap-6 md:gap-8">
            {[
              {
                title: "Upload Your Data",
                description: "Import documents, websites, and custom data to train your AI",
                icon: (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                  </svg>
                )
              },
              {
                title: "Train & Customize",
                description: "Fine-tune your assistant's knowledge and behavior",
                icon: (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                  </svg>
                )
              },
              {
                title: "Deploy & Share",
                description: "Embed on your website or share with your team",
                icon: (
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.367 2.684 3 3 0 00-5.367-2.684z" />
                  </svg>
                )
              }
            ].map((feature, index) => (
              <div key={index} className="bg-white p-6 rounded-xl shadow-lg border">
                <div className="w-12 h-12 rounded-lg bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center text-white mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
  
      {/* New Data Governance Section */}
      <section className="py-12 md:py-24 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl lg:text-4xl font-bold">
              Data & AI
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600"> Governance and Privacy</span>
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              Protect your data and maintain compliance
            </p>
          </div>
          
          <div className="grid md:grid-cols-4 gap-6 md:gap-8">
            <div className="bg-white p-6 rounded-xl shadow-lg border">
              <div className="w-12 h-12 rounded-lg bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center text-white mb-4">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Your data is your own</h3>
              <p className="text-gray-600">
                Ensures strict data separation between customers. Your knowledge base, documents, and guides used to train your AI assistant are securely stored in your private Asklly workspace. No data is ever shared with other companies.
              </p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-lg border">
              <div className="w-12 h-12 rounded-lg bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center text-white mb-4">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 11.5V14m0-2.5v-6a2 2 0 014 0v6m-4 0h4m-4 0h0m-4 0a2 2 0 104 0v-6a2 2 0 00-4 0v6m4 0h4" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Private data stays private</h3>
              <p className="text-gray-600">
                Safeguards customer privacy by detecting and masking personal information (PII), such as SSN and credit card details. Ensures sensitive data is accessed only when necessary, maintaining security and data integrity.
              </p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-lg border">
              <div className="w-12 h-12 rounded-lg bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center text-white mb-4">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Enterprise-grade security</h3>
              <p className="text-gray-600">
              Designed with a security-first approach, it ensures your sensitive information is always protected. Features include end-to-end encryption, secure data storage, and regular security audits. Your data’s safety is our top priority.
              </p>
            </div>

            <div className="bg-white p-6 rounded-xl shadow-lg border">
              <div className="w-12 h-12 rounded-lg bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center text-white mb-4">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-2">Compliance ready</h3>
              <p className="text-gray-600">
              Ensure compliance with industry standards using built-in controls and features. Maintain transparency with detailed audit trails. Manage data effectively with robust governance tools.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 md:py-24 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-2 gap-8 lg:gap-12 items-center">
            <div>
              <h2 className="text-3xl lg:text-4xl font-bold mb-6">
                See what
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600"> AskLLY.ai </span>
                can do for you
              </h2>
              <p className="text-lg text-gray-600 mb-8">
                Find out how AskLLY.ai can help your company transform the employee and customer experience with our conversational AI assistants. Our intelligent solutions are designed to enhance customer interactions and streamline your business operations.
              </p>
              <button className="px-8 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg text-lg">
                Learn More
              </button>
            </div>
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-400/30 to-purple-400/30 rounded-2xl blur-2xl" />
              <div className="relative bg-white/90 p-8 rounded-2xl shadow-xl border">
                <div className="space-y-6">
                  <div className="flex gap-4">
                    <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                      <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                      </svg>
                    </div>
                    <div className="flex-1">
                      <h3 className="font-semibold mb-1">Intelligent Conversations</h3>
                      <div className="h-2 bg-blue-200 rounded w-3/4"></div>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="w-10 h-10 rounded-full bg-purple-100 flex items-center justify-center">
                      <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                      </svg>
                    </div>
                    <div className="flex-1">
                      <h3 className="font-semibold mb-1">Use cases</h3>
                      <div className="h-2 bg-purple-200 rounded w-2/3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SecuritySection/>


      <section className="py-12 md:py-24 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl lg:text-4xl font-bold mb-6 lg:mb-8">
            Ready to Build Your
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600"> AI Assistant?</span>
          </h2>
          <button className="w-full sm:w-auto px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg text-lg">
            Get Started for Free
          </button>
        </div>
      </section>
      
      <Footer />
    </div>
  );
};

export default Website;