import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import EditBot from 'views/EditBot/editBot';
import Profile from 'views/Profile/Profile';
import SamplePage from 'views/sample-page/index';
import PublishAI from 'views/Publish/index';
import EventAI from 'views/Events/index';
import ConnectAI from 'views/Connect/index';
import KnowledgeBaseAI from 'views/knowledgeBase';
import UploadDoc from 'views/Create/Upload';
import DataDoc from 'views/Create/Text';
import URLDoc from 'views/Create/Link';
import QADoc from 'views/Create/QA';
import ChatWithBot from 'views/Chat/ChatWithBot';
import Documentation from 'views/documentation/SiteDocs';
import BuildKnowledgeCard from 'views/welcome/BuildKnowledgeCard';
import Audit from 'views/audit/Audit';
import AIMarketplace from 'views/marketplace/index';
import SearchBar from 'views/search/index';

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'welcome',
      element: <BuildKnowledgeCard />
    },
    {
      path: 'mybots',
      element: <DashboardDefault />
    },
    {
      path: 'marketplace',
      element: <AIMarketplace />
    },
    {
      path: 'search',
      element: <SearchBar />
    },
    {
      path: 'upload',
      element: <UploadDoc />
    },
    {
      path: 'data',
      element: <DataDoc />
    },
    {
      path: 'url',
      element: <URLDoc />
    },
    {
      path: 'qa',
      element: <QADoc />
    },
    {
      path: 'edit/:id', 
      element: <EditBot />
    },
    {
      path: 'profile', 
      element: <Profile />
    },
    {
      path: 'sample', 
      element: <SamplePage />
    },
    {
      path: 'publish', 
      element: <PublishAI />
    },
    {
      path: 'events', 
      element: <EventAI />
    },
    {
      path: 'connect', 
      element: <ConnectAI />
    },
    {
      path: 'kb', 
      element: <KnowledgeBaseAI />
    },
    {
      path: 'chat',
      element: <ChatWithBot />
    },
    {
      path: 'docs',
      element: <Documentation />
    },
    {
      path: 'audit',
      element: <Audit />
    }
  ],
};


export default MainRoutes;
