import MainLayout from "layout/MainLayout";
import Members from "views/Teams/Members";
import Teams from "views/Teams/Teams";

const TeamPage = {
    path: "/teams",
    element: <MainLayout />,
    children: [
        {
            path: "",
            element: <Teams />
        },
        {
            path: ":id",
            element: <Members />
        }
    ]
}

export default TeamPage