import React, { useState, useEffect } from 'react';
import { Heart, Book, Bot, Search, X, Menu, ChevronLeft } from 'lucide-react';
import getUserInfo, { addToFavorites, getFavorites } from 'services/app.services';
import { useNavigate } from 'react-router';
import { useAccess } from 'context/AccessContext';

const MainCard = ({ children, className = '' }) => (
  <div className={`bg-white rounded-lg shadow-sm p-6 ${className}`}>
    {children}
  </div>
);

const ITEMS_PER_PAGE = 10;

const SearchBar = () => {
  const uid = localStorage.getItem("uid");
  const nav = useNavigate()
  const { fetchtrainedBots, getKb } = useAccess()

  const [activeSection, setActiveSection] = useState('ai-assistants');
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [kbhub, setKbhub] = useState([]);
  const [ai, setAi] = useState([]);
  const [fav, setFav] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Handle window resize and data fetching
  useEffect(() => {
    const fetchData = async () => {
      try {
        const form = new FormData();
        const usr = await getUserInfo(uid);
        const email = usr.email;
        if (email == null) {
          email = localStorage.getItem("email");
        }
        form.append("email", email);
        const organization = localStorage.getItem("organization");
        form.append("organization", organization);
        const kbData = await getKb(form);
        const aiData = await fetchtrainedBots(uid);
        
        setKbhub(kbData.kb || []);
        setAi(aiData.data[0] || []);

        const favs = await getFavorites()
        setFav(favs.favorites)
        console.log(favs.favorites)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, [uid]);

  // Reset page when changing sections
  useEffect(() => {
    setCurrentPage(1);
  }, [activeSection, searchQuery]);

  const allSubItems = {
    'knowledge-hub': kbhub.map(item => ({
      title: item.file_name,
      description: item.kb_name,
      category: 'Knowledge Hub',
      nav: '/kb',
      onclick: async() => {
        await addToFavorites(item?.id, "knowledgebase")
        const favs = await getFavorites()
        setFav(favs.favorites)
      },
      fav: fav.some(f => f.category === "Knowledge Hub" && f.id === item.id)
    })),
    'ai-assistants': ai.map(item => ({
      title: item.botname,
      description: 'AI Assistant',
      category: 'AI Assistants', 
      nav: `/api/${item?.apikey}`,
      onclick: async() => {
        await addToFavorites(item?.id, "bot")
        const favs = await getFavorites()
        setFav(favs.favorites)
      },
      fav: fav.some(f => f.category === "AI Assistants" && f.id === item.id)
    })),
    'favorites': fav.map(item => ({
      title: item.category === 'AI Assistants' ? item.botname : item.file_name,
      description: item.category === 'AI Assistants' ? 'AI Assistant' : item.kb_name,
      category: item.category,
      nav: item.category === 'AI Assistants' ? `/api/${item.apikey}` : '/kb',
      onclick: async() => {
        await addToFavorites(item?.id, item.category === 'AI Assistants' ? "bot" : "knowledgebase")
        const favs = await getFavorites()
        setFav(favs.favorites)
      },
      fav: true
    }))
  };

  console.log(allSubItems, fav)

  const menuItems = [
    {
      id: 'favorites',
      icon: <Heart className="w-5 h-5" />,
      title: 'Favorites',
      items: allSubItems['favorites']
    },
    {
      id: 'knowledge-hub',
      icon: <Book className="w-5 h-5" />,
      title: 'Knowledge Hub',
      items: allSubItems['knowledge-hub']
    },
    {
      id: 'ai-assistants',
      icon: <Bot className="w-5 h-5" />,
      title: 'AI Assistants',
      items: allSubItems['ai-assistants']
    }
  ];

  const Pagination = ({ totalItems }) => {
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
    
    return (
      <div className="flex justify-center mt-6 gap-2">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={`px-3 py-1 rounded ${
              currentPage === page
                ? 'bg-purple-600 text-white'
                : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            {page}
          </button>
        ))}
      </div>
    );
  };

  const SubItemGrid = ({ items }) => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const paginatedItems = items.slice(startIndex, startIndex + ITEMS_PER_PAGE);
    
    return (
      <>
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
          {paginatedItems.map((item, index) => (
            <div 
              key={index}
              onClick={()=>nav(item?.nav)}
              className="bg-white rounded-lg p-4 md:p-6 hover:shadow-md transition-all cursor-pointer"
            >
              <div className="flex flex-col h-full">
                <div className="flex justify-between items-start flex-wrap gap-2">
                  <div className="flex-1">
                    <h3 className="text-lg md:text-xl font-semibold text-gray-900">{item.title}</h3>
                    <p className="text-gray-600 text-sm md:text-base mt-2">{item.description}</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-purple-600 text-xs md:text-sm whitespace-nowrap">
                      {item.category}
                    </span>
                    <Heart 
                      fill={item?.fav ? "red" : "none"}
                      className={`w-5 h-5 ${item?.fav ? "text-red-500" : "text-gray-400"} hover:text-red-500 cursor-pointer`}
                      onClick={(e) => {
                        console.log("clicking on the item")
                        item?.onclick()
                        e.stopPropagation();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination totalItems={items.length} />
      </>
    );
  };

  // Filter items based on search query
  const filteredItems = searchQuery
    ? [...allSubItems['knowledge-hub'], ...allSubItems['ai-assistants']]
        .filter(item =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.category.toLowerCase().includes(searchQuery.toLowerCase())
        )
    : activeSection === 'favorites'
      ? allSubItems['favorites'] || []
      : menuItems.find(item => item.id === activeSection)?.items || [];

  const handleToggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Top Search Bar */}
      <div className="sticky top-0 z-50 bg-white border-b border-gray-200 shadow-sm">
        <div className="max-w-screen-xl mx-auto px-4 py-3">
          <div className="flex items-center gap-2">
            {isMobile && !isMobileMenuOpen && (
              <button
                onClick={handleToggleMenu}
                className="p-2 hover:bg-gray-100 rounded-lg"
              >
                <Menu className="h-5 w-5 text-gray-600" />
              </button>
            )}
            <div className="relative flex-1 max-w-3xl mx-auto">
              <div className={`
                relative flex items-center transition-all duration-200 bg-white
                ${isSearchFocused ? 'transform scale-[1.01]' : ''}
              `}>
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder={isMobile ? "Search..." : "Search knowledge base or AI assistants..."}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setIsSearchFocused(true)}
                  onBlur={() => setIsSearchFocused(false)}
                  className={`
                    w-full py-2 md:py-3 pl-10 pr-10
                    border-2 rounded-full text-sm md:text-base
                    bg-white 
                    text-gray-900 placeholder-gray-500
                    focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500
                    transition-all duration-200
                  `}
                />
                {searchQuery && (
                  <button
                    onClick={() => setSearchQuery('')}
                    className="absolute right-3 p-1 hover:bg-gray-100 rounded-full"
                  >
                    <X className="h-5 w-5 text-gray-400" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex">
        {/* Mobile Menu Overlay */}
        {isMobile && isMobileMenuOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={() => setIsMobileMenuOpen(false)}
          />
        )}

        {/* Sidebar navigation */}
        <nav className={`
          ${isMobile 
            ? `fixed inset-y-0 left-0 w-64 z-50 transform transition-transform duration-300 ease-in-out
               ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}`
            : 'w-64'
          }
          bg-white border-r border-gray-200 min-h-screen
        `}>
          {isMobile && isMobileMenuOpen && (
            <div className="p-4 border-b border-gray-200 flex items-center">
              <button
                onClick={() => setIsMobileMenuOpen(false)}
                className="p-2 hover:bg-gray-100 rounded-lg"
              >
                <ChevronLeft className="h-5 w-5 text-gray-600" />
              </button>
              <span className="ml-2 font-medium">Menu</span>
            </div>
          )}
          <div className="p-4 space-y-1">
            {menuItems.map((item) => (
              <div
                key={item.id}
                className={`flex items-center space-x-3 p-3 rounded-lg cursor-pointer
                  transition-colors ${
                  activeSection === item.id 
                    ? 'bg-purple-600 text-white' 
                    : 'hover:bg-purple-50 text-gray-600'
                }`}
                onClick={() => {
                  setActiveSection(item.id);
                  if (isMobile) setIsMobileMenuOpen(false);
                }}
              >
                <div className={activeSection === item.id ? 'text-white' : 'text-gray-400'}>
                  {item.icon}
                </div>
                <div className="font-medium">{item.title}</div>
              </div>
            ))}
          </div>
        </nav>
        
        {/* Main content */}
        <main className={`h-[calc(70vh-200px)] flex-1 p-4 md:p-8 ${isMobile ? 'w-full' : ''}`}>
          <div className="max-w-6xl mx-auto">
            <h1 className="text-xl md:text-2xl font-bold text-gray-900 mb-6">
              {searchQuery 
                ? `Search Results${filteredItems.length ? `: ${filteredItems.length} found` : ''}`
                : menuItems.find(item => item.id === activeSection)?.title
              }
            </h1>
            
            {filteredItems.length > 0 ? (
              <div className="overflow-y-auto">
                <SubItemGrid items={filteredItems} />
              </div>
            ) : (
              <div className="text-gray-600 text-sm md:text-base">
                {searchQuery ? `No results found for "${searchQuery}"` : 'No items available'}
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default SearchBar;