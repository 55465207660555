// material-ui
import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| Events PAGE ||============================== //

const EventAI = () => (
  <MainCard title="Capture All the Events">
    <Typography variant="body2">
      Capture all the events for your Organization to your knowledge hub which can be used to train your AI ChatBot
    </Typography>
  </MainCard>
);

export default EventAI;
